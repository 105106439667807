import React, {useEffect, useRef}  from 'react'
import apiLink from '../../../config.js'
import {useLocation} from 'react-router-dom'

import ButtonQuestions from '../../MEP/button question.js'
import Entete from '../../MEP/entete.js'
import GraphAssolement3 from '../../OAD/Graphiques/assolement_graph_3.js';
import BlocCompRdt from './Composant_comparaison_simulations/bloc_Comp_rdt.js';
import Graph3VaguesComp from './Composant_comparaison_simulations/Graph_trois_vagues_comp.js';
import GraphDifference_comp2050 from './Composant_comparaison_simulations/graphDifference_Comp2050.js'
import GraphAleasRapport from '../../PDF/graphAleas_Rapport.js'

import IMGProd from '../../../assets/navig_exploit_ac.png'
import IMGRDT from '../../../assets/navig_riskRDT_ac.png'
import IMG3050 from '../../../assets/navig2050_2030-2050_ac.png'
import IMGAleas from '../../../assets/navig2050_aleas_ac.png'


function CompSimul2050() {
  const { state } = useLocation();
  const { Sim1, Sim2 } = state; //récupérer les deux Simulations

  const idExploitationPrincipale = JSON.parse(localStorage.getItem('idExploitationPrinicpale'));

  const [Libelle1, setLibell1]= React.useState("");
  const [Libelle2, setLibell2]= React.useState("");
  const [myDataSurf1, setDataSurf1]= React.useState([]);
  const [myDataSurf2, setDataSurf2]= React.useState([]);
  const [surfTot1, setsurfTot1]= React.useState(0);
  const [surfTot2, setsurfTot2]= React.useState(0);
  const [PBTot1, setPBTot1]= React.useState(0);
  const [PBTot2, setPBTot2]= React.useState(0);
  const [Risques7_1, setR7_1] = React.useState(0)
  const [Risques7_2, setR7_2] = React.useState(0)
  const [Risques20_1, setR20_1] = React.useState(0)
  const [Risques20_2, setR20_2] = React.useState(0)
  const [Risques50_1, setR50_1] = React.useState(0)
  const [Risques50_2, setR50_2] = React.useState(0)
  const [dataPerso_1, setDP_1]= React.useState();
  const [dataPerso_2, setDP_2]= React.useState();
  const [dataVagues7, setDV7]= React.useState();
  const [dataVagues20, setDV20]= React.useState();
  const [dataVagues50, setDV50]= React.useState();
  const [maximumRDT, setmaxDRT]= React.useState();
  const [dataQ1, setDQ1]= React.useState();
  const [dataQ2, setDQ2]= React.useState();
  const [dataQ3, setDQ3]= React.useState();
  const [D2020_1, setD2020_1]= React.useState();
  const [D2030_2D_1, setD2030_2D_1]= React.useState();
  const [D2040_2D_1, setD2040_2D_1]= React.useState();
  const [D2050_2D_1, setD2050_2D_1]= React.useState();
  const [D2020_2, setD2020_2]= React.useState();
  const [D2030_2D_2, setD2030_2D_2]= React.useState();
  const [D2040_2D_2, setD2040_2D_2]= React.useState();
  const [D2050_2D_2, setD2050_2D_2]= React.useState();
  const [D2030_4D_1, setD2030_4D_1]= React.useState();
  const [D2040_4D_1, setD2040_4D_1]= React.useState();
  const [D2050_4D_1, setD2050_4D_1]= React.useState();
  const [D2030_4D_2, setD2030_4D_2]= React.useState();
  const [D2040_4D_2, setD2040_4D_2]= React.useState();
  const [D2050_4D_2, setD2050_4D_2]= React.useState();
  const [dataMoy1_SH_2D, setDataMoy1_SH_2D] =  React.useState()
  const [dataMoy1_SH_4D, setDataMoy1_SH_4D] =  React.useState()
  const [dataMin1_SH_2D, setDataMin1_SH_2D] =  React.useState()
  const [dataMin1_SH_4D, setDataMin1_SH_4D] =  React.useState()
  const [dataMax1_SH_2D, setDataMax1_SH_2D] =  React.useState()
  const [dataMax1_SH_4D, setDataMax1_SH_4D] =  React.useState()
  const [dataQ11_SH_2D, setDataQ11_SH_2D] =  React.useState()
  const [dataQ11_SH_4D, setDataQ11_SH_4D] =  React.useState()
  const [dataQ31_SH_2D, setDataQ31_SH_2D] =  React.useState()
  const [dataQ31_SH_4D, setDataQ31_SH_4D] =  React.useState()
  const [dataMoy1_EE_2D, setDataMoy1_EE_2D] =  React.useState()
  const [dataMoy1_EE_4D, setDataMoy1_EE_4D] =  React.useState()
  const [dataMin1_EE_2D, setDataMin1_EE_2D] =  React.useState()
  const [dataMin1_EE_4D, setDataMin1_EE_4D] =  React.useState()
  const [dataMax1_EE_2D, setDataMax1_EE_2D] =  React.useState()
  const [dataMax1_EE_4D, setDataMax1_EE_4D] =  React.useState()
  const [dataQ11_EE_2D, setDataQ11_EE_2D] =  React.useState()
  const [dataQ11_EE_4D, setDataQ11_EE_4D] =  React.useState()
  const [dataQ31_EE_2D, setDataQ31_EE_2D] =  React.useState()
  const [dataQ31_EE_4D, setDataQ31_EE_4D] =  React.useState()
  const [dataMoy1_GEL_2D, setDataMoy1_GEL_2D] =  React.useState()
  const [dataMoy1_GEL_4D, setDataMoy1_GEL_4D] =  React.useState()
  const [dataMin1_GEL_2D, setDataMin1_GEL_2D] =  React.useState()
  const [dataMin1_GEL_4D, setDataMin1_GEL_4D] =  React.useState()
  const [dataMax1_GEL_2D, setDataMax1_GEL_2D] =  React.useState()
  const [dataMax1_GEL_4D, setDataMax1_GEL_4D] =  React.useState()
  const [dataQ11_GEL_2D, setDataQ11_GEL_2D] =  React.useState()
  const [dataQ11_GEL_4D, setDataQ11_GEL_4D] =  React.useState()
  const [dataQ31_GEL_2D, setDataQ31_GEL_2D] =  React.useState()
  const [dataQ31_GEL_4D, setDataQ31_GEL_4D] =  React.useState()
  const [dataMoy1_CDC_2D, setDataMoy1_CDC_2D] =  React.useState()
  const [dataMoy1_CDC_4D, setDataMoy1_CDC_4D] =  React.useState()
  const [dataMin1_CDC_2D, setDataMin1_CDC_2D] =  React.useState()
  const [dataMin1_CDC_4D, setDataMin1_CDC_4D] =  React.useState()
  const [dataMax1_CDC_2D, setDataMax1_CDC_2D] =  React.useState()
  const [dataMax1_CDC_4D, setDataMax1_CDC_4D] =  React.useState()
  const [dataQ11_CDC_2D, setDataQ11_CDC_2D] =  React.useState()
  const [dataQ11_CDC_4D, setDataQ11_CDC_4D] =  React.useState()
  const [dataQ31_CDC_2D, setDataQ31_CDC_2D] =  React.useState()
  const [dataQ31_CDC_4D, setDataQ31_CDC_4D] =  React.useState()
  const [dataMoy2_SH_2D, setDataMoy2_SH_2D] =  React.useState()
  const [dataMoy2_SH_4D, setDataMoy2_SH_4D] =  React.useState()
  const [dataMin2_SH_2D, setDataMin2_SH_2D] =  React.useState()
  const [dataMin2_SH_4D, setDataMin2_SH_4D] =  React.useState()
  const [dataMax2_SH_2D, setDataMax2_SH_2D] =  React.useState()
  const [dataMax2_SH_4D, setDataMax2_SH_4D] =  React.useState()
  const [dataQ12_SH_2D, setDataQ12_SH_2D] =  React.useState()
  const [dataQ12_SH_4D, setDataQ12_SH_4D] =  React.useState()
  const [dataQ32_SH_2D, setDataQ32_SH_2D] =  React.useState()
  const [dataQ32_SH_4D, setDataQ32_SH_4D] =  React.useState()
  const [dataMoy2_EE_2D, setDataMoy2_EE_2D] =  React.useState()
  const [dataMoy2_EE_4D, setDataMoy2_EE_4D] =  React.useState()
  const [dataMin2_EE_2D, setDataMin2_EE_2D] =  React.useState()
  const [dataMin2_EE_4D, setDataMin2_EE_4D] =  React.useState()
  const [dataMax2_EE_2D, setDataMax2_EE_2D] =  React.useState()
  const [dataMax2_EE_4D, setDataMax2_EE_4D] =  React.useState()
  const [dataQ12_EE_2D, setDataQ12_EE_2D] =  React.useState()
  const [dataQ12_EE_4D, setDataQ12_EE_4D] =  React.useState()
  const [dataQ32_EE_2D, setDataQ32_EE_2D] =  React.useState()
  const [dataQ32_EE_4D, setDataQ32_EE_4D] =  React.useState()
  const [dataMoy2_GEL_2D, setDataMoy2_GEL_2D] =  React.useState()
  const [dataMoy2_GEL_4D, setDataMoy2_GEL_4D] =  React.useState()
  const [dataMin2_GEL_2D, setDataMin2_GEL_2D] =  React.useState()
  const [dataMin2_GEL_4D, setDataMin2_GEL_4D] =  React.useState()
  const [dataMax2_GEL_2D, setDataMax2_GEL_2D] =  React.useState()
  const [dataMax2_GEL_4D, setDataMax2_GEL_4D] =  React.useState()
  const [dataQ12_GEL_2D, setDataQ12_GEL_2D] =  React.useState()
  const [dataQ12_GEL_4D, setDataQ12_GEL_4D] =  React.useState()
  const [dataQ32_GEL_2D, setDataQ32_GEL_2D] =  React.useState()
  const [dataQ32_GEL_4D, setDataQ32_GEL_4D] =  React.useState()
  const [dataMoy2_CDC_2D, setDataMoy2_CDC_2D] =  React.useState()
  const [dataMoy2_CDC_4D, setDataMoy2_CDC_4D] =  React.useState()
  const [dataMin2_CDC_2D, setDataMin2_CDC_2D] =  React.useState()
  const [dataMin2_CDC_4D, setDataMin2_CDC_4D] =  React.useState()
  const [dataMax2_CDC_2D, setDataMax2_CDC_2D] =  React.useState()
  const [dataMax2_CDC_4D, setDataMax2_CDC_4D] =  React.useState()
  const [dataQ12_CDC_2D, setDataQ12_CDC_2D] =  React.useState()
  const [dataQ12_CDC_4D, setDataQ12_CDC_4D] =  React.useState()
  const [dataQ32_CDC_2D, setDataQ32_CDC_2D] =  React.useState()
  const [dataQ32_CDC_4D, setDataQ32_CDC_4D] =  React.useState()

  
  useEffect ( () => {
    fetch(apiLink + 'page/exploitation/' + Sim1)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataSurf1(response.dataSurface)
      setDataSurf1(prev => {
        return prev;
      })
      setsurfTot1(response.surfaceTotale)
      setsurfTot1(prev => {
        return prev;
      })
      setPBTot1(response.PBTotal)
      setPBTot1(prev => {
        return prev;
      })
    })
    fetch(apiLink + 'page/exploitation/' + Sim2)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataSurf2(response.dataSurface)
      setDataSurf2(prev => {
        return prev;
      })
      setsurfTot2(response.surfaceTotale)
      setsurfTot2(prev => {
        return prev;
      })
      setPBTot2(response.PBTotal)
      setPBTot2(prev => {
        return prev;
      })
    }) 
    
    fetch(apiLink + 'page/optimisation/' + Sim1)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setR7_1(response.risques7)
      setR7_1(prev => {
        return prev;
      })
      setR20_1(response.risques20)
      setR20_1(prev => {
        return prev;
      })
      setR50_1(response.risques50)
      setR50_1(prev => {
        return prev;
      })
    }) 
    fetch(apiLink + 'page/optimisation/' + Sim2)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setR7_2(response.risques7)
      setR7_2(prev => {
        return prev;
      })
      setR20_2(response.risques20)
      setR20_2(prev => {
        return prev;
      })
      setR50_2(response.risques50)
      setR50_2(prev => {
        return prev;
      })
    }) 
    fetch(apiLink + 'page/production-rapport/' + Sim1)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      let data1 = response
        fetch(apiLink + 'page/production-rapport/' + Sim2)
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
        
        let data2 = response
        var vitiPres = false
        for (var cle in data1) {
          if (cle.includes("Vignes")) {
            vitiPres = true
          }
        }
        for (var cle in data2) {
          if (cle.includes("Vignes")) {
            vitiPres = true
          }
        }
        if(vitiPres){
        fetch(apiLink + 'page/comparaison/' + Sim1 + '/' + Sim2 + '/2')
        .then( (response) => {
            return response.json()
        })
        .then(response =>{
          setDP_1(response.dataPerso)
          setDP_1(prev => {
              return prev;
          })
          setDP_2(response.dataPerso2)
          setDP_2(prev => {
              return prev;
          })
          setLibell1(response.libelleExploitation1)
          setLibell1(prev => {
              return prev;
          })
          setLibell2(response.libelleExploitation2)
          setLibell2(prev => {
              return prev;
          })
          setDV7(response.dataVagues7)
          setDV7(prev => {
              return prev;
          })
          setDV20(response.dataVagues20)
          setDV20(prev => {
              return prev;
          })
          setDV50(response.dataVagues50)
          setDV50(prev => {
              setmaxDRT(prev[prev.length-2].y+20)
              setDQ1([{x:prev.length/4, y:0}, {x:prev.length/4, y:80}])
              setDQ2([{x:prev.length/2, y:0}, {x:prev.length/2, y:80}])
              setDQ3([{x:prev.length*3/4, y:0}, {x:prev.length*3/4, y:80}])
              return prev;
          })
        }) 
      }
      else {
        fetch(apiLink + 'page/comparaison/' + Sim1 + '/' + Sim2)
        .then( (response) => {
            return response.json()
        })
        .then(response =>{
          setDP_1(response.dataPerso)
          setDP_1(prev => {
              return prev;
          })
          setDP_2(response.dataPerso2)
          setDP_2(prev => {
              return prev;
          })
          setLibell1(response.libelleExploitation1)
          setLibell1(prev => {
              return prev;
          })
          setLibell2(response.libelleExploitation2)
          setLibell2(prev => {
              return prev;
          })
          setDV7(response.dataVagues7)
          setDV7(prev => {
              return prev;
          })
          setDV20(response.dataVagues20)
          setDV20(prev => {
              return prev;
          })
          setDV50(response.dataVagues50)
          setDV50(prev => {
              setmaxDRT(prev[prev.length-1].y+20)
              setDQ1([{x:prev.length/4, y:0}, {x:prev.length/4, y:80}])
              setDQ2([{x:prev.length/2, y:0}, {x:prev.length/2, y:80}])
              setDQ3([{x:prev.length*3/4, y:0}, {x:prev.length*3/4, y:80}])
              return prev;
          })
        }) 
      } 
    });
  })
  fetch('https://api2050.diagorisk.com/resultatExploitation/?idExploit=' + Sim1)
    .then((response) => response.json())
    .then((response) => {
        setD2020_1(response.R2020_2degre)
        setD2020_1(prev => {
          return prev;
        })
        setD2030_2D_1(response.R2030_2degre)
        setD2030_2D_1(prev => {
          return prev;
        })
        setD2040_2D_1(response.R2040_2degre)
        setD2040_2D_1(prev => {
          return prev;
        })
        setD2050_2D_1(response.R2050_2degre)
        setD2050_2D_1(prev => {
          return prev;
        })
        setD2030_4D_1(response.R2030_4degre)
        setD2030_4D_1(prev => {
          return prev;
        })
        setD2040_4D_1(response.R2040_4degre)
        setD2040_4D_1(prev => {
          return prev;
        })
        setD2050_4D_1(response.R2050_4degre)
        setD2050_4D_1(prev => {
          return prev;
        })
    })
    fetch('https://api2050.diagorisk.com/resultatExploitation/?idExploit=' + Sim2)
    .then((response) => response.json())
    .then((response) => {
        setD2020_2(response.R2020_2degre)
        setD2020_2(prev => {
          return prev;
        })
        setD2030_2D_2(response.R2030_2degre)
        setD2030_2D_2(prev => {
          return prev;
        })
        setD2040_2D_2(response.R2040_2degre)
        setD2040_2D_2(prev => {
          return prev;
        })
        setD2050_2D_2(response.R2050_2degre)
        setD2050_2D_2(prev => {
          return prev;
        })
        setD2030_4D_2(response.R2030_4degre)
        setD2030_4D_2(prev => {
          return prev;
        })
        setD2040_4D_2(response.R2040_4degre)
        setD2040_4D_2(prev => {
          return prev;
        })
        setD2050_4D_2(response.R2050_4degre)
        setD2050_4D_2(prev => {
          return prev;
        })
    })
    
    fetch('https://api2050.diagorisk.com/pageAleas/?idExploitation=' + Sim1)
      .then((response) => response.json())
      .then((response) => {
          // Si la réponse est réussie, mettre à jour les données
          setDataMoy1_SH_2D(response.dataMoy_SH_2D)
          setDataMoy1_SH_2D(prev => {
            return prev;
          })
          setDataMoy1_SH_4D(response.dataMoy_SH_4D)
          setDataMoy1_SH_4D(prev => {
            return prev;
          })
          setDataMin1_SH_2D(response.dataMin_SH_2D)
          setDataMin1_SH_2D(prev => {
            return prev;
          })
          setDataMin1_SH_4D(response.dataMin_SH_4D)
          setDataMin1_SH_4D(prev => {
            return prev;
          })
          setDataMax1_SH_2D(response.dataMax_SH_2D)
          setDataMax1_SH_2D(prev => {
            return prev;
          })
          setDataMax1_SH_4D(response.dataMax_SH_4D)
          setDataMax1_SH_4D(prev => {
            return prev;
          })
          setDataQ11_SH_2D(response.dataQ1_SH_2D)
          setDataQ11_SH_2D(prev => {
            return prev;
          })
          setDataQ11_SH_4D(response.dataQ1_SH_4D)
          setDataQ11_SH_4D(prev => {
            return prev;
          })
          setDataQ31_SH_2D(response.dataQ3_SH_2D)
          setDataQ31_SH_2D(prev => {
            return prev;
          })
          setDataQ31_SH_4D(response.dataQ3_SH_4D)
          setDataQ31_SH_4D(prev => {
            return prev;
          })
          setDataMoy1_EE_2D(response.dataMoy_EE_2D)
          setDataMoy1_EE_2D(prev => {
            return prev;
          })
          setDataMoy1_EE_4D(response.dataMoy_EE_4D)
          setDataMoy1_EE_4D(prev => {
            return prev;
          })
          setDataMin1_EE_2D(response.dataMin_EE_2D)
          setDataMin1_EE_2D(prev => {
            return prev;
          })
          setDataMin1_EE_4D(response.dataMin_EE_4D)
          setDataMin1_EE_4D(prev => {
            return prev;
          })
          setDataMax1_EE_2D(response.dataMax_EE_2D)
          setDataMax1_EE_2D(prev => {
            return prev;
          })
          setDataMax1_EE_4D(response.dataMax_EE_4D)
          setDataMax1_EE_4D(prev => {
            return prev;
          })
          setDataQ11_EE_2D(response.dataQ1_EE_2D)
          setDataQ11_EE_2D(prev => {
            return prev;
          })
          setDataQ11_EE_4D(response.dataQ1_EE_4D)
          setDataQ11_EE_4D(prev => {
            return prev;
          })
          setDataQ31_EE_2D(response.dataQ3_EE_2D)
          setDataQ31_EE_2D(prev => {
            return prev;
          })
          setDataQ31_EE_4D(response.dataQ3_EE_4D)
          setDataQ31_EE_4D(prev => {
            return prev;
          })
          setDataMoy1_GEL_2D(response.dataMoy_GEL_2D)
          setDataMoy1_GEL_2D(prev => {
            return prev;
          })
          setDataMoy1_GEL_4D(response.dataMoy_GEL_4D)
          setDataMoy1_GEL_4D(prev => {
            return prev;
          })
          setDataMin1_GEL_2D(response.dataMin_GEL_2D)
          setDataMin1_GEL_2D(prev => {
            return prev;
          })
          setDataMin1_GEL_4D(response.dataMin_GEL_4D)
          setDataMin1_GEL_4D(prev => {
            return prev;
          })
          setDataMax1_GEL_2D(response.dataMax_GEL_2D)
          setDataMax1_GEL_2D(prev => {
            return prev;
          })
          setDataMax1_GEL_4D(response.dataMax_GEL_4D)
          setDataMax1_GEL_4D(prev => {
            return prev;
          })
          setDataQ11_GEL_2D(response.dataQ1_GEL_2D)
          setDataQ11_GEL_2D(prev => {
            return prev;
          })
          setDataQ11_GEL_4D(response.dataQ1_GEL_4D)
          setDataQ11_GEL_4D(prev => {
            return prev;
          })
          setDataQ31_GEL_2D(response.dataQ3_GEL_2D)
          setDataQ31_GEL_2D(prev => {
            return prev;
          })
          setDataQ31_GEL_4D(response.dataQ3_GEL_4D)
          setDataQ31_GEL_4D(prev => {
            return prev;
          })
          setDataMoy1_CDC_2D(response.dataMoy_CDC_2D)
          setDataMoy1_CDC_2D(prev => {
            return prev;
          })
          setDataMoy1_CDC_4D(response.dataMoy_CDC_4D)
          setDataMoy1_CDC_4D(prev => {
            return prev;
          })
          setDataMin1_CDC_2D(response.dataMin_CDC_2D)
          setDataMin1_CDC_2D(prev => {
            return prev;
          })
          setDataMin1_CDC_4D(response.dataMin_CDC_4D)
          setDataMin1_CDC_4D(prev => {
            return prev;
          })
          setDataMax1_CDC_2D(response.dataMax_CDC_2D)
          setDataMax1_CDC_2D(prev => {
            return prev;
          })
          setDataMax1_CDC_4D(response.dataMax_CDC_4D)
          setDataMax1_CDC_4D(prev => {
            return prev;
          })
          setDataQ11_CDC_2D(response.dataQ1_CDC_2D)
          setDataQ11_CDC_2D(prev => {
            return prev;
          })
          setDataQ11_CDC_4D(response.dataQ1_CDC_4D)
          setDataQ11_CDC_4D(prev => {
            return prev;
          })
          setDataQ31_CDC_2D(response.dataQ3_CDC_2D)
          setDataQ31_CDC_2D(prev => {
            return prev;
          })
          setDataQ31_CDC_4D(response.dataQ3_CDC_4D)
          setDataQ31_CDC_4D(prev => {
            return prev;
          })
      })
      
      fetch('https://api2050.diagorisk.com/pageAleas/?idExploitation=' + Sim2)
      .then((response) => response.json())
      .then((response) => {
          // Si la réponse est réussie, mettre à jour les données
          setDataMoy2_SH_2D(response.dataMoy_SH_2D)
          setDataMoy2_SH_2D(prev => {
            return prev;
          })
          setDataMoy2_SH_4D(response.dataMoy_SH_4D)
          setDataMoy2_SH_4D(prev => {
            return prev;
          })
          setDataMin2_SH_2D(response.dataMin_SH_2D)
          setDataMin2_SH_2D(prev => {
            return prev;
          })
          setDataMin2_SH_4D(response.dataMin_SH_4D)
          setDataMin2_SH_4D(prev => {
            return prev;
          })
          setDataMax2_SH_2D(response.dataMax_SH_2D)
          setDataMax2_SH_2D(prev => {
            return prev;
          })
          setDataMax2_SH_4D(response.dataMax_SH_4D)
          setDataMax2_SH_4D(prev => {
            return prev;
          })
          setDataQ12_SH_2D(response.dataQ2_SH_2D)
          setDataQ12_SH_2D(prev => {
            return prev;
          })
          setDataQ12_SH_4D(response.dataQ2_SH_4D)
          setDataQ12_SH_4D(prev => {
            return prev;
          })
          setDataQ32_SH_2D(response.dataQ3_SH_2D)
          setDataQ32_SH_2D(prev => {
            return prev;
          })
          setDataQ32_SH_4D(response.dataQ3_SH_4D)
          setDataQ32_SH_4D(prev => {
            return prev;
          })
          setDataMoy2_EE_2D(response.dataMoy_EE_2D)
          setDataMoy2_EE_2D(prev => {
            return prev;
          })
          setDataMoy2_EE_4D(response.dataMoy_EE_4D)
          setDataMoy2_EE_4D(prev => {
            return prev;
          })
          setDataMin2_EE_2D(response.dataMin_EE_2D)
          setDataMin2_EE_2D(prev => {
            return prev;
          })
          setDataMin2_EE_4D(response.dataMin_EE_4D)
          setDataMin2_EE_4D(prev => {
            return prev;
          })
          setDataMax2_EE_2D(response.dataMax_EE_2D)
          setDataMax2_EE_2D(prev => {
            return prev;
          })
          setDataMax2_EE_4D(response.dataMax_EE_4D)
          setDataMax2_EE_4D(prev => {
            return prev;
          })
          setDataQ12_EE_2D(response.dataQ2_EE_2D)
          setDataQ12_EE_2D(prev => {
            return prev;
          })
          setDataQ12_EE_4D(response.dataQ2_EE_4D)
          setDataQ12_EE_4D(prev => {
            return prev;
          })
          setDataQ32_EE_2D(response.dataQ3_EE_2D)
          setDataQ32_EE_2D(prev => {
            return prev;
          })
          setDataQ32_EE_4D(response.dataQ3_EE_4D)
          setDataQ32_EE_4D(prev => {
            return prev;
          })
          setDataMoy2_GEL_2D(response.dataMoy_GEL_2D)
          setDataMoy2_GEL_2D(prev => {
            return prev;
          })
          setDataMoy2_GEL_4D(response.dataMoy_GEL_4D)
          setDataMoy2_GEL_4D(prev => {
            return prev;
          })
          setDataMin2_GEL_2D(response.dataMin_GEL_2D)
          setDataMin2_GEL_2D(prev => {
            return prev;
          })
          setDataMin2_GEL_4D(response.dataMin_GEL_4D)
          setDataMin2_GEL_4D(prev => {
            return prev;
          })
          setDataMax2_GEL_2D(response.dataMax_GEL_2D)
          setDataMax2_GEL_2D(prev => {
            return prev;
          })
          setDataMax2_GEL_4D(response.dataMax_GEL_4D)
          setDataMax2_GEL_4D(prev => {
            return prev;
          })
          setDataQ12_GEL_2D(response.dataQ2_GEL_2D)
          setDataQ12_GEL_2D(prev => {
            return prev;
          })
          setDataQ12_GEL_4D(response.dataQ2_GEL_4D)
          setDataQ12_GEL_4D(prev => {
            return prev;
          })
          setDataQ32_GEL_2D(response.dataQ3_GEL_2D)
          setDataQ32_GEL_2D(prev => {
            return prev;
          })
          setDataQ32_GEL_4D(response.dataQ3_GEL_4D)
          setDataQ32_GEL_4D(prev => {
            return prev;
          })
          setDataMoy2_CDC_2D(response.dataMoy_CDC_2D)
          setDataMoy2_CDC_2D(prev => {
            return prev;
          })
          setDataMoy2_CDC_4D(response.dataMoy_CDC_4D)
          setDataMoy2_CDC_4D(prev => {
            return prev;
          })
          setDataMin2_CDC_2D(response.dataMin_CDC_2D)
          setDataMin2_CDC_2D(prev => {
            return prev;
          })
          setDataMin2_CDC_4D(response.dataMin_CDC_4D)
          setDataMin2_CDC_4D(prev => {
            return prev;
          })
          setDataMax2_CDC_2D(response.dataMax_CDC_2D)
          setDataMax2_CDC_2D(prev => {
            return prev;
          })
          setDataMax2_CDC_4D(response.dataMax_CDC_4D)
          setDataMax2_CDC_4D(prev => {
            return prev;
          })
          setDataQ12_CDC_2D(response.dataQ2_CDC_2D)
          setDataQ12_CDC_2D(prev => {
            return prev;
          })
          setDataQ12_CDC_4D(response.dataQ2_CDC_4D)
          setDataQ12_CDC_4D(prev => {
            return prev;
          })
          setDataQ32_CDC_2D(response.dataQ3_CDC_2D)
          setDataQ32_CDC_2D(prev => {
            return prev;
          })
          setDataQ32_CDC_4D(response.dataQ3_CDC_4D)
          setDataQ32_CDC_4D(prev => {
            return prev;
          })
      })
    }, [Sim1, Sim2])

  const ScrollProd= useRef(null);
  const ScrollRDT= useRef(null);
  const Scroll3050= useRef(null);
  const ScrollAleas= useRef(null);
  const scrollToProd = () => ScrollProd.current.scrollIntoView(true);
  const scrollToRDT = () => ScrollRDT.current.scrollIntoView(true);
  const scrollTo3050 = () => Scroll3050.current.scrollIntoView(true);
  const scrollToAleas = () => ScrollAleas.current.scrollIntoView(true);

  const comp_simul = (
    <div>
      <div className='menuComparaison2050'>
        <button onClick={scrollToProd} className='buttonMenuComp2050'> <img src={IMGProd} alt='icone'  className='iconMenuComp'/> </button>
        <button onClick={scrollToRDT} className='buttonMenuComp2050'> <img src={IMGRDT} alt='icone' className='iconMenuComp'/> </button>
        <button onClick={scrollTo3050} className='buttonMenuComp2050'> <img src={IMG3050} alt='icone' className='iconMenuComp'/> </button>
        <button onClick={scrollToAleas} className='buttonMenuComp2050'> <img src={IMGAleas} alt='icone' className='iconMenuComp'/> </button>
      </div>

      <div ref={ScrollProd} className='titre'>Productions </div> 
      <div className='dispLigne'>
        <div className='column dispLeft BlocComparaison BCL'>
          <div className='titrePlus'> {Libelle1} <span style={{fontWeight:'normal', fontSize:'70%'}}> {Math.round(surfTot1*100)/100} ha</span> </div>
          <GraphAssolement3 myData={myDataSurf1} PB={PBTot1} print={false}/>
        </div>

        <div className='column dispLeft BlocComparaison'>
          <div className='titrePlus'> {Libelle2} <span style={{fontWeight:'normal', fontSize:'70%'}}> {Math.round(surfTot2*100)/100} ha  </span> </div>
          <GraphAssolement3 myData={myDataSurf2} PB={PBTot2} print={false}/>
        </div>
      </div> 

      <div ref={ScrollRDT} className='titre'>Quel est mon niveau de risques ? </div>
      <div className='paragraphe'> La ligne noire pleine représente la simulation de gauche et celle en pointillée, la simulation de droite. La comparaison ce fait sur les exploitations moyennes grandes cultures</div>
      <div style={{marginTop:'10px'}}>
        <Graph3VaguesComp dataPerso_1={dataPerso_1} dataPerso_2={dataPerso_2} dataVagues7={dataVagues7} dataVagues20={dataVagues20} dataVagues50={dataVagues50} maximum={maximumRDT} dataQ1={dataQ1} dataQ2={dataQ2} dataQ3={dataQ3} />
      </div>
      <div className='dispLigne'>
        <div className='BlocComparaison BCL'>
        <div className='paragraphe' style={{marginBottom:'10px'}} > <span style={{fontWeight:'1000px', fontSize:'30px', color:'red'}}>___</span> Risques de {Libelle1} </div>
          <BlocCompRdt Risques7={Risques7_1} Risques20={Risques20_1} Risques50={Risques50_1} />
        </div>
        <div className='BlocComparaison'>
        <div className='paragraphe' style={{marginBottom:'10px'}} > <span style={{fontWeight:'1000px', fontSize:'30px', color:'red'}}>---</span> Risques de {Libelle2} </div>
          <BlocCompRdt Risques7={Risques7_2} Risques20={Risques20_2} Risques50={Risques50_2} />
        </div>
      </div>

      <div ref={Scroll3050}  className='titre'>Evolution des risques entre 2020 et 2050 </div>
      <div style={{fontSize:"20px", marginBottom:'20px'}}> Evolution des risques entre 2020 et 2050 avec les scénarios à +2 ou +4°C pour les deux exploitations comparées. En pointillé l’exploitation de droite et en ligne plein l’exploitation de gauche.</div>
      <div className='titrePlus'> + 2°C </div>
      {D2020_1 !== undefined && D2020_2 !== undefined && D2030_2D_1 !== undefined && D2030_2D_2 !== undefined && D2040_2D_1 !== undefined && D2040_2D_2 !== undefined && D2050_2D_1 !== undefined && D2050_2D_2 !== undefined ? 
        <GraphDifference_comp2050 D2020={D2020_1} D2020_2={D2020_2} D2030={D2030_2D_1} D2030_2={D2030_2D_2} D2040={D2040_2D_1} D2040_2={D2040_2D_2} D2050={D2050_2D_1} D2050_2={D2050_2D_2}/> 
      : ""}
      <div className='titrePlus'> + 4°C </div>
      {D2020_1 !== undefined && D2020_2 !== undefined && D2030_4D_1 !== undefined && D2030_4D_2 !== undefined && D2040_4D_1 !== undefined && D2040_4D_2 !== undefined && D2050_4D_1 !== undefined && D2050_4D_2 !== undefined ? 
        <GraphDifference_comp2050 D2020={D2020_1} D2020_2={D2020_2} D2030={D2030_4D_1} D2030_2={D2030_4D_2} D2040={D2040_4D_1} D2040_2={D2040_4D_2} D2050={D2050_4D_1} D2050_2={D2050_4D_2}/> 
      : ""}

      <div ref={ScrollAleas} className='titre'>Impact de chaque aléa climatique  </div>
      <div className='titreMoins row'> Stress hydrique</div>
      <div className='dispLigne'>
        <div className='BlocComparaison BCL'>
            <GraphAleasRapport myDataMoy2D={dataMoy1_SH_2D} myDataMax2D={dataMax1_SH_2D} myDataMin2D={dataMin1_SH_2D} myDataQ12D={dataQ11_SH_2D} myDataQ32D={dataQ31_SH_2D} myDataMoy4D={dataMoy1_SH_4D} myDataMax4D={dataMax1_SH_4D} myDataMin4D={dataMin1_SH_4D} myDataQ14D={dataQ11_SH_4D} myDataQ34D={dataQ31_SH_4D} aleas={'SH'}/> 
          
          </div>
          <div className='BlocComparaison'>
            <GraphAleasRapport myDataMoy2D={dataMoy2_SH_2D} myDataMax2D={dataMax2_SH_2D} myDataMin2D={dataMin2_SH_2D} myDataQ12D={dataQ12_SH_2D} myDataQ32D={dataQ32_SH_2D} myDataMoy4D={dataMoy2_SH_4D} myDataMax4D={dataMax2_SH_4D} myDataMin4D={dataMin2_SH_4D} myDataQ14D={dataQ12_SH_4D} myDataQ34D={dataQ32_SH_4D} aleas={'SH'}/> 
          
        </div>
      </div>
      <div className='titreMoins row'> Excès d'eau</div>
      <div className='dispLigne'>
        <div className='BlocComparaison BCL'>
            <GraphAleasRapport myDataMoy2D={dataMoy1_EE_2D} myDataMax2D={dataMax1_EE_2D} myDataMin2D={dataMin1_EE_2D} myDataQ12D={dataQ11_EE_2D} myDataQ32D={dataQ31_EE_2D} myDataMoy4D={dataMoy1_EE_4D} myDataMax4D={dataMax1_EE_4D} myDataMin4D={dataMin1_EE_4D} myDataQ14D={dataQ11_EE_4D} myDataQ34D={dataQ31_EE_4D} aleas={'EE'}/> 
          </div>
          <div className='BlocComparaison'>
            <GraphAleasRapport myDataMoy2D={dataMoy2_EE_2D} myDataMax2D={dataMax2_EE_2D} myDataMin2D={dataMin2_EE_2D} myDataQ12D={dataQ12_EE_2D} myDataQ32D={dataQ32_EE_2D} myDataMoy4D={dataMoy2_EE_4D} myDataMax4D={dataMax2_EE_4D} myDataMin4D={dataMin2_EE_4D} myDataQ14D={dataQ12_EE_4D} myDataQ34D={dataQ32_EE_4D} aleas={'EE'}/> 
        </div>
      </div>
      <div className='titreMoins row'> Gel</div>
      <div className='dispLigne'>
        <div className='BlocComparaison BCL'>
            <GraphAleasRapport myDataMoy2D={dataMoy1_GEL_2D} myDataMax2D={dataMax1_GEL_2D} myDataMin2D={dataMin1_GEL_2D} myDataQ12D={dataQ11_GEL_2D} myDataQ32D={dataQ31_GEL_2D} myDataMoy4D={dataMoy1_GEL_4D} myDataMax4D={dataMax1_GEL_4D} myDataMin4D={dataMin1_GEL_4D} myDataQ14D={dataQ11_GEL_4D} myDataQ34D={dataQ31_GEL_4D} aleas={'GEL'}/> 
          </div>
          <div className='BlocComparaison'>
            <GraphAleasRapport myDataMoy2D={dataMoy2_GEL_2D} myDataMax2D={dataMax2_GEL_2D} myDataMin2D={dataMin2_GEL_2D} myDataQ12D={dataQ12_GEL_2D} myDataQ32D={dataQ32_GEL_2D} myDataMoy4D={dataMoy2_GEL_4D} myDataMax4D={dataMax2_GEL_4D} myDataMin4D={dataMin2_GEL_4D} myDataQ14D={dataQ12_GEL_4D} myDataQ34D={dataQ32_GEL_4D} aleas={'GEL'}/> 
        </div>
      </div>
      <div className='titreMoins row'> Coup de chaleur</div>
      <div className='dispLigne'>
        <div className='BlocComparaison BCL'>
            <GraphAleasRapport myDataMoy2D={dataMoy1_CDC_2D} myDataMax2D={dataMax1_CDC_2D} myDataMin2D={dataMin1_CDC_2D} myDataQ12D={dataQ11_CDC_2D} myDataQ32D={dataQ31_CDC_2D} myDataMoy4D={dataMoy1_CDC_4D} myDataMax4D={dataMax1_CDC_4D} myDataMin4D={dataMin1_CDC_4D} myDataQ14D={dataQ11_CDC_4D} myDataQ34D={dataQ31_CDC_4D} aleas={'CDC'}/> 
          </div>
          <div className='BlocComparaison'>
            <GraphAleasRapport myDataMoy2D={dataMoy2_CDC_2D} myDataMax2D={dataMax2_CDC_2D} myDataMin2D={dataMin2_CDC_2D} myDataQ12D={dataQ12_CDC_2D} myDataQ32D={dataQ32_CDC_2D} myDataMoy4D={dataMoy2_CDC_4D} myDataMax4D={dataMax2_CDC_4D} myDataMin4D={dataMin2_CDC_4D} myDataQ14D={dataQ12_CDC_4D} myDataQ34D={dataQ32_CDC_4D} aleas={'CDC'}/> 
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <Entete titre="Comparaison de simulation"/>
      <div className="container" >
        {idExploitationPrincipale!==null ? comp_simul : "Pour avoir accès à cette onglet vous devez créer un compte"}
      </div>
      <ButtonQuestions/>
    </div>
  )
}

export default CompSimul2050