import React, {useEffect, useState} from 'react'
import TitreRapport from './titre_rapport'
import GraphAleasRapport from './graphAleas_Rapport.js'
import GraphAleasNBjoursRapport from './graphAleasNbjours_Rapport.js'
import GraphAleasNBjoursRapport2 from './graphAleasNbjours_Rapport2.js'
import apiLink from '../../config.js'

function PageAleasPDF({idExploit}) {
  const [ExploitMixte, setExploitMixte] = useState(false)
  const [ExploitViti, setExploitViti] = useState(false)
  const [ExploitGC,setExploitGC] = useState(true)

  const [dataMoy_GEL_2D, setDataMoy_GEL_2D] =  React.useState()
  const [dataMoy_GEL_4D, setDataMoy_GEL_4D] =  React.useState()
  const [dataMin_GEL_2D, setDataMin_GEL_2D] =  React.useState()
  const [dataMin_GEL_4D, setDataMin_GEL_4D] =  React.useState()
  const [dataMax_GEL_2D, setDataMax_GEL_2D] =  React.useState()
  const [dataMax_GEL_4D, setDataMax_GEL_4D] =  React.useState()
  const [dataQ1_GEL_2D, setDataQ1_GEL_2D] =  React.useState()
  const [dataQ1_GEL_4D, setDataQ1_GEL_4D] =  React.useState()
  const [dataQ3_GEL_2D, setDataQ3_GEL_2D] =  React.useState()
  const [dataQ3_GEL_4D, setDataQ3_GEL_4D] =  React.useState()
  const [dataMoy_CDC_2D, setDataMoy_CDC_2D] =  React.useState()
  const [dataMoy_CDC_4D, setDataMoy_CDC_4D] =  React.useState()
  const [dataMin_CDC_2D, setDataMin_CDC_2D] =  React.useState()
  const [dataMin_CDC_4D, setDataMin_CDC_4D] =  React.useState()
  const [dataMax_CDC_2D, setDataMax_CDC_2D] =  React.useState()
  const [dataMax_CDC_4D, setDataMax_CDC_4D] =  React.useState()
  const [dataQ1_CDC_2D, setDataQ1_CDC_2D] =  React.useState()
  const [dataQ1_CDC_4D, setDataQ1_CDC_4D] =  React.useState()
  const [dataQ3_CDC_2D, setDataQ3_CDC_2D] =  React.useState()
  const [dataQ3_CDC_4D, setDataQ3_CDC_4D] =  React.useState()
  const [dataNbjours_GEL_2D, setdataNbjours_GEL_2D] = React.useState()
  const [dataNbjours_GEL_4D, setdataNbjours_GEL_4D] = React.useState()
  const [dataNbjours_CDC_2D, setdataNbjours_CDC_2D] = React.useState()
  const [dataNbjours_CDC_4D, setdataNbjours_CDC_4D] = React.useState()
  const [dataNbjours_GEL_Max_2D, setNBJ_GEL_MAX_2D] = React.useState()
  const [dataNbjours_GEL_Max_4D, setNBJ_GEL_MAX_4D] = React.useState()
  const [dataNbjours_GEL_Min_2D, setNBJ_GEL_MIN_2D] = React.useState()
  const [dataNbjours_GEL_Min_4D, setNBJ_GEL_MIN_4D] = React.useState()
  const [dataNbjours_CDC_Max_2D, setNBJ_CDC_MAX_2D] = React.useState()
  const [dataNbjours_CDC_Max_4D, setNBJ_CDC_MAX_4D] = React.useState()
  const [dataNbjours_CDC_Min_2D, setNBJ_CDC_MIN_2D] = React.useState()
  const [dataNbjours_CDC_Min_4D, setNBJ_CDC_MIN_4D] = React.useState()

  function fetchData() {
    fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
    let data = response
    for (var cle in data) {
        if (cle.includes("Vignes")) {
            setExploitMixte(true)
            setExploitGC(false)
            setExploitViti(false);
            break;
        }
    }
    let allKeysContainVignes = Object.keys(data).every(cle => cle.includes("Vignes"));
    if (allKeysContainVignes) {
        setExploitViti(true);
        setExploitMixte(false)
        setExploitGC(false)
    } else {
        setExploitViti(false);
    }
    })

    fetch('https://api2050.diagorisk.com/pageAleas/?idExploitation=' + idExploit)
      .then((response) => response.json())
      .then((response) => {
        if ('error' in response) {
          // Si la réponse contient une erreur, relancer la requête après 60 secondes
          setTimeout(fetchData, 60000);
        } else {
          // Si la réponse est réussie, mettre à jour les données
          setDataMoy_GEL_2D(response.dataMoy_GEL_2D)
          setDataMoy_GEL_2D(prev => {
            return prev;
          })
          setDataMoy_GEL_4D(response.dataMoy_GEL_4D)
          setDataMoy_GEL_4D(prev => {
            return prev;
          })
          setDataMin_GEL_2D(response.dataMin_GEL_2D)
          setDataMin_GEL_2D(prev => {
            return prev;
          })
          setDataMin_GEL_4D(response.dataMin_GEL_4D)
          setDataMin_GEL_4D(prev => {
            return prev;
          })
          setDataMax_GEL_2D(response.dataMax_GEL_2D)
          setDataMax_GEL_2D(prev => {
            return prev;
          })
          setDataMax_GEL_4D(response.dataMax_GEL_4D)
          setDataMax_GEL_4D(prev => {
            return prev;
          })
          setDataQ1_GEL_2D(response.dataQ1_GEL_2D)
          setDataQ1_GEL_2D(prev => {
            return prev;
          })
          setDataQ1_GEL_4D(response.dataQ1_GEL_4D)
          setDataQ1_GEL_4D(prev => {
            return prev;
          })
          setDataQ3_GEL_2D(response.dataQ3_GEL_2D)
          setDataQ3_GEL_2D(prev => {
            return prev;
          })
          setDataQ3_GEL_4D(response.dataQ3_GEL_4D)
          setDataQ3_GEL_4D(prev => {
            return prev;
          })
          setDataMoy_CDC_2D(response.dataMoy_CDC_2D)
          setDataMoy_CDC_2D(prev => {
            return prev;
          })
          setDataMoy_CDC_4D(response.dataMoy_CDC_4D)
          setDataMoy_CDC_4D(prev => {
            return prev;
          })
          setDataMin_CDC_2D(response.dataMin_CDC_2D)
          setDataMin_CDC_2D(prev => {
            return prev;
          })
          setDataMin_CDC_4D(response.dataMin_CDC_4D)
          setDataMin_CDC_4D(prev => {
            return prev;
          })
          setDataMax_CDC_2D(response.dataMax_CDC_2D)
          setDataMax_CDC_2D(prev => {
            return prev;
          })
          setDataMax_CDC_4D(response.dataMax_CDC_4D)
          setDataMax_CDC_4D(prev => {
            return prev;
          })
          setDataQ1_CDC_2D(response.dataQ1_CDC_2D)
          setDataQ1_CDC_2D(prev => {
            return prev;
          })
          setDataQ1_CDC_4D(response.dataQ1_CDC_4D)
          setDataQ1_CDC_4D(prev => {
            return prev;
          })
          setDataQ3_CDC_2D(response.dataQ3_CDC_2D)
          setDataQ3_CDC_2D(prev => {
            return prev;
          })
          setDataQ3_CDC_4D(response.dataQ3_CDC_4D)
          setDataQ3_CDC_4D(prev => {
            return prev;
          })
          setdataNbjours_GEL_2D(response.dataNbjours_GEL_2D)
          setdataNbjours_GEL_2D(prev => {
            return prev;
          })
          setdataNbjours_GEL_4D(response.dataNbjours_GEL_4D)
          setdataNbjours_GEL_4D(prev => {
            return prev;
          })
          setdataNbjours_CDC_2D(response.dataNbjours_CDC_2D)
          setdataNbjours_CDC_2D(prev => {
            return prev;
          })
          setdataNbjours_CDC_4D(response.dataNbjours_CDC_4D)
          setdataNbjours_CDC_4D(prev => {
            return prev;
          })
          
          setNBJ_GEL_MAX_2D(response.dataNbjoursMax_GEL_2D)
          setNBJ_GEL_MAX_2D(prev => {
            return prev;
          })
          setNBJ_GEL_MAX_4D(response.dataNbjoursMax_GEL_4D)
          setNBJ_GEL_MAX_4D(prev => {
            return prev;
          })
          setNBJ_CDC_MAX_2D(response.dataNbjoursMax_CDC_2D)
          setNBJ_CDC_MAX_2D(prev => {
            return prev;
          })
          setNBJ_CDC_MAX_4D(response.dataNbjoursMax_CDC_4D)
          setNBJ_CDC_MAX_4D(prev => {
            return prev;
          })
          setNBJ_GEL_MIN_2D(response.dataNbjoursMin_GEL_2D)
          setNBJ_GEL_MIN_2D(prev => {
            return prev;
          })
          setNBJ_GEL_MIN_4D(response.dataNbjoursMin_GEL_4D)
          setNBJ_GEL_MIN_4D(prev => {
            return prev;
          })
          setNBJ_CDC_MIN_2D(response.dataNbjoursMin_CDC_2D)
          setNBJ_CDC_MIN_2D(prev => {
            return prev;
          })
          setNBJ_CDC_MIN_4D(response.dataNbjoursMin_CDC_4D)
          setNBJ_CDC_MIN_4D(prev => {
            return prev;
          })
        }
      })
      .catch((error) => {
        // Gérer les erreurs de la requête
        console.error('Erreur lors de la requête API :', error);
      });
  }
  useEffect ( () => {
    fetchData()
  }, [idExploit])
  
  return (
    <div className='containerPrint'>
      <TitreRapport Titre={'Impact des aléas climatique'} Sous_Titre={"Sur toute l'exploitation"} Page={ExploitViti ? '5' : '6'}/>
      <div className='paragraphe'>Les impacts en fonction de chaque aléas est pour chaque année de 2020 à 2050 en moyenne 10 ans.</div>
      <div className='paragraphe'>Les impacts données sont pour l'ensemble de votre exploitation.</div>
      <div className='titreMoins row' style={{marginBottom:'20px', marginTop:'40px'}}> Gel</div>
      <div className='row'>
        <div style={{width:'50%'}}>
          <div className='titreMoins row'> Nombre de jour </div>
          {dataNbjours_GEL_2D !== undefined && dataNbjours_GEL_4D !== undefined  ?<GraphAleasNBjoursRapport2 myDataMoy2D={dataNbjours_GEL_2D} myDataMoy4D={dataNbjours_GEL_4D} myDataMin2D={dataNbjours_GEL_Min_2D} myDataMin4D={dataNbjours_GEL_Min_4D}  myDataMax2D={dataNbjours_GEL_Max_2D} myDataMax4D={dataNbjours_GEL_Max_4D} print={true}/> : ""} 
        </div>
        <div style={{width:'50%'}}>
          <div className='titreMoins row'> Impacts </div>
          <GraphAleasRapport myDataMoy2D={dataMoy_GEL_2D} myDataMax2D={dataMax_GEL_2D} myDataMin2D={dataMin_GEL_2D} myDataMoy4D={dataMoy_GEL_4D} myDataMax4D={dataMax_GEL_4D} myDataMin4D={dataMin_GEL_4D} aleas={'GEL'} print={true}/>
        </div>
      </div>
      
      <div className='titreMoins row' style={{marginBottom:'20px', marginTop:'40px'}}> Coup de chaleur</div>
      <div className='row'>
        <div style={{width:'50%'}}>
          <div className='titreMoins row'> Nombre de jour </div>
          {dataNbjours_CDC_2D !== undefined && dataNbjours_CDC_4D !== undefined  ? <GraphAleasNBjoursRapport2  myDataMoy2D={dataNbjours_CDC_2D} myDataMoy4D={dataNbjours_CDC_4D} myDataMin2D={dataNbjours_CDC_Min_2D} myDataMin4D={dataNbjours_CDC_Min_4D}  myDataMax2D={dataNbjours_CDC_Max_2D} myDataMax4D={dataNbjours_CDC_Max_4D} print={true}/> : ""} 
        </div>
        <div style={{width:'50%'}}>
          <div className='titreMoins row'> Impacts </div>
          <GraphAleasRapport myDataMoy2D={dataMoy_CDC_2D} myDataMax2D={dataMax_CDC_2D} myDataMin2D={dataMin_CDC_2D} myDataQ12D={dataQ1_CDC_2D} myDataQ32D={dataQ3_CDC_2D} myDataMoy4D={dataMoy_CDC_4D} myDataMax4D={dataMax_CDC_4D} myDataMin4D={dataMin_CDC_4D} myDataQ14D={dataQ1_CDC_4D} myDataQ34D={dataQ3_CDC_4D} aleas={'CDC'} print={true}/>
        </div>
      </div>
    </div>
  )
}

export default PageAleasPDF