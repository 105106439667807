import React from "react";
import ReactEcharts from "echarts-for-react";

function GraphAleasNBjoursRapport2({ myDataMin2D, myDataMin4D, myDataMoy2D, myDataMoy4D, myDataMax2D, myDataMax4D,print }) {
  const getOptions = () => {
    let options = {
      grid: {
        top: '18%',
        left: '11%',
        right: '13%',
      },
      legend: {
        data: ['Minimum 2°C','Moyen 2°C','Maximum 2°C', 'Minimum 4°C','Moyen 4°C','Maximum 4°C' ],
        textStyle: {
          fontSize: 14, // Ajustez la taille de la police de la légende selon vos besoins
        },
      },
      xAxis: {
        type: 'category',
        data: ['2020','2025','2030','2035', '2040', '2045', '2050'],
        name: 'Risques',
        nameLocation: 'middle', // Place le nom de l'axe au centre sous l'axe
        nameGap: 30,
        nameTextStyle: {
          fontSize: 16 // Ajustez la taille de la police selon vos besoins
        },
        axisLabel: {
          fontSize: 16, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
          
        }
      },
      yAxis: {
        type: 'value',
        name: 'Nb de jour',
        nameTextStyle: {
          fontSize: 16, // Ajustez la taille de la police selon vos besoins
        },
        axisLabel: {
          fontSize: 16, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
        },
      },
      series: [
        {
          name: "Maximum 2°C",
          data: myDataMax2D,
          type: 'line',
          smooth: true,
          itemStyle: {
            color: 'red' // aleas === 'SH' ? '#00C4BF' : aleas==='EE' ?  '#001570' : aleas==='CDC' ?  '#b97400' : '#008f4e' 
          },
          lineStyle: {
            width: 2, // Ajustez la largeur de la ligne selon vos besoins
          },
          symbolSize: 10,
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              formatter: function (param) {
                return param.data.toFixed(2)
              },
              position:'top',
              textStyle: {
                fontSize: 16 // Ajustez la taille de police selon vos préférences
              },
            },
          },
        },
        {
          name: "Moyen 2°C",
          data: myDataMoy2D,
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#dfe300' // aleas === 'SH' ? '#00F1F1' : aleas==='EE' ?  '#002FF1' : aleas==='CDC' ?  '#F19600' : '#00E57D' 
          },
          lineStyle: {
            width: 2, // Ajustez la largeur de la ligne selon vos besoins
          },
          symbolSize: 10,
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              formatter: function (param) {
                return param.data.toFixed(2)
              },
              position:'top',
              textStyle: {
                fontSize: 16 // Ajustez la taille de police selon vos préférences
              },
            },
          },
        },
        {
          name: "Minimum 2°C",
          data: myDataMin2D,
          type: 'line',
          smooth: true,
          itemStyle: {
            color: 'green' //aleas === 'SH' ? '#93FFFF' : aleas==='EE' ?  '#6986FF' : aleas==='CDC' ?  '#ffc25c' : '#6effbd' 
          },
          lineStyle: {
            width: 2, // Ajustez la largeur de la ligne selon vos besoins
          },
          symbolSize: 10,
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              formatter: function (param) {
                return param.data.toFixed(2)
              },
              position:'top',
              textStyle: {
                fontSize: 16 // Ajustez la taille de police selon vos préférences
              },
            },
          },
        },
        {
          name: "Maximum 4°C",
          data: myDataMax4D,
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#870000' // aleas === 'SH' ? '#00C4BF' : aleas==='EE' ?  '#001570' : aleas==='CDC' ?  '#b97400' : '#008f4e' 
          },
          lineStyle: {
            width: 2, // Ajustez la largeur de la ligne selon vos besoins
          },
          symbolSize: 10,
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              formatter: function (param) {
                return param.data.toFixed(2)
              },
              position:'top',
              textStyle: {
                fontSize: 16 // Ajustez la taille de police selon vos préférences
              },
            },
          },
        },
        {
          name: "Moyen 4°C",
          data: myDataMoy4D,
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#b4b700' // aleas === 'SH' ? '#00F1F1' : aleas==='EE' ?  '#002FF1' : aleas==='CDC' ?  '#F19600' : '#00E57D' 
          },
          lineStyle: {
            width: 2, // Ajustez la largeur de la ligne selon vos besoins
          },
          symbolSize: 10,
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              formatter: function (param) {
                return param.data.toFixed(2)
              },
              position:'top',
              textStyle: {
                fontSize: 16 // Ajustez la taille de police selon vos préférences
              },
            },
          },
        },
        {
          name: "Minimum 4°C",
          data: myDataMin4D,
          type: 'line',
          smooth: true,
          itemStyle: {
            color: 'green' //aleas === 'SH' ? '#93FFFF' : aleas==='EE' ?  '#6986FF' : aleas==='CDC' ?  '#ffc25c' : '#6effbd' 
          },
          lineStyle: {
            width: 3, // Ajustez la largeur de la ligne selon vos besoins
          },
          symbolSize: 10,
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              formatter: function (param) {
                return param.data.toFixed(2)
              },
              position:'top',
              textStyle: {
                fontSize: 16 // Ajustez la taille de police selon vos préférences
              },
            },
          },
        },
      ],
    };

    return options;
  };

  return (
    <div
      style={{
        marginBottom: print === true ? '10px' : '20px',
        width: print === true ? '65%' : '80%',
        height: print === true ? '550px' : '450px',
        marginTop: print === true ? '10px' : '30px',
      }}
    >
      <ReactEcharts option={getOptions()} style={{ height: '100%', width: '100%' }} />
    </div>
  );
}

export default GraphAleasNBjoursRapport2;
