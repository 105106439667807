import React, {useEffect, useState, useRef} from 'react';
import apiLink from '../../config.js'
import {useLocation, Navigate} from 'react-router-dom'
import axios from "axios";
import img from '../../assets/opti_assol.jpg'

import Entete from '../MEP/entete'
import OADMenu from '../MEP/oadMenu'
import ButtonQuestions from '../MEP/button question.js';
import ButtonVidéos from '../MEP/buttonVidéo.js';

import balancePG from '../../assets/balance-equiv.png'
import balancePD from '../../assets/balance-inv.png'
import balancePP from '../../assets/balance-droite.png'

import GraphAssolement3 from './Graphiques/assolement_graph_3';
import GraphCamemberg from './Graphiques/assolement_camemberg';
import Culture from '../Compte/Assolement/culture_assol';
import CultureAjoutSpe from '../Compte/Assolement/culture_assol_ajout_spe';
import GrapheIR1 from './Graphiques/Graphe_Impact_Risque_1';
import GrapheIR2 from './Graphiques/Graphe_Impact_Risque_2';
import GrapheIC1 from './Graphiques/Graphe_Impact_Culture_1';
import GrapheIC2 from './Graphiques/Graphe_Impact_Culture_2';
import TreemapOptiAssol from './Graphiques/treemap_opti_assol';

function OptimisationAssolement() {
    const { state } = useLocation();
    const { idExploit } = state;
    const [goToHome, setGoToHome] = React.useState(false)
    const ScrollProd= useRef(null);
    const [showSemis, setShowSemis] = React.useState(false)

    const [TypeCulture, setTypeCulture] = useState("")
    const [city, setCity] = useState()
    const [valueSpeaddOtherSpe, setValueSpeAdddOtherSpe]= useState()
    const [dataPlusCult, setdataPlusCult]= useState({})
    const [dataDefaut, setDataDefaut] = useState()
    const [dataExploit, setDataExploit] = useState() 
    const [data,setData]= useState({})
    const [PrezExpType, setPrezExpType] = useState()
    
    const mesDonnees = new FormData(); 
    const mesDonneesP = new FormData(); 
    const idAgent = JSON.parse(localStorage.getItem('idAgent'));
    const role = JSON.parse(localStorage.getItem('role'));
    const formule = JSON.parse(localStorage.getItem('formule'));
    const [idExploitComp, setidExploitComp]= React.useState(false);

    const [ShowResult, setShowResult]= React.useState(false);
    const [ShowDetails1, setShowDetails1]= React.useState(false);
    const [ShowDetails2, setShowDetails2]= React.useState(false);
    const [dataPourcCult1, setDPC1] = React.useState([])
    const [dataPourcCult2, setDPC2] = React.useState([])
    const [myDataSurf1, setDataSurf1]= React.useState([]);
    const [myDataSurf2, setDataSurf2]= React.useState();
    const [myDataPB1, setDataPB1]= React.useState([]);
    const [myDataPB2, setDataPB2]= React.useState([]);
    const [goToSimmulation, setGoToSimulation] = useState(false)
    const [GoToTarif,setGoToTarif] = useState(false)

    const [dataTreeMap1, setdataTreeMap1]= React.useState([
      {"name":"Blé tendre \n2.9","value":"2.9",itemStyle : { "color":"#FFD705"}},
      {"name":"Orge de printemps \n3.7","value":"3.7",itemStyle : { "color":"#FFF385"}},
      {"name":"Maïsgrain \n5.1","value":"5.1",itemStyle : { "color":"#92D050"}},
      {"name":"Colza \n1.9","value":"1.9",itemStyle : { "color":"#D7A657"}},
      {"name":"Tournesol \n2.5","value":"2.5",itemStyle : { "color":"#F4D9B6"}},
      {"name":"Fève et féverole \n5.7","value":"5.7",itemStyle : { "color":"#D6EAF8"}},
      {"name":"Pois protéagineux \n5.5","value":"5.5",itemStyle : { "color":"#F4B084"}}]);
    const [dataTreeMap2, setdataTreeMap2]= React.useState([
      {"name":"Blé tendre \n2.5","value":"2.5",itemStyle : { "color":"#FFD705"}},
      {"name":"Orge de printemps \n3.7","value":"3.7",itemStyle : { "color":"#FFF385"}},
      {"name":"Colza \n2.7","value":"2.7",itemStyle : { "color":"#D7A657"}},
      {"name":"Tournesol \n2.5","value":"2.5",itemStyle : { "color":"#F4D9B6"}},
      {"name":"Fève et féverole \n5.6","value":"5.6",itemStyle : { "color":"#D6EAF8"}},
      {"name":"Pois protéagineux \n5.5","value":"5.5",itemStyle : { "color":"#F4B084"}}]);
    /* const [dataTreeMap1, setdataTreeMap1]= React.useState([])
    const [dataTreeMap2, setdataTreeMap2]= React.useState([]) */
    const [DifRisques, setDifRisques]= React.useState(908)

    const [surfTot1, setsurfTot1]= React.useState(0);
    const [PBTot1, setPBTot1]= React.useState(0);
    const [dataIR1, setDataIR1]= React.useState([{'x':-800, 'y': 1, 'color':'#FFC000', 'label':"Courant"}, {'x':200, 'y': 2, 'color':'#70AD47', 'label':"Exceptionnel"}, {'x':-1100, 'y': 3, 'color':'#C55A11', 'label':"Catastrophique"}]);
    const [dataIR2_nouveau, setDataIR2_nouveau]= React.useState([{'x':45200, 'y': 1, 'color':'#FFC000', 'label':"Courant"}, {'x':60000, 'y': 2, 'color':'#70AD47', 'label':"Exceptionnel"}, {'x':67500, 'y': 3, 'color':'#C55A11', 'label':"Catastrophique"}]);
    const [dataIR2_actuel, setDataIR2_actuel]= React.useState([{'x':-46000, 'y': 1, 'color':'#FFC000', 'label':"Courant"}, {'x':-59800, 'y': 2, 'color':'#70AD47', 'label':"Exceptionnel"}, {'x':-68600, 'y': 3, 'color':'#C55A11', 'label':"Catastrophique"}]);
    const [dataIC1, setDataIC1]= React.useState([{'x':-800, 'y': 1, 'color':'#2E8B57', 'label':"Fève et févérole"}, {'x':3000, 'y': 2, 'color':'#938953', 'label':"Betterave sucrière"}, {'x':1000, 'y': 3, 'color':'#F4D9B6', 'label':"Tournesol"}, {'x':-1700, 'y': 4, 'color':'#92D050', 'label':"Maïs grain"}, {'x':0, 'y': 5, 'color':'#FFD705', 'label':"Blé tendre"}]);
    const [dataIC2_actuel, setDataIC2_actuel]= React.useState([{'x':-8700, 'y': 1, 'color':'#2E8B57', 'label':"Fève et févérole"}, {'x':-5100, 'y': 2, 'color':'#938953', 'label':"Betterave sucrière"}, {'x':-2000, 'y': 3, 'color':'#F4D9B6', 'label':"Tournesol"}, {'x':-44100, 'y': 4, 'color':'#92D050', 'label':"Maïs grain"}, {'x':-7300, 'y': 5, 'color':'#FFD705', 'label':"Blé tendre"}]);
    const [dataIC2_nouveau, setDataIC2_nouveau]= React.useState([{'x':7900, 'y': 1, 'color':'#2E8B57', 'label':"Fève et févérole"}, {'x':8100, 'y': 2, 'color':'#938953', 'label':"Betterave sucrière"}, {'x':3000, 'y': 3, 'color':'#F4D9B6', 'label':"Tournesol"}, {'x':42400, 'y': 4, 'color':'#92D050', 'label':"Maïs grain"}, {'x':7300, 'y': 5, 'color':'#FFD705', 'label':"Blé tendre"}]);

    const CreaDataTreaMap = (dataIC) => {
      const convertedData = dataIC.map((item) => {    
        return {
          name: `${item.label} \n${item.x.toLocaleString()}`,
          value: parseInt(`${item.x}`),
          itemStyle: { color: item.color },
        };
      });
      
      return convertedData;
    }
    const shapeDataExtrapole = (dataIR, dataPourcCult,PB) =>{
      var risqueCou = dataIR[0]['x']/PB*100
      let sommeRisqueCulture = 0;
      // Parcourez le tableau et additionnez les valeurs
      dataPourcCult.forEach(item => {
        sommeRisqueCulture += item.value;
      });

      dataPourcCult.forEach(item => {
        var valueS = Math.round(item.value * risqueCou / sommeRisqueCulture * 10) / 10
        item.value = valueS
        item.name = item.name.replace(/\d+(\.\d+)? %/, valueS + " %");
      } )
      return dataPourcCult

    }

    const CreaDataDupliquee = (dataExploitTest, dataDefaut) => {
      const data = {};
      if (dataDefaut !== undefined) {
        let keyCount = 1;
        for (const exploitKey in dataExploitTest) {
          if (dataExploitTest.hasOwnProperty(exploitKey)) {
            const exploitObj = dataExploitTest[exploitKey];
            const exploitKeys = Object.keys(exploitObj);
    
            if (dataDefaut.hasOwnProperty(exploitKey)) {
              const defautObj = dataDefaut[exploitKey];
              const defautEntry = dataDefaut[exploitKey]
              const defautKeys = Object.keys(defautObj);
    
              for (let i = 1; i < exploitKeys.length; i++) {
                const exploitEntry = exploitObj[exploitKeys[i]];
    
                if (i < defautKeys.length) {  
                  data[keyCount] = {
                    libelle: exploitEntry.libelle,
                    SelectedSpe: exploitEntry.SelectedSpe,
                    surface: exploitEntry.surface,
                    Semis:"",
                    rendement: exploitEntry.rendement,
                    rendementConv: defautEntry.rendement,
                    rendementBio: defautEntry.rendementBio,
                    rendementIrrig: defautEntry.rendementIrrig,
                    prix: exploitEntry.prix,
                    prixConv: defautEntry.prix,
                    prixBio: defautEntry.prixBio,
                    Specification: defautEntry.specifications,
                    key: exploitKey,
                  };
    
                  keyCount++;
                } else {
                  data[keyCount] = {
                    libelle: exploitEntry.libelle,
                    SelectedSpe: exploitEntry.SelectedSpe,
                    surface: exploitEntry.surface,
                    Semis:"",
                    rendement: exploitEntry.rendement,
                    rendementConv: null,
                    rendementBio: null,
                    rendementIrrig: null,
                    prix: exploitEntry.prix,
                    prixConv: null,
                    prixBio: null,
                    Specification: null,
                    key: exploitKey,
                  };
    
                  keyCount++;
                }
              }
            }
          }
        }
      }
      for (const index in data){
        if(data[index]['surface'] === '0.00'){
          delete data[index]
        }
      }
      return data;
    };

    const mergedata = (dataExploit, dataDefaut)=> {
      var data = {};
      if(dataDefaut !== undefined) {
        for (const key in dataExploit) {
          if (dataExploit.hasOwnProperty(key)) {
            const exploitEntry = dataExploit[key][Object.keys(dataExploit[key])[0]];;
            const defautEntry = dataDefaut[key];
      
            data[key] = {
              libelle: exploitEntry.libelle,
              SelectedSpe: exploitEntry.SelectedSpe,
              surface: exploitEntry.surface,
              Semis:"",
              rendement: exploitEntry.rendement,
              rendementConv: defautEntry.rendement,
              rendementBio: defautEntry.rendementBio,
              rendementIrrig: defautEntry.rendementIrrig,
              prix: exploitEntry.prix,
              prixConv: defautEntry.prix,
              prixBio: defautEntry.prixBio,
              Specification: defautEntry.specifications,
            };
          }
        }
      }
      return data;
    } 
    
    const EnvoiResultats = (data, typeCulture, dataPlusCult)=> { //permet de confirgurer les valeurs pour quelles ressortent de manière simplifiées
      var dataOutput = {};
      if(data !== undefined && dataPlusCult !== undefined) { //on vérifie que les données ont bien été récupérées par l'API
        for (const key in data) { //on fait le tour des données de l'exploitation
          if (data.hasOwnProperty(key)) {
            const dataEntry = data[key];
            const speInThisCultre = []
            for (const keyDPC in dataPlusCult) { //on fait le tour des données des culrures duppliquée
              if (dataPlusCult.hasOwnProperty(keyDPC)) {
                const selectedSpeDataPlusCult = dataPlusCult[keyDPC]['key'];
                const selectedSpeData = key;
    
                if ( // on fait une liste s'il existe d'autre spé pour chaque culture 
                  selectedSpeDataPlusCult &&
                  selectedSpeData &&
                  selectedSpeDataPlusCult.toLowerCase() === selectedSpeData.toLowerCase()
                ) {
                  speInThisCultre.push(keyDPC);
                }
              }
            }
            dataOutput[key] = {};
            dataOutput[key][dataEntry['SelectedSpe'].toString()] = {
              libelle: dataEntry.libelle,
              SelectedSpe: dataEntry.SelectedSpe,
              surface: dataEntry.surface,
              rendement: dataEntry.rendement,
              prix: dataEntry.prix,
              typeCulture: typeCulture,
              Semis:dataEntry.Semis,
            };
            if (speInThisCultre.length !== 0) {
              for (const indice in speInThisCultre) {
                const index = speInThisCultre[indice]
                const speSelectionnee = dataPlusCult[index]['SelectedSpe'].toString()
                dataOutput[key][speSelectionnee] = {
                  libelle: dataPlusCult[index]['libelle'],
                  SelectedSpe: dataPlusCult[index]['SelectedSpe'],
                  surface: dataPlusCult[index]['surface'],
                  rendement: dataPlusCult[index]['rendement'],
                  prix: dataPlusCult[index]['prix'],
                  typeCulture: typeCulture,
                  Semis:dataPlusCult[index]['Semis'],
                };
              }
            }          
          }
        }
      }
      return dataOutput;
    } 

    //Fonction qui prend en entrée la même chose que la précédente et utilise la précédente pour initialiser les données puis prend aussi dataDefaut pour compléter les spé que l'on a pas 

    const EnvoiCompletResultats = (data, typeCulture, dataPlusCult, dataDefault) => {
      const dataOutPut = EnvoiResultats(data, typeCulture, dataPlusCult)
      if (dataOutPut !== undefined && dataDefault !== undefined) {
        // On vérifie que les données ont bien été récupérées par l'API
        for (const key in dataOutPut) {
          if (dataOutPut.hasOwnProperty(key)) {
            const specifications = dataDefault[key]['specifications'];
    
            if (!dataOutPut[key].hasOwnProperty('none')) {
              dataOutPut[key]['none'] = {
                libelle: dataDefault[key]['libelle'],
                SelectedSpe: 'none',
                surface: '0.00',
                rendement: '0.00',
                prix: '0.00',
                typeCulture: 'Conventionnelle',
                Semis:""
              };
            }
    
            for (const specKey in specifications) {
              if (specifications.hasOwnProperty(specKey)) {
                if (!dataOutPut[key].hasOwnProperty(specKey)) {
                  dataOutPut[key][specKey] = {
                    libelle: dataDefault[key]['libelle'],
                    SelectedSpe: specKey,
                    surface: '0.00',
                    rendement: specifications[specKey]['rendement'],
                    prix: specifications[specKey]['prix'],
                    typeCulture: 'Conventionnelle',
                    Semis:""
                  };
                }
              }
            }
          }
        }
      }
      return dataOutPut;
    } 

    useEffect(() => {
        fetch(apiLink + 'page/exploitation/' + idExploit)
        .then( (response) => {
        return response.json()
        })
        .then(response =>{
          if (response !== "Exploitaiton " + idExploit + " non trouvée") {
            setDataSurf1(response.dataSurface)
            setDataSurf1(prev => {
                return prev;
            })
            setDataPB1(response.dataPB)
            setDataPB1(prev => {
                return prev;
            })
            setsurfTot1(response.surfaceTotale)
            setsurfTot1(prev => {
                return prev;
            })
            setPBTot1(response.PBTotal)
            setPBTot1(prev => {
                return prev;
            })
          } else {
            localStorage.clear()
            setGoToHome(true)
          }
        })

        const items = JSON.parse(localStorage.getItem('idAgent'));
        const items2 = JSON.parse(localStorage.getItem('idExploitationPrinicpale'));

        fetch(apiLink + 'getExploitations/' + items)
        .then ( (response) => {
          return response.json()
        })
        .then (response => {
          if (idExploit in response ){
            setPrezExpType(false)
          }
          else {
            setPrezExpType(true)
          }
        })

        fetch(apiLink + 'getExploitation/' + idExploit) // récupère assolement exploitation principale
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
          if (response !== "Exploitaiton " + idExploit + " non trouvée") {
            var bio = false 
            var irrig = false 
            if ("none" in response["111"]) {
              bio = response["111"]["none"]["bio"]
              irrig = response["111"]["none"]["irrigation"]
            }
            else{
              bio = response["111"]["1"]["bio"]
              irrig = response["111"]["1"]["irrigation"]
            }
            var TC = ""
            if (bio === true){
              TC = "Biologique"
            }
            else if (irrig === true){
              TC = "Irriguee"
            }
            else {
              TC = "Conventionnelle"
            }
            setTypeCulture(TC)
            setTypeCulture(prev => {
                return prev;
            })
            var DExp = response

            fetch(apiLink + 'getLocalisation/' + items2)
              .then( (response) => {
                  return response.json()
              })
              .then(response =>{
                let city
                city = response.idCommune
                setCity(response.idCommune)
                fetch(apiLink + 'getExploitationDefault/' + city)
                .then( (response) => {
                  return response.json()
                })
                .then(response =>{
                  var DDef=response
                  setData(mergedata(DExp,DDef))
                  setdataPlusCult(CreaDataDupliquee(DExp,DDef))
                  setDataDefaut(response)
                  setDataDefaut(prev => {
                    return prev;
                  })
              })
            })
            setDataExploit(response)
            setDataExploit(prev => {
            return prev;
            })
          } else {
            localStorage.clear()
            setGoToHome(true)
          }
        })
    }, [idExploit]);

    const culturesDispo = (ClickedGC, ClickedViti) => {
      var obj 
      if(ClickedGC){
        obj = Object.keys(data).map((item)=>( item !=="630" && item !=="670" && item !=="675"?
          {"key": item, "label":data[item].libelle, "nbspe":Object.keys(data[item]["Specification"]).length} : null
        ))
      }
      else if (ClickedViti){
        obj = Object.keys(data).map((item)=>( item ==="630" || item ==="670" || item ==="675"?
          {"key": item, "label":data[item].libelle, "nbspe":Object.keys(data[item]["Specification"]).length} : null
        ))
      }
      else {
        obj = Object.keys(data).map((item)=>( 
          {"key": item, "label":data[item].libelle, "nbspe":Object.keys(data[item]["Specification"]).length}
        ))
      }
      return obj
    }

    const [ShowDupliquerProd, setShowDupliquerProd]= useState(false);
    const [ShowAjouterProd, setShowAjouterProd]= useState(false);
    const [ClickedGC, setClickedGC]= useState(false);
    const [ClickedViti, setClickedViti]= useState(false);
    const [ClickedMixte, setClickedMixte]= useState(true);

    const legendeCultures = Object.keys(dataIC1).map(key => (
        <div style={{color:`${dataIC1[key]["color"]}`, fontWeight:'bold'}}>
            {dataIC1[key]['label']}
        </div>
    ));
//permet de stocker si bio, conventionnel ou irrigué
 //permet de stocker si bio, conventionnel ou irrigué
 function handleChangeTypeCulture(event) {
  var TC = event.target.value;
  setTypeCulture(TC);
  Object.keys(data).map(key => {
    var newData = { ...data };
    if (TC === "Conventionnelle") {
      if (data[key]['SelectedSpe'] !== "none") {
        newData[key]["rendement"] = data[key]['Specification'][data[key]['SelectedSpe']]['rendement'];
        newData[key]["prix"] = data[key]['Specification'][data[key]['SelectedSpe']]['prix'];
      } else {
        newData[key]["rendement"] = data[key]['rendementConv'];
        newData[key]["prix"] = data[key]['prixConv'];
      }
    } else {
      if (data[key]['SelectedSpe'] !== "none") {
        newData[key]["rendement"] = data[key]['Specification'][data[key]['SelectedSpe']]['rendementBio'];
        newData[key]["prix"] = data[key]['Specification'][data[key]['SelectedSpe']]['prixBio'];
      } else {
        newData[key]["rendement"] = data[key]['rendementBio'];
        newData[key]["prix"] = data[key]['prixBio'];
      }
    }
    setData(newData);
  });
  Object.keys(dataPlusCult).map(keyDC => {
    var newDataPlusCult = { ...dataPlusCult };
    if (TC === "Conventionnelle") {
      if (dataPlusCult[keyDC]['SelectedSpe'] !== "none") {
        newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['rendement'];
        newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['prix'];
      } else {
        newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['rendementConv'];
        newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['prixConv'];
      }
    } else {
      if (dataPlusCult[keyDC]['SelectedSpe'] !== "none") {
        newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['rendementBio'];
        newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['Specification'][dataPlusCult[keyDC]['SelectedSpe']]['prixBio'];
      } else {
        newDataPlusCult[keyDC]["rendement"] = dataPlusCult[keyDC]['rendementBio'];
        newDataPlusCult[keyDC]["prix"] = dataPlusCult[keyDC]['prixBio'];
      }
    }
    setdataPlusCult(newDataPlusCult);
  });
  
}


  //tableau des cultures de base
  const table_assol=  Object.keys(data).map(key => (
    <Culture 
    keys={key}
    data={data}
    MAJ_surf_rdt_prix_spe={MAJ_surf_rdt_prix_spe}
    ClickedGC={ClickedGC}
    ClickedMixte= {ClickedMixte}
    ClickedViti={ClickedViti}
    libelle={data[key]["libelle"]}
    showSemis={showSemis}
    />
  ))
  //tableau des cultures dupliqué pour ajouter des spés
  const table_assol_spe_sup = Object.keys(dataPlusCult).map(clef => (
    <CultureAjoutSpe 
    keys={clef}
    data={dataPlusCult}
    MAJ_surf_rdt_prix_spe={MAJ_surf_rdt_prix_spe_Cult_Plus}
    SuppressionSpe={SuppressionSpe}
    libelle={dataPlusCult[clef]["libelle"]}
    showSemis={showSemis}
    />
  ))

  function formatDateSemis(data, dataPlusCult) {
    var formatIsValid = true; // Initialise à true
    var regex = /^[0-3]{1}[0-9]{1}\.[0-1]{1}[0-9]{1}$/;
  
    for (var key in data) {
      if (parseFloat(data[key]["surface"]) !==0 && !regex.test(data[key]["Semis"])) {
        formatIsValid = false;
        break; // Si une correspondance invalide est trouvée, on peut sortir de la boucle
      }
    }
    if (formatIsValid) {
      for (var key in dataPlusCult) {
      if (parseFloat(dataPlusCult[key]["surface"]) !== 0 && !regex.test(dataPlusCult[key]["Semis"])) {
        formatIsValid = false;
        break; // Si une correspondance invalide est trouvée, on peut sortir de la boucle
      }
    }
    }
  
    return formatIsValid;
  }

  //choix de la spé à dupliquer
  function handlechangeOtherSpe(event) {
    setValueSpeAdddOtherSpe(event.target.value)
  }

  //Ajout d'une nouvelle culture dans les liste de celles dupliquées
  function addOtherSpe(){
    var lengthSpe = Object.keys(data[valueSpeaddOtherSpe]["Specification"]).length
    var Nbligne = 0
    for (var item in dataPlusCult) {
      if (dataPlusCult[item]['key'] === valueSpeaddOtherSpe) {
        Nbligne += 1;
      }
    }
    if(Nbligne < lengthSpe) {
      var sumDPC = Object.keys(dataPlusCult).pop() //récupère la dernière clef de l'objet
      var clef = 0
      if(sumDPC === undefined){
        clef = 1
      }
      else {
        clef = parseInt(sumDPC) + 1
      }
      var newLigne = {
        [clef]:{
          "key": valueSpeaddOtherSpe,
          "libelle":data[valueSpeaddOtherSpe]["libelle"],
          "SelectedSpe":"none",
          "surface":0.00,
          "rendement":data[valueSpeaddOtherSpe]["rendement"],
          "rendementConv" :data[valueSpeaddOtherSpe]["rendementConv"],
          "rendementBio":data[valueSpeaddOtherSpe]["rendementBio"] ,
          "prix":data[valueSpeaddOtherSpe]["prix"],
          "prixConv":data[valueSpeaddOtherSpe]["prixConv"],
          "prixBio":data[valueSpeaddOtherSpe]["prixBio"],
          "Specification": data[valueSpeaddOtherSpe]["Specification"],
          "Semis":""
        }  
      }
      setdataPlusCult({...dataPlusCult, ...newLigne});
      //setdataPlusCult(Object.assign(dataPlusCult,newLigne))
    }
    else {
      alert("Vous ne pouvez pas ajouter plus de ligne de culture qu'il n'y a de spécification")
    }
  }

  //fonction de suppression d'une culture dupliquée
  function SuppressionSpe(key){
    const updateddata = { ...dataPlusCult};
    delete updateddata[key];
    setdataPlusCult(updateddata);
    setdataPlusCult(prev => {
      return prev
    })
  }

  // Mise à jour des données de surfaces, rdt, prix et spécification de chaque culture
  function MAJ_surf_rdt_prix_spe(key,type, valeur) {
    var newData = {...data};
    if (typeof valeur === "string") {
      newData[key][type] = valeur
    }
    else { 
      newData[key][type] = parseFloat(valeur)
    }
    setData(newData)
    setData(prev => {
      return prev;
    }) 
  }
  function MAJ_surf_rdt_prix_spe_Cult_Plus(key,type, valeur) {
    var newData = {...dataPlusCult};
    if (typeof valeur === "string") {
      newData[key][type] = valeur
    }
    else { 
      newData[key][type] = parseFloat(valeur)
    }
    setdataPlusCult(newData)
    setdataPlusCult(prev => {
      return prev;
    }) 
  }

  // calcul du total des surfaces
  const total_surf1 = (data) => {
    var surf_tot1 = 0
    for (var key in data ) {
      surf_tot1 +=  parseFloat(data[key]["surface"])
    }
    return surf_tot1
  }
  const total_surf2 = (dataPlusCult) => {
    var surf_tot2 = 0
    if (dataPlusCult === undefined) {
      surf_tot2 = 0
    }
    else {
      for (var key in dataPlusCult ) {
        surf_tot2 +=  parseFloat(dataPlusCult[key]["surface"])
      }
    }
    return surf_tot2
  }
  const total_surf = (data, dataPlusCult) => { 
    return total_surf1(data) + total_surf2(dataPlusCult) 
  }

  // calcul du total des PB
  const total_PB1 = (data) => {
    var PB_tot1 = 0
    for (var key in data ) {
      if(parseFloat(data[key]["surface"]) !== 0){
        PB_tot1 += Math.round( parseFloat(data[key]["surface"])* parseFloat(data[key]["rendement"])* parseFloat(data[key]["prix"]))
      } else {
        PB_tot1 +=0
      }

      
    }
    return PB_tot1
  }
  const total_PB2 = (dataPlusCult) => {
    var PB_tot2 = 0
    if (dataPlusCult === undefined) {
      PB_tot2 = 0
    }
    else {
      for (var key in dataPlusCult ) {
        if (parseFloat(dataPlusCult[key]["surface"]) !== 0){
          PB_tot2 += Math.round( parseFloat(dataPlusCult[key]["surface"])* parseFloat(dataPlusCult[key]["rendement"])* parseFloat(dataPlusCult[key]["prix"]))
        } else {
          PB_tot2 += 0
        }
        
      }
    }
    return PB_tot2
   
  }
  const total_PB = (data, dataPlusCult) => {
    return (total_PB1(data) + total_PB2(dataPlusCult))
  }

  // cherche si une culture dupliqué n'a pas de surface 
  function Spe_dupliquee_sans_surf(dataPlusCult){
    var surfNull= false 
    for (var key in dataPlusCult ) {
      if (dataPlusCult[key]["surface"] === 0) {
        surfNull = true
      }
    }
    return surfNull
  }

  // cherche si une production à deux fois la même spé 
  function prod_spe_en_double(data, dataPlusCult){
    var PED = false 
    for (var keyD in data ) { // on fait le tour des productions de base 
      var SpeList = data[keyD]["Specification"] // on récupère la liste des spécifications
      var SpeSelected = {"none": 0} //initialisation de liste avec le sans spé
      for (var keySL in SpeList){
        Object.assign(SpeSelected,{[keySL]:0}) 
      }
      var firstSpe = data[keyD]["SelectedSpe"]
      SpeSelected[firstSpe] += 1// on ajoute 1 pour la spé sélectionné par la première culture 
      for (var keyDC in dataPlusCult ) { // a chaque fois on fait le tour des productions duppliquées
        if(dataPlusCult[keyDC]["key"] === keyD) {
          var SS = dataPlusCult[keyDC]["SelectedSpe"]
          SpeSelected[SS] += 1  //si la key est égale à keyD alors on ajoute 1 à la spé
        }
      }
      for (var keySS in SpeSelected){
        if(SpeSelected[keySS] > 1){
          PED = true //si une spé à plus de 1 itération on arrête la fonction et on return true
          return PED
        }
      }
    }
    return PED
  }

  //fonction pour récupérer la somme des des pertes moyennes 
  function MoyRiskCult(dataTR){
    var sum = dataTR.reduce((total, item) => total + item.value, 0);
    return sum
  }

  function changeOrderOfCulturesIC(IC){
    var updated =IC
                .map((item, index) => ({ ...item, y: index + 1 }));
    return updated

  }
  function removeZero(IC){
    var updated =IC
                .filter(item => item.x !== 0) // Remove items with 'x' equal to 0
    return updated
  }
  // foinction d'envoi des données au serveur
  function handleSubmit(event) {
    var surfaceNull = Spe_dupliquee_sans_surf(dataPlusCult)
    var productionEnDouble = prod_spe_en_double(data, dataPlusCult)
    var FormatDateDeSemis = formatDateSemis(data, dataPlusCult)
    event.preventDefault()
    if (data["630"] !== undefined && ((data["630"]["surface"]===0 && data["630"]["SelectedSpe"]!=="none") || (data["670"]["surface"]===0 && data["670"]["SelectedSpe"]!=="none") || (data["675"]["surface"]===0 && data["675"]["SelectedSpe"]!=="none"))){
      alert('Vous devez choisir un type de vin pour le surface en viticulture saisie')
    }
    else if (surfaceNull === true) {
      alert("L'une des productions duppliquées ne possède pas de surface, veuillez soit : \n- Supprimer cette production \n- Ajouter une surface à cette production ")
    }
    else if (productionEnDouble === true){
      alert("L'une de vos production possède deux (ou plus) fois la même spécification, veuillez soit \n - Supprimer le production avec la spécification en double \n - Changer la spécification ")
    }
    else{
      setShowResult(true)
      var prod = JSON.stringify(EnvoiCompletResultats(data, TypeCulture, dataPlusCult, dataDefaut))
      mesDonnees.set("productions", prod);
      mesDonnees.set("nom", "Exploitation Comparaison");
      mesDonnees.set("visible", 0);

      axios({
          method: 'post',
          url: apiLink + 'setExploitation/' + idAgent,
          data: mesDonnees
      })
      .then(function (reponse) {
            if(reponse.status === 200){
                var idExploitComp
                idExploitComp = reponse.data.idExploitation
                setidExploitComp(reponse.data.idExploitation)
                fetch(apiLink + 'page/optimisationassolement/' + idExploit + '/' + idExploitComp)
                .then( (response) => {
                return response.json()
                })
                .then(response =>{
                  if(response.dataSurface[0]['itemStyle']['color'] === null) {
                    fetch(apiLink + 'page/optimisationassolement/' + idExploit + '/' + idExploitComp)
                    .then( (response) => {
                    return response.json()
                    })
                    .then(response => {
                      setDataSurf2(response.dataSurface)
                      setDataSurf2(prev => {
                          return prev;
                      })
                      setDPC1(response.dataPourcCult1)
                      setDPC1(prev => {
                        return prev
                      })
                      setDPC2(response.dataPourcCult2)
                      setDPC2(prev => {
                        return prev
                      })
                      setDataIR1(response.dataIR1)
                      setDataIR1(prev => {
                          setDifRisques((prev[0]['x'] + prev[1]['x'] + prev[2]['x'])/3)
                          return prev;
                      })
                      setDataIR2_actuel(response.dataIR2_actuel) 
                      setDataIR2_actuel(prev => {
                          return prev;
                      })
                      setDataIR2_nouveau(response.dataIR2_nouveau)
                      setDataIR2_nouveau(prev => {
                          return prev;
                      })
                      setDataIC1(changeOrderOfCulturesIC(response.dataIC1))
                      setDataIC1(prev => {
                          return prev;
                      })
                      setDataIC2_actuel(changeOrderOfCulturesIC(response.dataIC2_actuel))
                      setDataIC2_actuel(prev => {
                          setdataTreeMap1(CreaDataTreaMap(prev))
                          return prev;
                      })
                      setDataIC2_nouveau(changeOrderOfCulturesIC(response.dataIC2_nouveau))
                      setDataIC2_nouveau(prev => {
                        setdataTreeMap2(CreaDataTreaMap(prev))
                          return prev;
                      })

                    })
                  }
                  else {
                    setDataSurf2(response.dataSurface)
                    setDataSurf2(prev => {
                        return prev;
                    })
                    setDPC1(response.dataPourcCult1)
                    setDPC1(prev => {
                      return prev
                    })
                    setDPC2(response.dataPourcCult2)
                    setDPC2(prev => {
                      return prev
                    })
                    setDataIR1(response.dataIR1)
                    setDataIR1(prev => {
                        setDifRisques((prev[0]['x'] + prev[1]['x'] + prev[2]['x'])/3)
                        return prev;
                    })
                    setDataIR2_actuel(response.dataIR2_actuel) 
                    setDataIR2_actuel(prev => {
                        return prev;
                    })
                    setDataIR2_nouveau(response.dataIR2_nouveau)
                    setDataIR2_nouveau(prev => {
                        return prev;
                    })
                    setDataIC1(changeOrderOfCulturesIC(response.dataIC1))
                    setDataIC1(prev => {
                        return prev;
                    })
                    setDataIC2_actuel(changeOrderOfCulturesIC(response.dataIC2_actuel))
                    setDataIC2_actuel(prev => {
                        setdataTreeMap1(CreaDataTreaMap(prev))
                        return prev;
                    })
                    setDataIC2_nouveau(changeOrderOfCulturesIC(response.dataIC2_nouveau))
                    setDataIC2_nouveau(prev => {
                      setdataTreeMap2(CreaDataTreaMap(prev))
                        return prev;
                    })
                  }
                    
                })
                fetch(apiLink + 'page/exploitation/' + idExploitComp)
                .then( (response) => {
                return response.json()
                })
                .then(response =>{
                  setDataPB2(response.dataPB)
                  setDataPB2(prev => {
                      return prev;
                  })
                })
            }
        else {
          alert("Une erreur c'est produite, votre assolement n'a pas pû être enregistré")
        }
      })
      .catch(function (erreur) {
          //On traite ici les erreurs éventuellement survenues
          console.log(erreur);
      });

      setTimeout(() => {
        if (ScrollProd.current) {
          ScrollProd.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }
  }
  function handleShowSemis(){
    if (showSemis){
      setShowSemis(false)
    }
    else {
      setShowSemis(true)
    }
  }
    
    function handleShowD1() {
        setShowDetails1(true)
    }
    function handleHideD1() {
        setShowDetails1(false)
    }
    function handleShowD2() {
        setShowDetails2(true)
    }
    function handleHideD2() {
        setShowDetails2(false)
    }
    function handleGoToTarifs(){
      setGoToTarif(true)
    }
    function handleNewSimul(event) {
        event.preventDefault()
        var prod = JSON.stringify(EnvoiCompletResultats(data, TypeCulture, dataPlusCult, dataDefaut))
        fetch(apiLink + 'updateExploitationVisible/' + idExploitComp + '/1')
        .then( (response) => {
          mesDonneesP.set("productions", prod);
          mesDonneesP.set("nom", "Exploitation Comparaison");
          mesDonneesP.set("idCommune", city);
          mesDonneesP.set("IdExploitation", idExploitComp);
          var JSON_P = {"productions" : prod, "nom": "Exploitation Comparaison", "idCommune": city, "idExploitation":idExploitComp}
          
          const jsonData = JSON.stringify(JSON_P);

          axios.post('https://api2050.diagorisk.com/newSimul/', jsonData)
            .catch(error => {
              // Handle errors here
              console.error('Error:', error);
            });
          })
          setGoToSimulation(true)
    }

    function handleShowDupliProd() {
      setShowDupliquerProd(true)
    }
    function handleHideDupliProd() {
        setShowDupliquerProd(false)
    }
    function handleShowAjouProd() {
        setShowAjouterProd(true)
    }
    function handleHideAjouProd() {
        setShowAjouterProd(false)
    }
    function handleClickedGC() {
      setClickedGC(true)
      setClickedViti(false)
      setClickedMixte(false)
    }
    function handleClickedViti() {
      setClickedGC(false)
      setClickedViti(true)
      setClickedMixte(false)
    }
    function handleClickedMixte() {
      setClickedGC(false)
      setClickedViti(false)
      setClickedMixte(true)
    }

    if(goToSimmulation) {
        return <Navigate push to={`/simulations`} />
    }
    if(GoToTarif) {
      return <Navigate push to={`/abonnements`} />
    }
    if(goToHome) {
      return <Navigate push to={`/`} />
    }

    return (
        <div>
            <Entete titre="Optimisation de l'assolement"/> 
            {role!==null && (formule==="Premium" || formule==="Essentielle")? ( 
            PrezExpType ? (
              <div className='row'>
                  <div style={{width:'50%'}}>
                    <div className='titrePlus'> Page indisponible pour une simulation avec une exploitation type </div>
                    <div className='paragraphe' > Pour consulter cette page utilisez une de vos propres simulations </div>
                  </div>
                  <div style={{width:'45%'}}>
                  <img src={img} alt="capture d'écran exemple de la page Optimisation d'assolement" style={{width:'100%'}}/>
                  </div>
                </div>
            ) : ( 
            <div className='container'>
              <div className='titrePlusVideo'>
                <div className='titre' style={{marginTop:('15px')}}> Quel assolement est le plus adapté à votre situation ?</div>
                <ButtonVidéos link={'https://www.youtube.com/watch?v=HFX8bK7E-68&ab_channel=DiagoRisk'}/>
              </div>
                <div className='paragraphe'> Complétez le nouvel assolement</div>
                <div onSubmit={handleSubmit}>
                  <div className='dispLigne'>
                    <button className= {ClickedGC ? 'validation validationCompGCVITIMI valCompGCC' : 'validation validationCompGCVITIMI valCompGCUnC'} onClick={handleClickedGC} >
                      Grandes cultures
                    </button>
                    <button className= {ClickedViti ?'validation validationCompGCVITIMI valCompVitiC' : 'validation validationCompGCVITIMI valCompVitiUnC'} onClick={handleClickedViti}>
                      Viticulture
                    </button>
                    <button className= {ClickedMixte ?'validation validationCompGCVITIMI valCompMixteC' : 'validation validationCompGCVITIMI valCompMixteUnC'} onClick={handleClickedMixte}>
                      Mixte
                    </button>
                  </div>
                  {TypeCulture !== "" ? (
                      <div className='row' onChange={handleChangeTypeCulture} style={{justifyContent:'left', marginBottom:'0px'}}> 
                          <input type="radio" value="Conventionnelle" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "Conventionnelle" }/>  <label className='paragraphe'> Conventionnelle </label>
                          <input type="radio" value="Biologique" name="CultureType" className='input_radio' defaultChecked={TypeCulture === "Biologique"}/> <label className='paragraphe'> Biologique </label>
                          {/* <input type="radio" value="Irriguee" name="CultureType" className='input_radio'defaultChecked={TypeCulture === "Irriguee"}/> <label className='paragraphe'> Irriguée </label> */}
                      </div> 
                    ):""}
                    <div className='table_assol'>
                      <div className='row ligne_assol'>
                        <div className='col_assol col_libelle_entete'>
                          <b>Libellé </b>
                        </div>
                        <div className='col_assol col_spe_entete'>
                          <b>Spécification </b>
                        </div>
                        <button className='col_buttonSemis' onClick={handleShowSemis}>{showSemis === true ? "-" : "+"}</button>
                        <div className={showSemis ? 'col_assol col_semis showButtonSemisTrue ' : 'col_assol col_semis showButtonSemisFalse '}>
                          <b>Date de semis </b>
                        </div>
                        <div className='col_assol col_surface'>
                          <b>Surface </b>
                        </div>
                        <div className='col_assol col_rdt'>
                          <b>Rendement </b>
                        </div>
                        <div className='col_assol col_prix'>
                          <b>Prix </b>
                        </div>
                        <div className='col_assol col_PB_unitaire'>
                          <b>Produit brut par ha </b>
                        </div>
                        <div className='col_assol col_PB_tit_Prod'>
                          <b>Produit brut total </b>
                        </div>
                      </div>
                        {Object.keys(data).length === 0 ?"":table_assol}
                        {Object.keys(dataPlusCult).length === 0 ?"": table_assol_spe_sup}
                        <div className='row ligne_assol'>
                        <div className='col_assol col_TotFinal' style={{fontWeight:'bold'}}>
                            Total 
                        </div>
                        <div className='col_assol col_surface' style={{fontWeight:'bold'}}>
                            {Math.round(total_surf(data, dataPlusCult)*100)/100} ha
                        </div>
                        <div className='col_assol col_PB_tot' style={{fontWeight:'bold'}}>
                            {total_PB(data, dataPlusCult).toLocaleString()} €
                        </div>
                        </div>
                    </div>
                    <div className='masquer_en_portrait' style={{marginTop:'20px', width:'100%'}}> 
                    <div className='dispLigne' style={{alignItems:'center', width:'100%'}}>
                      <button 
                        className={ShowDupliquerProd ? 'validation validationCompAssol valCompOrange' : 'validation validationCompAssol valCompVert'} 
                        onClick={ShowDupliquerProd ? handleHideDupliProd : handleShowDupliProd}>
                        Ajouter une autre spécification à une culture existante
                      </button>

                      <div className={ShowDupliquerProd ? 'ShowResult ' : 'HideResult'} style={{width:"65%"}}>
                        <select  style={{height:'50px', margin:'0px', width:'35%', marginLeft:"40px", marginRight:"20px"}} onChange={handlechangeOtherSpe}>
                          <option value="">Sélectionnez la production à dupliquer</option>
                          {culturesDispo(ClickedGC, ClickedViti).map((item)=>( item && <option key={item.key} value={item.key} > {item && item.label} </option> ) ) }
                        </select>
                        <button className='validation' onClick={addOtherSpe} style={{backgroundColor:'#d5d5d5', marginTop:'0px', height:'80%', margin:'0px', width:'25%'}}> Dupliquer la production </button>
                      </div>
                    </div>


                  </div>
                    
                    <div className='row'>
                        <button onClick={handleSubmit} className='validation'> Enregistrer </button> 
                    </div>
                </div>

                
                
                <div className={ShowResult ? 'ShowResult' : 'HideResult'}> 
                <div className='titreMoins'> Assolements </div>
                  <div  ref={ScrollProd} className='dispLigne'>
                    <div className='column dispLeft grapheGaucheCompAssol'>
                        <div className='titrePlus'> Actuel <span style={{fontWeight:'normal', fontSize:'70%'}}> {surfTot1.toFixed(2)} ha </span> </div>
                        <GraphAssolement3 myData={myDataSurf1} PB={PBTot1} print={false}/> 
                    </div>

                    <div className='column dispLeft'>
                        <div className='titrePlus'> Nouvel <span style={{fontWeight:'normal', fontSize:'70%'}}> {total_surf(data, dataPlusCult).toFixed(2)} ha </span> </div> 
                         <GraphAssolement3  myData={myDataSurf2} PB={total_PB(data, dataPlusCult)} print={false}/>
                    </div>
                  </div> 
                  
                  <div className='titreMoins'> Produits Bruts </div>
                  <div className='dispLigne'>
                    <div className='column dispLeft grapheGaucheCompAssol'>
                        <div className='titrePlus'> Actuel <span style={{fontWeight:'normal', fontSize:'70%'}}> {PBTot1.toLocaleString()} € </span> </div>
                        <GraphAssolement3 myData={myDataPB1} PB={""} print={false}/>
                    </div>

                    <div className='column dispLeft'>
                        <div className='titrePlus'> Nouvel <span style={{fontWeight:'normal', fontSize:'70%'}}> {total_PB(data, dataPlusCult).toLocaleString()} € </span> </div>
                        <GraphAssolement3  myData={myDataPB2} PB={""} print={false}/>
                    </div>
                  </div> 
                    <div className='row' style={{ backgroundColor:'#D1FFA1'}}>
                      <div>
                      <div className='titrePlus' style={{marginBottom:'10px'}}>Avec votre nouvel assolement </div> 
                      <ul> 
                        <li style={{color:'black'}}><div className='titrePlus' style={{marginBottom:'10px'}}> 
                          {(PBTot1) < total_PB(data, dataPlusCult)? 
                          ' Le produit brut AUGMENTE de ' + Math.abs(PBTot1-total_PB(data, dataPlusCult)).toLocaleString() + ' €': 
                          (PBTot1) > total_PB(data, dataPlusCult) ? 
                          ' Le produit brut DIMINUE de ' + Math.abs(PBTot1-total_PB(data, dataPlusCult)).toLocaleString() + ' €' : 
                          ' Le produit brut RESTE STABLE '} 
                        </div></li>
                      </ul>
                      <div className='titrePlus' style={{marginBottom:'10px'}}>Le risque courant </div>
                      <ul>
                          <li style={{color:'black'}}><div className='titrePlus' style={{marginBottom:'10px'}}>
                            {(dataIR2_actuel[0]['x'])< (dataIR2_nouveau[0]['x'])? 
                            ' AUGMENTE en valeur de ' + Math.abs(dataIR2_actuel[0]['x']-dataIR2_nouveau[0]['x']).toLocaleString() + ' €': 
                            (dataIR2_actuel[0]['x'])> (dataIR2_nouveau[0]['x']) ? 
                            'DIMINUE en valeur de ' + Math.abs(dataIR2_actuel[0]['x']-dataIR2_nouveau[0]['x']).toLocaleString() + ' €' : 
                            'RESTE STABLE en valeur '} 
                          </div></li>
                          <li style={{color:'black'}}><div className='titrePlus' style={{marginBottom:'20px'}}>
                            {(dataIR2_actuel[0]['x']/PBTot1*100).toFixed(1)< (dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult)*100).toFixed(1)? 
                            'AUGMENTE en % de produit brut de '  + Math.abs((dataIR2_actuel[0]['x']/PBTot1*100).toFixed(1)-(dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult)*100).toFixed(1)).toFixed(1) + ' %': 
                            (dataIR2_actuel[0]['x']/PBTot1*100).toFixed(1) > (dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult)*100).toFixed(1) ? 
                            'DIMINUE en % de produit brut de ' + Math.abs((dataIR2_actuel[0]['x']/PBTot1*100).toFixed(1)-(dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult)*100).toFixed(1)).toFixed(1) + ' %':
                            'RESTE STABLE en % de produit brut '} 
                          </div></li>
                      </ul>
                      
                    </div>
                    </div>
                    <div className='titreMoins' style={{marginTop:'15px'}}> Répartition des risques (courant) pour chaque culture en pourcentage de produit brut</div>

                    <div className='dispLigne'>
                      <div className='column dispLeft grapheGaucheCompAssol'>
                          <div className='titrePlus'> Risque assolement actuel <span style={{fontWeight:'normal', fontSize:'70%'}}> {(dataIR2_actuel[0]['x']/PBTot1*100).toFixed(1)} % de PB </span> </div>
                          <GraphCamemberg myData={shapeDataExtrapole(dataIR2_actuel, dataPourcCult1,PBTot1)} PB={""} print={false}/>
                      </div>

                      <div className='column dispLeft'>
                          <div className='titrePlus'> Risque nouvel assolement <span style={{fontWeight:'normal', fontSize:'70%'}}> {(dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult)*100).toFixed(1)} % de PB </span> </div>
                          <GraphCamemberg  myData={shapeDataExtrapole(dataIR2_nouveau, dataPourcCult2,total_PB(data, dataPlusCult))} PB={""} print={false}/>
                      </div>
                    </div> 

                    {/*<div className='row'>
                      <div className='titrePlus' style={{width:'50%', textAlign:'center', marginTop:'0px',marginBottom:'40px'}}> Risque actuel <span style={{fontSize:'smaller', fontWeight:'normal'}}>({Math.round(dataIR2_actuel[0]['x']/PBTot1 * 10000)/100} % du PB soit {MoyRiskCult(dataTreeMap1).toLocaleString()}€)</span> </div>
                      <div className='titrePlus'style={{width:'50%', textAlign:'center', marginTop:'0px',marginBottom:'40px'}}> Nouveau risque <span style={{fontSize:'smaller', fontWeight:'normal'}}>({Math.round(dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult)*10000)/100} % du PB soit {MoyRiskCult(dataTreeMap2).toLocaleString()}€)</span> </div>
                    </div>
                    
                     <div className='row testBGI' style={{justifyContent:'space-around', alignItems:'flex-start', backgroundImage: (dataIR2_actuel[0]['x']/PBTot1)> (dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult)) ? `url(${balancePG})`: (dataIR2_actuel[0]['x']/PBTot1)< (dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult)) ?`url(${balancePD})`: `url(${balancePP})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: '85%', position: 'relative', width: '100%', height: '600px'}}> 
                      <div style={{marginBottom:"20px" ,marginTop:(dataIR2_actuel[0]['x']/PBTot1)< (dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult)) ? "-60px": (dataIR2_actuel[0]['x']/PBTot1)> (dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult))  ? "20px":"-30px", width: "40%", height:"350px" }}>
                        <TreemapOptiAssol dataTM={dataTreeMap1} /> 
                      </div>
                      <div style={{marginBottom:"20px",marginTop: (dataIR2_actuel[0]['x']/PBTot1)> (dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult))  ? "-60px": (dataIR2_actuel[0]['x']/PBTot1)< (dataIR2_nouveau[0]['x']/total_PB(data, dataPlusCult)) ? "20px":"-30px", width: "40%", height:"350px" }}>
                        <TreemapOptiAssol dataTM={dataTreeMap2} /> 
                      </div>
                    </div> */}

                    <div className='titreMoins'>Impact sur le risque en valeur </div>
                    <div className='dispLigne'>
                        <div className='column dispLeft grapheGaucheCompAssol'>
                            <GrapheIR1 dataIR1={dataIR1} />
                            {dataIR1[0]['x']>0 && dataIR1[1]['x']>0 && dataIR1[2]['x']>0 ?
                            <div>
                              <div className='paragraphe'> Le risque augmente</div>
                            </div> : dataIR1[0]['x']<0 && dataIR1[1]['x']<0 && dataIR1[2]['x']<0 ?
                            <div>
                              <div className='paragraphe'> Le risque diminue</div>
                            </div> :
                            <div className='row' style={{width:'100%'}}>
                              <div className='paragraphe' style={{width:'50%',}}> Le risque diminue</div>
                              <div className='paragraphe' style={{width:'50%', textAlign:'right', }}> Le risque augmente</div>
                            </div>
                            }
                        </div>
                        <div className='column dispLeft paragrapheAlignGauche'>
                            <div className='paragraphe' style={{marginBottom:'10px'}}> Si l'écart est positif, le risque augmente.</div>
                            <div className='paragraphe' style={{marginBottom:'10px'}}> Si l'écart est négatif, le risque diminue. </div>
                            <div className='paragraphe' style={{marginBottom:'10px'}}> Les variations peuvent être différentes selon les périodes de retour, avec, par exemple, les risques courants qui augmentent et les risques catastrophiques qui diminuent, OU inversement.</div>
                            <div className='paragraphe' style={{color:'#DB04B9', fontWeight:'bold'}}>Catastrophique </div>
                            <div className='paragraphe' style={{color:'#0027CC', fontWeight:'bold'}}>Exceptionnel </div>
                            <div className='paragraphe' style={{color:'#FFC000', fontWeight:'bold'}}>Courant </div>
                            <button className={ShowDetails1 ? 'validation validationCompAssol valCompOrange' : 'validation validationCompAssol valCompVert'} onClick={ShowDetails1 ? handleHideD1 : handleShowD1} > {ShowDetails1 ? '- de détails' : '+ de détails'}</button>
                        </div>
                    </div>
                    <div className={ShowDetails1 ? 'ShowResult' : 'HideResult'}>
                        <div>
                          <div className='paragraphe'>En noir les données pour le nouvel assolement</div>
                          <div className='paragraphe'>En vert les données pour l'ancien assolement</div>
                            <GrapheIR2 dataIR2_actuel={dataIR2_actuel} dataIR2_nouveau={dataIR2_nouveau} />
                        </div>
                    </div>

                    {/* <div className='titreMoins'>Impact par culture pour un risque courant </div>
                    <div className='paragraphe'> Différence de risque pour les cultures dont la surface a été modifié dans le cadre d'un risque courant</div>
                    <div className='dispLigne'>
                        <div className='column dispLeft grapheGaucheCompAssol'>
                            <GrapheIC1 dataIC1={removeZero(dataIC1)} />
                        </div>
                        <div className='column dispLeft paragrapheAlignGauche'>
                            <div className='paragraphe' style={{marginBottom:'10px'}}> Si l'écart est positif, le risque augmente.</div>
                            <div className='paragraphe' style={{marginBottom:'10px'}}> Si l'écart est négatif, le risque diminue. </div>
                            <div className='paragraphe' style={{marginBottom:'10px'}}> Les cultures peuvent se compenser soit par la surface soit par le risque que chacune d'entre elles présente.</div>
                            <div className='paragraphe'> {legendeCultures}</div>
                            <button className={ShowDetails2 ? 'validation validationCompAssol valCompOrange' : 'validation validationCompAssol valCompVert'} onClick={ShowDetails2 ? handleHideD2 : handleShowD2} > {ShowDetails2 ? '- de détails' : '+ de détails'}</button>
                        </div>
                    </div>
                    <div className={ShowDetails2 ? 'ShowResult' : 'HideResult'}>

                    <div >
                      <div className='paragraphe'>En bleu les données pour le nouvel assolement</div>
                      <div className='paragraphe'>En gris les données pour l'ancien assolement</div>
                        <GrapheIC2 dataIC2_actuel={dataIC2_actuel} dataIC2_nouveau={dataIC2_nouveau} />
                    </div>
                    </div> */}
                    <div className='row'>
                        <button className='validation' onClick={handleNewSimul} style={{backgroundColor:'#d5d5d5'}}> Enregistrer cet assolement en tant que nouvelle simulation </button>
                    </div> 
                </div>
            </div>
            ) )
            : (
              <div className='container'>
                <div className='row'>
                  <div style={{width:'50%'}}>
                    <div className='titrePlus'> Page réservée aux formules Premium et Essentielle </div>
                    <div className='paragraphe' > Pour consulter cette page et pleins d'autres éléments, souscrivez ! </div>
                    <div className='row' >
                        <button className='validation' onClick={handleGoToTarifs} > Voir les offres  </button>
                    </div> 
                  </div>
                  <div style={{width:'45%'}}>
                  <img src={img} alt="capture d'écran exemple de la page Optimisation d'assolement" style={{width:'100%'}}/>
                  </div>
                </div>
              </div>
            )}
            <ButtonQuestions/>
            <OADMenu idExploit={idExploit}/>
        </div>
    )
}

export default OptimisationAssolement