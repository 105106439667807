import React, {useEffect} from 'react'
import apiLink from '../../../config.js'
import axios from "axios";
import Entete from '../../MEP/entete'
import {Navigate} from 'react-router-dom'

import ButtonQuestions from '../../MEP/button question.js';
import PopUpMDP from './popup_mdp'
import PopUpSupp from './popup_supp'

function Profil() {
  const [data, setData]= React.useState({'nom': '', 'prenom': '', 'societe': '', 'telephone': '', "libelleCommune":"", "libelleRole":""});
  const mesDonnees = new FormData(); 
  const idAgent = JSON.parse(localStorage.getItem('idAgent'));
  const formule = JSON.parse(localStorage.getItem('formule'));

  const [BpopupMDP, setBpopupMDP]= React.useState(false)
  const [Save, setSave]= React.useState(false)
  const [BpopupSupp, setBpopupSupp]= React.useState(false)
  const [goToHome, setGoToHome] = React.useState(false)

 useEffect(() => {
  fetch(apiLink + 'getUtilisateur/' + idAgent)
  .then((response) => {
    if (response.ok) { // test si le compte a été réinitialisé
      return response.json();
    } else {
      localStorage.clear()
      setGoToHome(true)
    }
  })
  .then(response =>{
    setData(response)
    setData(prev => {
        return prev;
    })
  })
    
  }, [idAgent]);


  
  function handleSubmit(event) {
    event.preventDefault()
    var nom = data['nom']
    var prenom = data['prenom']
    var telephone = data['telephone']
    var societe= data['societe']
    mesDonnees.set("nom", nom)
    mesDonnees.set("prenom", prenom)
    mesDonnees.set("telephone", telephone)
    mesDonnees.set("societe", societe)
    

    axios({
        method: 'post',
        url: apiLink + 'updateUtilisateur/' + idAgent, //changer
        data: mesDonnees
    })
    .then(function (reponse) {
      if(reponse.status === 200){
        setSave(true) //reprendre ici pour mettre un texte vert qui apparait sous le bouton pour dire que les infos sont enregistrées
      }
      else {
        alert("Une erreur c'est produite, vos modifications n'ont pas pu être enregistrées")
      }
    })
    .catch(function (erreur) {
        //On traite ici les erreurs éventuellement survenues
        console.log(erreur);
    });
  }


  function handleChangenom(event) {
    setSave(false)
    var newData = {...data};
    var nom = event.currentTarget.value;
    newData['nom'] = nom//execute the manipulations
    setData(newData)
    setData(prev => {
      return prev;
    })    
  }
  function handleChangeprenom(event) {
    setSave(false)
    var newData = {...data};
    var prenom = event.currentTarget.value;
    newData['prenom'] = prenom//execute the manipulations
    setData(newData)
    setData(prev => {
      return prev;
    })  
  }
  function handleChangesociete(event) {
    setSave(false)
    var newData = {...data};
    var societe = event.currentTarget.value;
    newData['societe'] = societe//execute the manipulations
    setData(newData)
    setData(prev => {
      return prev;
    })  
  }
  function handleChangetel(event) {
    setSave(false)
    var newData = {...data};
    var tel = event.currentTarget.value;
    newData['telephone'] = tel//execute the manipulations
    setData(newData)
    setData(prev => {
      return prev;
    })    
  }
  
  function handleDeconnexion() {
    localStorage.clear()
    setGoToHome(true)
  }
  if(goToHome) {
    return <Navigate push to={`/`} />
  }
  return (
    <div>
      <Entete titre="Profil"/>
      <div className='container'> 
        <div className='titreMoins'>Offre : <span style={{fontWeight:'normal'}}> {data['libelleRole']==="Administrateur" ? (data['libelleRole'] + " -") : "" } {formule} </span></div>
        <div className='principal principalBis'>
          <div className='bottom bottom2' style={{marginTop:'0px'}}>
            <form onSubmit={handleSubmit} name="inscription" style={{width:'100%'}}>
              <div className='titreMoins'> Nom : <span style={{color:'red'}}>*</span> </div>
              <input 
              className='input'
              value={data['nom']}
              onChange={handleChangenom}
              placeholder='Nom'
              type='text'
              style={{marginBottom:'10px', }}
              required/>
              <div className='titreMoins'> Prénom :  <span style={{color:'red'}}>*</span> </div>
              <input 
              className='input'
              value={data['prenom']}
              onChange={handleChangeprenom}
              placeholder='Prénom'
              type='text'
              style={{marginBottom:'10px', }}
              required/>
              <div className='titreMoins'> Société : </div>
              <input 
              className='input'
              value={data['societe']}
              onChange={handleChangesociete}
              placeholder='Société'
              type='tel'
              style={{marginBottom:'10px', }}/>
              <div className='titreMoins'> Téléphone : <span style={{color:'red'}}>*</span> </div> 
              <input 
              className='input'
              value={data['telephone']}
              onChange={handleChangetel}
              placeholder='Téléphone'
              type='tel'
              pattern="[0]{1}[6-7]{1}[0-9]{8}"
              style={{marginBottom:'10px', }}
              required/>
              <div className='textInscription' style={{color:'black'}}>Format : 06XXXXXXXX ou 07XXXXXXXX</div>
              <div className='titreMoins'> Localisation :  </div>
              <input 
              className='input'
              value={data['libelleCommune']}
              type='text'
              style={{marginBottom:'10px'}}
              disabled/>
              <div className='row'>
                <button type='submit' className='validation'> Enregistrer </button>
              </div>
              <div className='textInscription' style={{color:'green'}}>{Save ? 'Modifications enregistrées' : ''}</div>
            </form>
          </div>
          <div className='bottom bottom2' style={{marginTop:'0px', justifyContent:'flex-start'}}>
            <div className='titreMoins' > Mot de passe :  </div>
            <input 
            className='input'
            value="abcdefghij"
            placeholder='Mot de passe'
            type='password'
            style={{marginBottom:'10px'}}
            disabled/>
            <div className='row' style={{width:'100%'}}>
              <button className='validation'  onClick={() => setBpopupMDP(true)}> Modifier le mot de passe </button>
            </div>

            <div className='titreMoins' > Mon compte  :  </div>
            
            <div className='row' style={{width:'100%'}}>
              <button className='validation' onClick={handleDeconnexion} style={{backgroundColor:'#E5E5E5'}}> Déconnexion </button>
              <button className='validation' style={{backgroundColor:'#FF757F'}}  onClick={() => setBpopupSupp(true)}> Supprimer mon compte </button>
            </div>
          </div>
        </div>
        <PopUpMDP trigger={BpopupMDP} setTrigger={setBpopupMDP}/>
        <PopUpSupp trigger={BpopupSupp} setTrigger={setBpopupSupp}/>
      </div>
      <ButtonQuestions/>
    </div>
  )
}

export default Profil