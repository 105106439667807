import React, {useEffect} from 'react'
import TitreRapport from './titre_rapport'
import apiLink from '../../config.js'
import GraphSolEsquiveViti from '../OAD/Graphiques/graphSolEsquiveViti.js'
import loading_Data from '../../assets/loading-Data.gif'

function PageSolCepage({idExploit}) {
  const [ExploitMixte, setExploitMixte] = React.useState(false)
  const [ExploitViti, setExploitViti] = React.useState(false)
  const [ExploitGC,setExploitGC] = React.useState(true)
  const [dataCCep, setdataCCep]= React.useState()
    const [dataCCepLib, setdataCCepLib]= React.useState()
  function fetchData() {
    fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
    let data = response
    for (var cle in data) {
        if (cle.includes("Vignes")) {
            setExploitMixte(true)
            setExploitGC(false)
            setExploitViti(false);
            break;
        }
    }
    let allKeysContainVignes = Object.keys(data).every(cle => cle.includes("Vignes"));
    if (allKeysContainVignes) {
        setExploitViti(true);
        setExploitMixte(false)
        setExploitGC(false)
    } else {
        setExploitViti(false);
    }
    })
    fetch('https://api2050.diagorisk.com/pageSolutionRemplacerCepage/?idExploitation=' + idExploit)
          .then((response) => response.json())
          .then((response) => {
            setdataCCep(response.dataCCep)
            setdataCCep(prev => {
                return prev;
            })
            setdataCCepLib(response.dataCCepLib)
            setdataCCepLib(prev => {
                return prev;
            })
        })
}

  useEffect ( () => {
      fetchData()
    }, [idExploit])

  return (
    <div className='containerPrint'>
      <TitreRapport Titre={"Changement d'un ou plusieurs cépages"} Page={ExploitViti ? '6' : '7'}/>
      <div className='paragraphe'>Tous les cépages sont présents ici afin de proposer un large panel des cépages que nous modélisons. Cependant, une restriction peut être réalisée afin de n'avoir que les cépages qui vous concernent. </div>
      {dataCCep !== undefined && dataCCepLib !== undefined ?
          <GraphSolEsquiveViti data={dataCCep} libelleData={dataCCepLib} print={true}/> 
            : 
            <div className='row' style={{marginTop:'100px'}}>
              <img src={loading_Data} alt="image de chargement" className=""/>
          </div> 
      }

     {/*  <div className='commentaireRapport' style={{marginTop:'50px'}}>
        <div style={{fontWeight:'bold', marginBottom:'5px'}}>Commentaire : </div>
        <div className='paragraphe'>ff</div>
      </div> */}
      
    </div>
  )
}

export default PageSolCepage