import React, {useEffect} from 'react'
import apiLink from '../../config.js'
import TitreRapport from './titre_rapport.js'
import AssolRapport from './assolementRapport.js';
import GraphAssolement3 from '../OAD/Graphiques/assolement_graph_3.js';

function PageProduction({idExploit}) {
  const [myDataSurf, setDataSurf]= React.useState([]);
  const [myDataPb, setDataPb]= React.useState([]);
  const [surfTot, setsurfTot]= React.useState(0);
  const [PBTot, setPBTot]= React.useState(0);
  const [data,setData] = React.useState([]);

  useEffect ( () => {
    fetch(apiLink + 'page/exploitation/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setDataSurf(response.dataSurface)
      setDataSurf(prev => {
        return prev;
      })
      setDataPb(response.dataPB)
      setDataPb(prev => {
        return prev;
      })
      setsurfTot(response.surfaceTotale)
      setsurfTot(prev => {
        return prev;
      })
      setPBTot(response.PBTotal)
      setPBTot(prev => {
        return prev;
      })
    }) 
    fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setData(response)
      setData(prev => {
        return prev;
      })
    }) 
  }, [idExploit])

  const tableau_assol=  Object.keys(data).map(key => (
    <AssolRapport
    libelle={key}
    surface={data[key][0]}
    rendement={data[key][1]}
    prix={data[key][2]}
    pbCulture={data[key][3]}
    />
  ))

  return (
    <div className='containerPrint' >
      <TitreRapport Titre={'Production'} Page={'1'}/>
      <div className='row ligne_assol'>
        <div className='col_assol_rapport col_libelle_rapport' style={{fontWeight:'bold'}}>
          Libellé
        </div>
        <div className='col_assol_rapport col_surface_rapport' style={{fontWeight:'bold'}}>
          Surface
        </div>
        <div className='col_assol_rapport col_rdt_rapport' style={{fontWeight:'bold'}}>
          Rendement
        </div>
        <div className='col_assol_rapport col_prix_rapport' style={{fontWeight:'bold'}}>
          Prix
        </div>   
        <div className='col_assol_rapport col_PB_unitaire_rapport' style={{fontWeight:'bold'}}>
          Produit brut unitaire
        </div>     
      </div>
      {tableau_assol}
      
      <div className='dispLigne dispLigneRapport'>
        <div className='column dispLeft'>
          <div className='titrePlus'> Surface <span style={{fontWeight:'normal', fontSize:'70%'}}> ({Math.round(surfTot*100)/100} hectares)</span> </div>
          <GraphAssolement3 myData={myDataSurf} PB={""} print={true}/>
        </div>

        <div className='column dispRight dispRightRapport'>
          <div className='titrePlus'> Produit brut <span style={{fontWeight:'normal', fontSize:'70%'}}> ({PBTot.toLocaleString()} euros) </span> </div>
          <GraphAssolement3 myData={myDataPb} PB={""} print={true}/>
        </div>
      </div> 
    </div>
  )
}

export default PageProduction