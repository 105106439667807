import { type } from '@testing-library/user-event/dist/type';
import React from 'react'
import Select from 'react-select';

const CultureAjoutCepage = ({keys,data, MAJ_surf_rdt_prix_spe, SuppressionSpe, libelle, typeCulture, showSemis}) => {
    const dataSpe = data[keys]['Specification']
    
    function handleChangeSurface(event) {
        var surf = event.currentTarget.value;
        MAJ_surf_rdt_prix_spe(keys, "surface", surf);
    }
    function handleChangeSemis(event) {
        var semis = event.currentTarget.value;
        MAJ_surf_rdt_prix_spe(keys, "Semis", semis);
    }
    function handleChangeRdt(event) {
        var rdt = event.currentTarget.value;
        MAJ_surf_rdt_prix_spe(keys, "rendement", rdt);
    }

    function handleChangePrix(event) {
        var prix = event.currentTarget.value;
        MAJ_surf_rdt_prix_spe(keys, "prix", prix);
    }

    function handleChangeSpe(event){
        var spe = event.value
        var idTC_rdt 
        var idTC_prix
        var idTCNone_rdt
        var idTCNone_prix
        if(typeCulture==="Conventionnelle") {
            idTC_rdt = 'rendement'
            idTC_prix = 'prix'
            idTCNone_rdt = 'rendementConv'
            idTCNone_prix = "prixConv"
        }
        else {
            idTC_rdt = 'rendementBio'
            idTC_prix = 'prixBio'
            idTCNone_rdt = 'rendementBio'
            idTCNone_prix = 'prixBio'
        }
        if (spe !== "none"){
            MAJ_surf_rdt_prix_spe(keys, "rendement", data[keys]['Specification'][spe][idTC_rdt]);
            MAJ_surf_rdt_prix_spe(keys, "prix",data[keys]['Specification'][spe][idTC_prix]);
            MAJ_surf_rdt_prix_spe(keys, "SelectedSpe", data[keys]['Specification'][spe]['key']);
        }
        else {
            MAJ_surf_rdt_prix_spe(keys, "rendement", data[keys][idTCNone_rdt]);
            MAJ_surf_rdt_prix_spe(keys, "prix", data[keys][idTCNone_prix]);
            MAJ_surf_rdt_prix_spe(keys, "SelectedSpe", "none");
        }
        
    }

    const options = Object.keys(dataSpe).map((item)=>(
        {"value": dataSpe[item].key, "label":dataSpe[item].libelle}
    ))

    const optionsNull = {"value":"none", "label":""}
    options.unshift(optionsNull)

    const DateDeSemis = () => {
        var semis = []
        if (data[keys]['key']=== "111"){
            if (data[keys]["SelectedSpe"] === "1112"){
                semis = [{'value': "15.02", "libelle": "15 février"}, {'value': "20.02", "libelle": "20 février"}, {'value': "25.02", "libelle": "25 février"},{'value': "05.03", "libelle": "5 mars"}, {'value': "10.03", "libelle": "10 mars"},{'value': "15.03", "libelle": "15 mars"} ]
                
            }
            else if (data[keys]["SelectedSpe"] === "1113"){
                semis = [{'value': "25.10", "libelle": "25 octobre"}, {'value': "30.10", "libelle": "30 octobre"},{'value': "05.11", "libelle": "5 novembre"}, {'value': "10.11", "libelle": "10 novembre"}, {'value': "15.11", "libelle": "15 novembre"}, {'value': "20.11", "libelle": "20 novembre"},  {'value': "25.11", "libelle": "25 novembre"}]
                
            }
            else {
                semis = [{'value': "15.02", "libelle": "15 février"}, {'value': "20.02", "libelle": "20 février"}, {'value': "25.02", "libelle": "25 février"},{'value': "05.03", "libelle": "5 mars"}, {'value': "10.03", "libelle": "10 mars"},{'value': "15.03", "libelle": "15 mars"},{'value': "25.10", "libelle": "25 octobre"}, {'value': "30.10", "libelle": "30 octobre"},{'value': "05.11", "libelle": "5 novembre"}, {'value': "10.11", "libelle": "10 novembre"}, {'value': "15.11", "libelle": "15 novembre"}, {'value': "20.11", "libelle": "20 novembre"},  {'value': "25.11", "libelle": "25 novembre"} ]
                
            }
        }
        else if (data[keys]['key']=== "112"){
            if (data[keys]["SelectedSpe"] === "1122"){
                semis = [{'value': "15.02", "libelle": "15 février"}, {'value': "20.02", "libelle": "20 février"}, {'value': "25.02", "libelle": "25 février"},{'value': "05.03", "libelle": "5 mars"}, {'value': "10.03", "libelle": "10 mars"},{'value': "15.03", "libelle": "15 mars"} ]
                
            }
            else if (data[keys]["SelectedSpe"] === "1123"){
                semis = [{'value': "25.10", "libelle": "25 octobre"}, {'value': "30.10", "libelle": "30 octobre"},{'value': "05.11", "libelle": "5 novembre"}, {'value': "10.11", "libelle": "10 novembre"}, {'value': "15.11", "libelle": "15 novembre"}, {'value': "20.11", "libelle": "20 novembre"},  {'value': "25.11", "libelle": "25 novembre"}]
                
            }
            else {
                semis = [{'value': "15.02", "libelle": "15 février"}, {'value': "20.02", "libelle": "20 février"}, {'value': "25.02", "libelle": "25 février"},{'value': "05.03", "libelle": "5 mars"}, {'value': "10.03", "libelle": "10 mars"},{'value': "15.03", "libelle": "15 mars"},{'value': "25.10", "libelle": "25 octobre"}, {'value': "30.10", "libelle": "30 octobre"},{'value': "05.11", "libelle": "5 novembre"}, {'value': "10.11", "libelle": "10 novembre"}, {'value': "15.11", "libelle": "15 novembre"}, {'value': "20.11", "libelle": "20 novembre"},  {'value': "25.11", "libelle": "25 novembre"} ]
                
            }
        }
        else if (data[keys]['key']=== "114"){
            semis = [{'value': "15.02", "libelle": "15 février"}, {'value': "20.02", "libelle": "20 février"}, {'value': "25.02", "libelle": "25 février"},{'value': "05.03", "libelle": "5 mars"}, {'value': "10.03", "libelle": "10 mars"},{'value': "15.03", "libelle": "15 mars"} ]
            
        }
        else if (data[keys]['key']=== "115"){
            semis = [{'value': "25.10", "libelle": "25 octobre"}, {'value': "30.10", "libelle": "30 octobre"},{'value': "05.11", "libelle": "5 novembre"}, {'value': "10.11", "libelle": "10 novembre"}, {'value': "15.11", "libelle": "15 novembre"}, {'value': "20.11", "libelle": "20 novembre"},  {'value': "25.11", "libelle": "25 novembre"}]
            
        }
        else if (data[keys]['key']=== "118"){
            semis = [{'value': "15.03", "libelle": "15 mars"}, {'value': "20.03", "libelle": "20 mars"}, {'value': "25.03", "libelle": "25 mars"}, {'value': "30.03", "libelle": "30 mars"}, {'value': "05.04", "libelle": "5 avril"}, {'value': "10.04", "libelle": "10 avril"}, {'value': "15.04", "libelle": "15 avril"}, {'value': "20.04", "libelle": "20 avril"}, {'value': "25.04", "libelle": "25 avril"}, {'value': "30.04", "libelle": "30 avril"}, {'value': "05.05", "libelle": "5 mai"}, {'value': "10.05", "libelle": "10 mai"}, {'value': "15.05", "libelle": "15 mai"}, {'value': "20.05", "libelle": "20 mai"}, {'value': "25.05", "libelle": "25 mai"}, {'value': "30.05", "libelle": "30 mai"}]
            
        }
        else if (data[keys]['key']=== "222"){
            semis = [{'value': "15/08", "libelle": "15 août"}, {'value': "20/08", "libelle": "20 août"}, {'value': "25/08", "libelle": "25 août"},{'value': "30/08", "libelle": "30 août"}, {'value': "05/09", "libelle": "05 septembre"}]
            
        }
        else {
            semis=[]
        }
        return semis
      }

    const styles = {
        option: (provided, state) => ({
          ...provided,
          fontWeight: state.isSelected ? "bold" : "normal",
          fontSize: "16px",
          width:'95%',
          minHeight: '30px',
          height: '30px',
        }),
        container:(provided) => ({
            ...provided,
          width:'95%',
          minHeight: '30px',
          height: '30px',
        }),
        control:(provided) => ({
            ...provided,
          width:'95%',
          minHeight: '35px',
          height: '35px',
          border: '1px solid grey',
          boxShadow: 'none',
          '&:hover': {
              border: '1px solid #adadad',
          }
        }),
        indicators:(provided) => ({
            ...provided,
          width:'95%',
        }),
        valueContainer: (provided) => ({
          ...provided,
          marginTop:'-25px',
          minHeight: '50px',
          height: '50px',
          color:'black'
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          minHeight: '35px',
          height: '35px',
        }),
        placeholder:(provided) => ({
            ...provided,
            height:"20px",
            color: "black",
        }),
      };
    function suppspe(){
        SuppressionSpe(keys)
    }
    

    return (
        <div className='row ligne_assol'>
           
            <div className='col_assol col_libelle_cult_plus'>
            {libelle}
            </div>
            <div className='col_assol col_suppr_cult_plus'>
            <button style={{backgroundColor:'rgb(254, 91, 91)', margin:'0px', borderWidth:'0.5px', height:'30px', width:"75%", color:'black'}} onClick={suppspe}> X </button>
            </div>
            <div className='col_assol col_spe'>
                <Select 
                placeholder=""
                options={options} 
                value={options.find(option => option.value === data[keys]['SelectedSpe'])}
                onChange={handleChangeSpe}  
                styles={styles}/>
                {/* <select onChange={handleChangeSpe} className='select_assol'>
                    <option key='standard' value="standard">Sélectionnez </option>
                    {Object.keys(dataSpe).map((item)=>( <option key={dataSpe[item].key} value={dataSpe[item].key} > {dataSpe[item].libelle} </option> ) )}
                </select> */}
            </div>
            <div className={showSemis ? 'col_assol col_semis showButtonSemisTrue ' : 'col_assol col_semis showButtonSemisFalse '}>
                <select 
                className='input_assol input_assol_semis dateSemisUneCulture'
                value={data[keys]["Semis"]} 
                onChange={handleChangeSemis}  
                style={{width:'100%'}} 
                disabled={(data[keys]['key']=== "630" || data[keys]['key']=== "670" || data[keys]['key']=== "675" || (data[keys]['key']!== "111" && data[keys]['key']!== "112" && data[keys]['key']!== "114" && data[keys]['key']!== "115" && data[keys]['key']!== "118" && data[keys]['key']!== "222"))} >
                    <option value=""></option>
                    {DateDeSemis().map((item)=>( <option key={item.value} value={item.value} > {item.libelle} </option> ) )}
                </select>
            </div>
            <div className='col_assol col_surface'>  
                <div className='videBlocAssol'> 
                    <input 
                    className='input_assol'
                    onChange={handleChangeSurface}
                    value= {data[keys]["surface"]}  
                    placeholder='0'
                    type='number'
                    step='0.01'
                    disabled={data[keys]["SelectedSpe"] === "none"}/>
                    <div className='pleinBlocAssol' style={{backgroundColor: data[keys]["surface"] !==0 ? 'rgb(179, 231, 148)' : '#C5C5CC', color: data[keys]["surface"] !==0 ? '#117900' : 'black' }}>
                        ha
                    </div>
                </div>
            </div>
            <div className='col_assol col_rdt'>
            <div className='videBlocAssol'>
            <input 
                className='input_assol'
                onChange={handleChangeRdt}
                value= {data[keys]["rendement"]}
                placeholder='0'
                type='number'
                step='0.01'
                disabled={data[keys]["SelectedSpe"] === "none" }/>
                <div className='pleinBlocAssol'>
                hl/ha
                </div>
            </div>
            </div>
            <div className='col_assol col_prix'>
            <div className='videBlocAssol'>
                <input 
                className='input_assol'
                onChange={handleChangePrix}
                value= {data[keys]["prix"]}
                placeholder='0'
                type='number'
                step='0.01'
                disabled={data[keys]["SelectedSpe"] === "none" }/>
                <div className='pleinBlocAssol'>
                €/hl
                </div>
            </div>
            </div>
            <div className='col_assol col_PB_unitaire'>
                {Math.round(data[keys]["rendement"]*data[keys]["prix"]).toLocaleString()} €
            </div>
            <div className='col_assol col_PB_tit_Prod'>
                {Math.round(data[keys]["surface"]*data[keys]["rendement"]*data[keys]["prix"]).toLocaleString()} €
            </div>
            
        </div>
    )
}
export default CultureAjoutCepage;