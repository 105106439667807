import React, { useState} from 'react';
import {Navigate} from 'react-router-dom'
import Nav from '../MEP/SWNav';
import FooterSW from '../MEP/footer_SW';

function DecouvDR() {
  const [goToContact, setGoToContact] = useState(false) 
  function handleContacter(event){
    event.preventDefault()
    setGoToContact(true)
  }
  if(goToContact) {
    return <Navigate push to={`/nouscontacter`} />
  }
  return (
    <div>
      <Nav/>
      <div className="containerSW">
        <h1 className="propositionValeur titreSW">Découvrir DiagoRisk</h1>
        {/*  <div className='principal' style={{alignItems:'center'}}>
          <video className='video_prez_DR' controls controlsList="nodownload">
            <source src={videoFile} type="video/mp4" />
            Votre navigateur ne supporte pas la lecture des vidéos.
          </video>
          <video className='video_prez_DR' controls controlsList="nodownload">
            <source src={videoFile2} type="video/mp4" />
            Votre navigateur ne supporte pas la lecture des vidéos.
          </video>
        </div> */}
        <div className='principal' style={{alignItems:'center', marginTop:'10px'}}><iframe
            src="https://www.youtube.com/embed/mwkac7NX5II"
            className='video_prez_DR'
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe></div>
        <div className='paragraphe paragrapheLanding' style={{marginTop:'15px', marginBottom:'15px', width:'100%', textAlign:'center', fontSize:"25px"}}>Pour une démonstration personnalisé ou échanger avec nous sur nos outils  </div>
        <h2 style={{marginTop:'5px'}}> <button className='ButtonHome sous-Titre-SW' onClick={handleContacter}>Contactez-nous ! </button> </h2>
      </div>
      <FooterSW/>
    </div>
  );
}

export default DecouvDR;