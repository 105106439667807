import React from "react";
import ReactEcharts from "echarts-for-react"; 
function GraphAleasNBjours2({myDataMoy, myDataMax, myDataMin, aleas, dataType, print}) { 
    const myDataMaxDif = [0, myDataMax[1]-myDataMax[0], myDataMax[2]-myDataMax[0], myDataMax[3]-myDataMax[0], myDataMax[4]-myDataMax[0], myDataMax[5]-myDataMax[0], myDataMax[6]-myDataMax[0]]
    const myDataMinDif = [0, myDataMin[1]-myDataMin[0], myDataMin[2]-myDataMin[0], myDataMin[3]-myDataMin[0], myDataMin[4]-myDataMin[0], myDataMin[5]-myDataMin[0], myDataMin[6]-myDataMin[0]]
     const myDataMoyDif = [0,0,0,0,0,0] /*[0, myDataMoy[1]-myDataMoy[0], myDataMoy[2]-myDataMoy[0], myDataMoy[3]-myDataMoy[0], myDataMoy[4]-myDataMoy[0], myDataMoy[5]-myDataMoy[0], myDataMoy[6]-myDataMoy[0]] */
    
    const getOptions = () => {
      let options = {
        grid: {
          top: '15%',
          right:'0%',
        },
        legend: {
          data: [ "Maximum", "Moyen", "Minimum"],
          textStyle: {
            fontSize: 16, // Ajustez la taille de la police de la légende selon vos besoins
          },
      },
        xAxis: {
            type: 'category',
            data: ['2020','2025','2030','2035', '2040', '2045', '2050'],
            name: 'Risques',
            nameLocation: 'middle', // Place le nom de l'axe au centre sous l'axe
            nameGap: 30,
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16, // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
              
            }
          },
          yAxis: {
            type: 'value',
            name: "Nbr de jour",
            nameTextStyle: {
              fontSize: 16 // Ajustez la taille de la police selon vos besoins
            },
            axisLabel: {
              fontSize: 16 // Ajustez la taille de la police des valeurs de l'axe Y selon vos besoins
            }
          },
          series: [     
           {
              name: "Maximum",
              data: dataType === 'Diff' ?myDataMaxDif: myDataMax,
              type: 'line',
              smooth: true,
              itemStyle: {
                color: 'red' // aleas === 'SH' ? '#00C4BF' : aleas==='EE' ?  '#001570' : aleas==='CDC' ?  '#b97400' : '#008f4e' 
              },
              lineStyle: {
                width: 3, // Ajustez la largeur de la ligne selon vos besoins
              },
              symbolSize: 10,
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return param.data.toFixed(2)
                  },
                  position:'top',
                  textStyle: {
                    fontSize: 16 // Ajustez la taille de police selon vos préférences
                  },
                },
              },
            },
            {
              name: "Moyen",
              data: dataType === 'Diff' ?myDataMoyDif: myDataMoy,
              type: 'line',
              smooth: true,
              itemStyle: {
                color: '#dfe300' // aleas === 'SH' ? '#00F1F1' : aleas==='EE' ?  '#002FF1' : aleas==='CDC' ?  '#F19600' : '#00E57D' 
              },
              lineStyle: {
                width: 3, // Ajustez la largeur de la ligne selon vos besoins
              },
              symbolSize: 10,
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return param.data.toFixed(2)
                  },
                  position:'top',
                  textStyle: {
                    fontSize: 16 // Ajustez la taille de police selon vos préférences
                  },
                },
              },
            },
            {
              name: "Minimum",
              data: dataType === 'Diff' ? myDataMinDif : myDataMin,
              type: 'line',
              smooth: true,
              itemStyle: {
                color: 'green' //aleas === 'SH' ? '#93FFFF' : aleas==='EE' ?  '#6986FF' : aleas==='CDC' ?  '#ffc25c' : '#6effbd' 
              },
              lineStyle: {
                width: 3, // Ajustez la largeur de la ligne selon vos besoins
              },
              symbolSize: 10,
              emphasis: {
                focus: 'series',
                label: {
                  show: true,
                  formatter: function (param) {
                    return param.data.toFixed(2)
                  },
                  position:'top',
                  textStyle: {
                    fontSize: 16 // Ajustez la taille de police selon vos préférences
                  },
                },
              },
            },
        ]
      };
  
      return options;
    };
  

    return (
      <div style={{marginBottom:print === true ? "10px" : "0px", width: print === true ? '60%' : '95%',  marginTop:print === true ? '10px' : '0px'}}>
        <ReactEcharts option={getOptions()} style={{ width:'100%', height: print === true ? '550px' :  '400px'  }} />
    </div>
  
  );
}

export default GraphAleasNBjours2;
  