import React, {useState, useEffect} from 'react'
import apiLink from '../../../config'

import Entete from '../../MEP/entete'
import GrapheColzaZI from '../Graphiques/GrapheColzaZI'
import GrapheRepartitionRiskColzaZI from '../Graphiques/GrapheRépartitionRiskColzaZI'
import loading_Data from '../../../assets/loading-Data.gif'

function ColzaZI() {
    const [CP, setCP]= useState('36150');
    const [ListVille, setList]= useState([{"key":"36002","libelle":"Aize"},{"key":"36029","libelle":"Buxeuil"},{"key":"36041","libelle":"La Chapelle-Saint-Laurian"},{"key":"36075","libelle":"Fontenay"},{"key":"36083","libelle":"Giroux"},{"key":"36085","libelle":"Guilly"},{"key":"36097","libelle":"Liniez"},{"key":"36102","libelle":"Luçay-le-Libre"},{"key":"36116","libelle":"Ménétréols-sous-Vatan"},{"key":"36122","libelle":"Meunet-sur-Vatan"},{"key":"36170","libelle":"Reboursin"},{"key":"36191","libelle":"Saint-Florentin"},{"key":"36230","libelle":"Vatan"}])
    const [PBCp, setPBCp]= useState(false);
    const [city, setCity]= useState("36230");
    const [ShowDetails, setShowDetail]= useState(false);

    const [dataConv, setDataConv] = useState()
    const [dataRobuste, setDataRobuste] = useState()
    const [dataZI, setDataZI] = useState()
    const [dataZIetRob, setDataZIetRob] = useState()


    const [ValRDT, setValRDT] = useState()
    const [franchise, setFranchise] = useState()
    const [dataRepartConv, setDataRepartConv] = useState()
    const [dataRepartRobuste, setDataRepartRobuste] = useState()
    const [dataRepartZI, setDataRepartZI] = useState()
    const [dataRepartZIetRob, setDataRepartZIetRob] = useState()


    useEffect ( () => {
        fetch('https://api2050.diagorisk.com/colzaZI/?idCommune=36230')
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
            setDataConv(response.dataConv2020)
            setDataConv(prev => {
              return prev;
            })
            setDataRobuste(response.dataRob2020)
            setDataRobuste(prev => {
              return prev;
            })
            setDataZI(response.dataZI2020)
            setDataZI(prev => {
              return prev;
            })
            setDataZIetRob(response.dataRobZI2020)
            setDataZIetRob(prev => {
              return prev;
            })
            setDataRepartConv(response.dataRepartConv2020)
            setDataRepartConv(prev => {
              return prev;
            })
            setDataRepartRobuste(response.dataRepartRob2020)
            setDataRepartRobuste(prev => {
              return prev;
            })
            setDataRepartZI(response.dataRepartZI2020)
            setDataRepartZI(prev => {
              return prev;
            })
            setDataRepartZIetRob(response.dataRepartRobZI2020)
            setDataRepartZIetRob(prev => {
              return prev;
            })
            setValRDT(response.ValRDT)
            setValRDT(prev => {
              return prev;
            })
            setFranchise(response.franchise)
            setFranchise(prev => {
              return prev;
            })
        })
      }, [])

    function handleSubmit(){
        setDataConv()
        setDataRobuste()
        setDataZI()
        setDataZIetRob()
        setDataRepartConv()
        setDataRepartRobuste()
        setDataRepartZI()
        setDataRepartZIetRob()
        setValRDT()
        setFranchise()

        fetch('https://api2050.diagorisk.com/colzaZI/?idCommune=' + city)
        .then( (response) => {
          return response.json()
        })
        .then(response =>{
            setDataConv(response.dataConv2020)
            setDataConv(prev => {
              return prev;
            })
            setDataRobuste(response.dataRob2020)
            setDataRobuste(prev => {
              return prev;
            })
            setDataZI(response.dataZI2020)
            setDataZI(prev => {
              return prev;
            })
            setDataZIetRob(response.dataRobZI2020)
            setDataZIetRob(prev => {
              return prev;
            })
            setDataRepartConv(response.dataRepartConv2020)
            setDataRepartConv(prev => {
              return prev;
            })
            setDataRepartRobuste(response.dataRepartRob2020)
            setDataRepartRobuste(prev => {
              return prev;
            })
            setDataRepartZI(response.dataRepartZI2020)
            setDataRepartZI(prev => {
              return prev;
            })
            setDataRepartZIetRob(response.dataRepartRobZI2020)
            setDataRepartZIetRob(prev => {
              return prev;
            })
            setValRDT(response.ValRDT)
            setValRDT(prev => {
              return prev;
            })
            setFranchise(response.franchise)
            setFranchise(prev => {
              return prev;
            })
        })
    }
    function handleChange(event){
        var CP = event.target.value
        setCP(CP)   
        fetch(apiLink + 'getCommunes/'+ CP)
        .then( (response) => {
            return response.json()
        })
        .then(response =>{
            setList(response.data)
            setList(prev => {
            prev = prev || []
            if (prev.length === 0 && CP.length>4){
                setPBCp(true)
            }
            else {
                setPBCp(false)
            }
            return prev;
            })
        }) 
    }
    function handleScrollSelect(event){
        var city = event.target.value
        setCity(city)
    }
    function handleHideShow(){
        if (ShowDetails){
            setShowDetail(false)
        }
        else {
            setShowDetail(true)
        }
    }
    return (
        <div>
            <Entete titre="Colza robuste et zéro insecticide"/>
            <div className='container'>
                <div className='paragraphe'>Evolution du risque entre le colza conventionnel et le colza zéro insecticide et entre le colza robuste et le colza zéro insectice. On prend comme référence la moyenne du rendement entre 2016 et 2020.</div>
                <div className='row' style={{justifyContent:'flex-start'}}>
                    <div style={{width:'33%', marginRight:'5%'}}>
                        <div className='titreMoins'> Saisissez votre code postal <span style={{color:'red', fontWeight:'normal'}}>{PBCp ? 'Ce code postal est incorrecte' : ''}</span></div>
                        <input 
                        className='input'
                        maxLength="5"
                        value={CP}
                        onChange={handleChange}
                        placeholder='Code Postal'
                        type='text'
                        required/>
                    </div>
                    <div style={{width:'33%'}}>
                        <div className='titreMoins'> Sélectionnez votre ville </div>
                        <select value={city} onChange={handleScrollSelect} required>
                        <option value="">Choisissez</option>
                        { Array.isArray(ListVille) ? ListVille.map((item)=>( <option key={item.key} value={item.key} > {item.libelle} </option> ) ) : ListVille===undefined ?  <option value={'pas de ville'}>  </option>  : <option value={ListVille.libelle}> {ListVille.libelle} </option> }
                        </select>
                    </div>
                    <div className='row'  style={{width:'33%'}}>
                        <button type='submit' className='validation' onClick={handleSubmit}> Valider </button>
                    </div>
                </div>

                <div className='dispLigne'>
                {dataConv!== undefined && dataRobuste!== undefined && dataZI!== undefined && dataZIetRob!== undefined ? (
                    <div className='column dispLeft dispLeft2050'>
                        <GrapheColzaZI DataConv={dataConv} DataRobuste={dataRobuste} DataZI={dataZI} DataZIetRob={dataZIetRob}/>
                        <button className={ShowDetails ? 'validation validationCompAssol valCompOrange' : 'validation validationCompAssol valCompVert'} onClick={handleHideShow} style={{marginTop:'-50px', zIndex:'2'}}> {ShowDetails ? '- de détails' : '+ de détails'}</button>
                        
                        
                    </div>
                    ) : (
                    <div className='column dispLeft dispLeft2050'>
                        <img src={loading_Data} alt="chargement" className="" style={{marginBottom:"20px"}}/> 
                        <button className={ShowDetails ? 'validation validationCompAssol valCompOrange' : 'validation validationCompAssol valCompVert'} onClick={handleHideShow} > {ShowDetails ? '- de détails' : '+ de détails'}</button>
                    </div> )}

                    <div className='column dispRight dispRight2050' style={{alignItems:'flex-start'}}>
                        <div className='titreMoins row' style={{width:'100%',textAlign:'center'}}> Evolution en % de la moyenne du rendement de 2016 à 2020 </div>
                        {dataConv!== undefined && dataRobuste!== undefined && dataZI!== undefined && dataZIetRob!== undefined ? (
                        <div className='tableau_prix_rapport' style={{width:'100%'}}> 
                            <div className='row_tableau_prix_rapport'>
                                <div className='ordonnée_tableau_prix_rapport ordonne_ZI' style={{height:'60px', fontWeight:'bold', textAlign:'center', paddingLeft:'2.5px', paddingRight:'2.5px'}}> Différence avec conventionel </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI abscisse_tableau_prix_rapport' style={{backgroundColor:'#7030A2', height:'60px', textAlign:'center'}}> Très courant <br/>(3 ans)</div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI abscisse_tableau_prix_rapport' style={{backgroundColor:'#FABE06', height:'60px', textAlign:'center'}}> Courant <br/>(7 ans) </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI abscisse_tableau_prix_rapport'style={{backgroundColor:'#0027D6', height:'60px', textAlign:'center'}}> Exceptionnel <br/>(20 ans)</div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI abscisse_tableau_prix_rapport' style={{backgroundColor:'#DB04B9', height:'60px', textAlign:'center'}}> Catastrophique <br/>(50 ans) </div>
                            </div>
                            <div className='row_tableau_prix_rapport'>
                                <div className='ordonnée_tableau_prix_rapport ordonne_ZI' style={{fontWeight:'bold'}}> Robuste </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataRobuste[0]- dataConv[0]).toFixed(1).toLocaleString()} % </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataRobuste[1]- dataConv[1]).toFixed(1).toLocaleString()} % </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataRobuste[2]- dataConv[2]).toFixed(1).toLocaleString()} % </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataRobuste[3]- dataConv[3]).toFixed(1).toLocaleString()} % </div>
                            </div>
                            <div className='row_tableau_prix_rapport'>
                                <div className='ordonnée_tableau_prix_rapport ordonne_ZI' style={{fontWeight:'bold'}}> ZI </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataZI[0]- dataConv[0]).toFixed(1).toLocaleString()} % </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataZI[1]- dataConv[1]).toFixed(1).toLocaleString()} % </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataZI[2]- dataConv[2]).toFixed(1).toLocaleString()} % </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataZI[3]- dataConv[3]).toFixed(1).toLocaleString()} % </div>
                            </div>
                            <div className='row_tableau_prix_rapport'>
                                <div className='ordonnée_tableau_prix_rapport ordonne_ZI' style={{fontWeight:'bold'}}> Robuste + ZI </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataZIetRob[0]- dataConv[0]).toFixed(1).toLocaleString()} % </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataZIetRob[1]- dataConv[1]).toFixed(1).toLocaleString()} % </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataZIetRob[2]- dataConv[2]).toFixed(1).toLocaleString()} % </div>
                                <div className='data_tableau_prix_rapport data_tableau_ZI'> {(dataZIetRob[3]- dataConv[3]).toFixed(1).toLocaleString()} % </div>
                            </div>
                        </div>
                        ) : (
                        <div className='column dispRight dispRight2050'>
                            <img src={loading_Data} alt="chargement" className="" /> 
                        </div> )}
                        <div className={ShowDetails ? ' paragraphe ShowResult' : ' paragraphe HideResult'} style={{width:'100%'}}>
                            Le graphique de répartition ci-dessous est la fréquence de réalisation d'un tel rendement compte tenu des aléas climatiques.
                        </div>
                    </div>
                </div>
                {dataConv!== undefined && dataRobuste!== undefined && dataZI!== undefined && dataZIetRob!== undefined ? (
                    <div className={ShowDetails ? 'ShowResult' : 'HideResult'} style={{marginLeft:'0%',width:'100%'}}>
                      <GrapheRepartitionRiskColzaZI DataConv={dataRepartConv} DataRobuste={dataRepartRobuste} DataZI={dataRepartZI} DataZIetRob={dataRepartZIetRob} nb={ValRDT} franchise={franchise} />
                    </div>
                    ) : ("")}
            </div>
        </div>
    )
}

export default ColzaZI