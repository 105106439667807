import React, {useState} from 'react'
import {Navigate} from 'react-router-dom'

function NavOAD() {
    const role = JSON.parse(localStorage.getItem('role')); 
    const formule = JSON.parse(localStorage.getItem('formule')); 

    const [showLinks, setShowLinks] = useState(false)
    const [goToHome, setGoToHome] = useState(false)
    const [goToNewSimul, setGoToNewSimul] = useState(false)
    const [goToSimulation, setGoToSimulation] = useState(false)
    const [goToColzaZI, setGoToUColzaZI] = useState(false)
    const [goToDR50, setGoToDR50] = useState(false)
    const [goToUneCulture, setUneCulture] = useState(false)
    const [goToProfil, setGoToProfil] = useState(false)
    const [goToInscription, setGoToInscription] = useState(false)
    const [goToQuestions, setGoToQuestions] = useState(false)
    const [goToMD, setGoToMD] = useState(false)

    const handleShowLinks = () => {
        setShowLinks(!showLinks)
    }

    const url = window.location.href
    const root = window.location.origin

    function handleClickHome(event) {
        event.preventDefault()
        setGoToHome(true)
    }
    function handleClickSimulation(event) {
        event.preventDefault()
        setGoToSimulation(true)
    }
    function handleClickNewSimul(event) {
        event.preventDefault()
        setGoToNewSimul(true)
    }
    function handleClickColzaZI(event){
        event.preventDefault()
        setGoToUColzaZI(true)
    }
    function handleClickDR50(event){
        event.preventDefault()
        setGoToDR50(true)
    }
    function handleClickUneCulture(event) {
        event.preventDefault()
        setUneCulture(true)
    }
    function handleClickProfil(event) {
        event.preventDefault()
        setGoToProfil(true)
    }
    function handleClickInscription(event) {
        event.preventDefault()
        setGoToInscription(true)
    }
    function handleClickQuestions(event) {
        event.preventDefault()
        setGoToQuestions(true)
    }
    function handleClickMD(event) {
        event.preventDefault()
        setGoToMD(true)
    }

    if(goToHome) {
        return <Navigate push to={`/`} />
    }
    if(goToNewSimul) {
        return <Navigate push to={`/nouvellesimulation`} />
    }
    if(goToSimulation) {
        return <Navigate push to={`/simulations`} />
    }
    if(goToColzaZI) {
        return <Navigate push to={`/unecolzarobustessezeroinsecticide`} />
    }
    if(goToDR50) {
        return <Navigate push to={`/diagorisk2050`} />
    }
    if(goToUneCulture) {
        return <Navigate push to={`/uneculture`} />
    }
    if(goToProfil) {
        return <Navigate push to={`/monprofil`} />
    }
    if(goToInscription) {
        return <Navigate push to={`/inscription`} state={{offre:"Premium"}} />
    }
    if(goToQuestions) {
        return <Navigate push to={`/questions`} />
    }
    if(goToMD) {
        return <Navigate push to={`/manueldecouverte`} />
    }

  return (
    <nav className={showLinks? 'navbarSW show-nav' : 'navbarSW hide-nav'}>
        <ul className={ role==="ROLE_ADMINISTRATEUR" ?'navbarSW_links linksOAD backgroundAdmin' : formule==="Flash" ? 'navbarSW_links linksOAD backgroundFlash' : 'navbarSW_links linksOAD backgroundExploitant' }>
            <li className='navbarSW_item'>
                <button className={role==="ROLE_ADMINISTRATEUR" ? 'ButtonHome navbarSW_lien navbarSW_lienAdmin': 'ButtonHome navbarSW_lien navbarSW_lienExploit'} onClick={handleClickHome} disabled={url===root+'/' ? true : false} > Accueil </button>
            </li>
            <li className='navbarSW_item'>
                <button className={role==="ROLE_ADMINISTRATEUR" ? 'ButtonHome navbarSW_lien navbarSW_lienAdmin': 'ButtonHome navbarSW_lien navbarSW_lienExploit'} onClick={handleClickNewSimul} disabled={url.indexOf("nouvellesimulation") > -1 ? true : false} > Nouvelle simulation </button>
            </li>
            <li className='navbarSW_item'>
                <button className={role==="ROLE_ADMINISTRATEUR" ? 'ButtonHome navbarSW_lien navbarSW_lienAdmin': 'ButtonHome navbarSW_lien navbarSW_lienExploit'} onClick={handleClickSimulation} disabled={url.indexOf("simulations") > -1 ? true : false} > Mes simulations </button>
            </li>
            {/* <li className='navbarSW_item'>
                <button className={role==="ROLE_ADMINISTRATEUR" ? 'ButtonHome navbarSW_lien navbarSW_lienAdmin' : 'ButtonHome navbarSW_lien'} onClick={handleClickUneCulture} disabled={url.indexOf("uneculture") > -1 ? true : false} > Analyse sur une culture</button>
            </li> */}
            <li className='navbarSW_item'>
                <button className={role==="ROLE_ADMINISTRATEUR" ? 'ButtonHome navbarSW_lien navbarSW_lienAdmin': 'ButtonHome navbarSW_lien navbarSW_lienExploit'} onClick={handleClickProfil} disabled={url.indexOf("monprofil") > -1 ? true : false} > Profil </button>
            </li>
            <li className='navbarSW_item' style ={{display : role==="ROLE_ADMINISTRATEUR" ? 'block' : 'none'}}>
                <button className={role==="ROLE_ADMINISTRATEUR" ? 'ButtonHome navbarSW_lien navbarSW_lienAdmin' : 'ButtonHome navbarSW_lien'} onClick={handleClickDR50} disabled={url.indexOf("diagorisk2050") > -1 ? true : false} > DiagoRisk 2050 territoire </button>
            </li>
            <li className='navbarSW_item' style ={{display : role==="ROLE_ADMINISTRATEUR" ? 'block' : 'none'}}>
                <button className={role==="ROLE_ADMINISTRATEUR" ? 'ButtonHome navbarSW_lien navbarSW_lienAdmin' : 'ButtonHome navbarSW_lien'} onClick={handleClickColzaZI} disabled={url.indexOf("unecolzarobustessezeroinsecticide") > -1 ? true : false} > Colza robuste et zéro insecticide</button>
            </li>
            <li className='navbarSW_item'>
                <button className={role==="ROLE_ADMINISTRATEUR" ? 'ButtonHome navbarSW_lien navbarSW_lienAdmin ShowResult' : 'ButtonHome navbarSW_lien HideResult'} onClick={handleClickInscription} disabled={url.indexOf("inscription") > -1 ? true : false} > Créer un compte </button>
            </li>
            <li className='navbarSW_item'>
                <button className={role==="ROLE_ADMINISTRATEUR" ? 'ButtonHome navbarSW_lien navbarSW_lienAdmin': 'ButtonHome navbarSW_lien navbarSW_lienExploit'} onClick={handleClickQuestions} disabled={url.indexOf("questions") > -1 ? true : false}  > Questions ? </button>
            </li>
            <li className='navbarSW_item'>
                <button className={role==="ROLE_ADMINISTRATEUR" ? 'ButtonHome navbarSW_lien navbarSW_lienAdmin': 'ButtonHome navbarSW_lien navbarSW_lienExploit'} onClick={handleClickMD} disabled={url.indexOf("/manueldecouvertee") > -1 ? true : false} > Manuel de découverte </button>
            </li>
        </ul>
        <button className={role==="ROLE_ADMINISTRATEUR" ?'navbarSW_burger burgerOAD backgroundAdmin': formule==="Flash"? 'navbarSW_burger burgerOAD backgroundFlash' : 'navbarSW_burger burgerOAD backgroundExploitant'} onClick={handleShowLinks}>
            <span className='burger-bar'></span>
        </button>

    </nav>
  )
}

export default NavOAD