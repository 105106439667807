import React from 'react';
import Nav from '../MEP/SWNav';
import FooterSW from '../MEP/footer_SW';
import BlogContainer from './blogContainer';
import blog1 from '../../assets/blog-1.jpg'
import blog2 from '../../assets/blog-2.jpg'
import blog3 from '../../assets/blog-3.jpg'
import blog4 from '../../assets/blog-4.jpg'
import blog5 from '../../assets/Lancement.png'
import blog6 from '../../assets/blog-6.jpg'
import blog7 from '../../assets/blog-7.jpg'
import blog8 from '../../assets/blog-5.jpg'
import blog9 from '../../assets/blog-8.png'
import blog10 from '../../assets/blog-9.jpg'
import blog11 from '../../assets/blog-10.png'
import blog12 from '../../assets/blog-11.jpg'
import blog13 from '../../assets/blog-12.png'

function Blog() {
  return (
    <div>
      <Nav/>
      <div className="containerSW">
        <h1 className="propositionValeur titreSW">Actualités</h1>
        <BlogContainer 
            titre={"Retrouvez nous au ViniTech-Sifel à Bordeaux"} 
            texte1={"Du 26 au 28 novembre au parc des expositions de Bordeaux, venez nous rencontrer sur le stand B1707 pour échanger sur vos problématiques et notre outil ! "} 
            texte2={"Venez découvrir les nouvelles fonctionnalités de DiagoRisk ! "} 
            texte3={"DiagoRisk chiffre les impacts d'un réchauffement climatique à +2°C et +4°C et vous aide à choisir les solutions les plus efficaces en 2030-2040-2050 pour une exploitation viticole ou un territoire.Un encépagement et un code postal suffisent pour obtenir les 1ers résultats."} 
            image={blog13} 
            dispo={1}
            date={"25/11/2024"}/>
       <BlogContainer 
            titre={"Le replay du webinaire 'Assurer le mildiou et les autres maladies fongiques avec PRAAM ?' est en ligne"} 
            texte1={"Retrouvez le replay du webinaire sur la chaine YouTube de DiagoRisk !"} 
            texte2={""} 
            texte3={"🎯 Retrouver le sur ->  https://youtu.be/nqZDnt9L5-k"} 
            image={blog12} 
            dispo={2}
            date={"21/10/2024"}/>
        <BlogContainer 
            titre={"Webinaire : Assurer le mildiou et les autres maladies fongiques avec PRAAM ?"} 
            texte1={"Forts des enseignements des projets VitiREV et ARRUPVICO et en lien avec l'Institut français de la vigne et du vin (IFV), nous proposons aujourd'hui aux vignobles français de déployer un protocole d'optimisation sécurisée des traitements contre le milidou et les autres maladies fongiques. Le principe : bénéficier d'une assurance couvrant les aléas climatiques, le Mildiou et les principales maladies fongiques en contrepartie du respect d'un protocole d'optimisation et de réduction des usages phytosanitaires."} 
            texte2={"Le vendredi 18 octobre 2024 à 14h, nous vous expliquerons l'architecture du dispositif que nous envisageons dans le cadre du dispositif PRAAM. La Région Nouvelle-Aquitaine présentera le schéma envisagé avec les partenaires de VitiREV pour couvrir au minimum 2000 ha dès 2025. À l’issue du webinaire, nous proposerons aux opérateurs économiques intéressés ou à leurs représentants de rédiger une réponse à l'AMI PRAAM avant le 29 novembre 2024."} 
            texte3={"🎯 Rendez-vous le vendredi 18 octobre 2024 en visio. Pour accéder au webinaire. Inscrivez-vous ! ->   https://forms.office.com/r/K4PCki3cep"} 
            image={blog11} 
            dispo={1}
            date={"09/10/2024"}/>
        <BlogContainer 
            titre={"Le replay du webinaire 'Assurer les transitions agroécologiques avec PRAAM ?' est en ligne"} 
            texte1={"Retrouvez le replay du webinaire sur la chaine YouTube de DiagoRisk !"} 
            texte2={""} 
            texte3={"🎯 Retrouver le sur ->   https://youtu.be/upPq--UusHQ"} 
            image={blog10} 
            dispo={2}
            date={"07/10/2024"}/>
        <BlogContainer 
            titre={"Webinaire : Assurer les transitions agroécologiques avec PRAAM ?"} 
            texte1={"Bpifrance a annoncé le lancement d’une nouvelle aide PRAAM pour diminuer l’usage des produits phytosanitaires classiques et promouvoir les alternatives du biocontrôle et des biosolutions. Doté d’un budget de 90 M€, ce dispositif pourrait couvrir entre 100 et 250 000 ha. Un des enjeux est de sécuriser cette transition, notamment en s'appuyant sur une approche combinant notamment un accompagnement technique et une couverture du risque innovante. A l'occasion d'un webinaire, le vendredi 4 octobre de 14h à 15h, Terres Inovia et DiagoRisk vous proposent d'expliquer comment construire un dispositif de sécurisation en grandes cultures et la place à accorder (ou pas) à une « assurance des transitions »  (volet 1, qui porte sur les « innovations contractuelles et assurantielles pour accompagner la prise de risque »)."} 
            texte2={"­Contenu du webinaire : - Quand a-t-on besoin d’un dispositif assurantiel ? - Comment articuler une couverture des pertes exceptionnelles avec l’accompagnement technique et/ou une incitation financière ? - Comment passer d’une approche par culture à une approche « Système de production » ?"} 
            texte3={"🎯 Rendez-vous le vendredi 4 octobre 2024 en visio. Pour accéder au webinaire. Inscrivez-vous ! ->  https://forms.office.com/r/wsEF9R8Ba2"} 
            image={blog9} 
            dispo={1}
            date={"25/09/2024"}/>
        <BlogContainer 
            titre={"Webinaire de découverte OAD DiagoRisk"} 
            texte1={"Rendez-vous le vendredi 24 mai 2024 en visio "} 
            texte2={"DiagoRisk chiffre les impacts d'un réchauffement climatique à +2°C et +4°C et vous aide à choisir les solutions les plus efficaces en 2030-2040-2050 pour une  exploitation agricole ou un territoire. Notre OAD vous permettra de réaliser facilement les Diagnostics CLIMAT individuel et territorial financés par l'ADEME. "} 
            texte3={"🎯 Pour ceux qui ne sont pas au LFDay ou qui souhaitent approfondir, nous vous proposons une démonstration complète en visio. Inscrivez-vous ! -> https://lnkd.in/eV5MA9St"} 
            image={blog7} 
            dispo={2}
            date={"21/05/2024"}/>
        <BlogContainer 
            titre={"Venez échanger avec nous au LFDay 2024"} 
            texte1={"Rendez-vous le mardi 21 mai 2024 au GroundControl de Paris !"} 
            texte2={"Comment faire facilement un Diagnostic Climat, individuel ou territorial, avec DiagoRisk et bénéficier du soutien de l'ADEME ➡️ RDV au LFDay mardi 21 mai "} 
            texte3={"Pour faciliter les échanges, pensez à réserver un rendez-vous sur la plateforme du LFDay"} 
            image={blog6} 
            dispo={1}
            date={"15/05/2024"}/>
        <BlogContainer 
            titre={"Séminaire ARRUP-VICO : pour préparer l'avenir et rendre possible les transitions"} 
            texte1={"Assurer une réduction volontaire des usages phytosanitaires ? Particulièrement sur la vigne et le colza ? Pour découvrir les 1ers résultats et échanger, rendez-vous au séminaire technique du projet, le 15 mars prochain."} 
            texte2={"Objectifs du séminaire : présenter les avancées techniques du projet ARRUPVICO, dresser un aperçu des cadres techniques et réglementaires actuels, envisager les évolutions nécessaires pour favoriser l’expérimentation à grande échelle de nouvelles garanties, et proposer des mesures d’accompagnement des risques liés à la transition agroécologique. "} 
            texte3={"En collaboration avec @VITIREV ! Invitations disponibles sur demande. Pour le programme provisoire, suivre le lien."} 
            image={blog8} 
            dispo={2}
            date={"24/01/2024"}/>
        <BlogContainer 
            titre={"Nouvelles formations en ligne !"} 
            texte1={"Jour J du lancement des nouvelles formations de DiagoRisk !"} 
            texte2={"Comment profiter au mieux de la réforme de l’assurance récolte ? Comment mieux gérer ses risques climatiques ? Les 2 atouts clés de cette formation : un chiffrage personnalisé du risque, la possibilité de se comparer et du coût de l'assurance Récolte."} 
            texte3={"Grâce à notre OAD DiagoRisk, un code postal et un assolement suffisent pour avoir les 1ers résultats ! Inscrivez-vous !"} 
            image={blog5} 
            dispo={1}
            date={"16/10/2023"}/>
        <BlogContainer 
            titre={"DiagoRisk aux LFDays"} 
            texte1={"Rendez-vous le 13 juin au #LFDay2023 avec La Ferme Digitale"} 
            texte2={""} 
            texte3={""} 
            image={blog4} 
            dispo={2}
            date={"12/05/2023"}/>
        <BlogContainer 
            titre={"« Se préparer à un #réchauffement à +4°C », telle est l’injonction du Ministre de la Transition Ecologique, Christophe Béchu."} 
            texte1={"Loin d’être un simple exercice, ces simulations changent radicalement les perspectives. Pour la viticulture, à +2°C, le #Bordeaux et le #Cognac se sentent épargnés ou largement en capacité de faire face à l'augmentation du coût des aléas climatiques (cf. page2). La #Champagne voit de son exposition aux risques climatiques diminuer. 🍾"} 
            texte2={"A +4°C, tous les vignobles sont impactés, à des niveaux qui remettent en cause leur rentabilité, voir leur viabilité. Certaines zones peuvent être victimes de véritables surprises avec des inversions brutales de perspectives (exemple dans le #valdeloire). L'enjeu est donc de définir comment s'adapter pour faire face au #réchauffement qu'il soit de +2°C ou à 4°C."} 
            texte3={"Nos simulations, prudentes, portent sur 80% des cépages et des communes, avec les mêmes réserves que dans celles situées dans le post précédent. Olivier Zebic"} 
            image={blog3} 
            dispo={1}
            date={"27/04/2023"}/>
        <BlogContainer 
            titre={"Quels coûts du réchauffement climatique en 2050 pour la #viticulture ?"} 
            texte1={"Avec Olivier Zebic, nous développons une approche originale pour chiffrer la perte de rendement par cépage et par commune (« toutes choses égales par ailleurs », hors bioagresseurs et effet qualitatif).A 🌡 + 2°C, nous identifions 4 France viticoles selon les pertes : 1. #adaptation urgente :  les rendements moyens sont amputés de +10% voir +15%. Pourtour méditerranéen et Nord-Est de l’Occitanie 2. Stable à élevé (autour de 5%):  l'augmentation s'ajoute à un niveau déjà élevé; la viticulture se rapproche de l’arboriculture aujourd’hui. Beaujolais – Bourgogne - Alsace 3. Bigarrés : Bordeaux - Cognac - Val de Loire Aval - à affiner selon les terroirs 4. Favorable :  Champagne – Nord-Est du Val de Loire"} 
            texte2={"Ce chiffrage aide à mesurer les efforts d’adaptation à envisager et à étaler les investissements dans le temps. Il conduit aussi à évaluer l’efficacité des solutions disponibles, par exemple, bien choisir ses nouveaux cépages."} 
            texte3={"Ce que cache cette carte : • La substitution entre aléas : moins de gel, mais des coups de chaleur chroniques par exemple • des rendements qui deviennent plus fluctuants • Les impacts qualitatifs et sanitaires • Une dégradation qui n’est pas régulière dans le temps • 😨 AU DELA DE +2°C, beaucoup de observationss sont à revoir ..."} 
            image={blog2} 
            dispo={2}
            date={"22/02/2023"}/>
        <BlogContainer 
            titre={"Risques et coûts liés à la précocité de la vigne"} 
            texte1={"Exemple à Chablis (89) : la précocité augmente le nombre de jour pendant lesquels les bourgeons et les inflorescences en formation sont exposés. Comme ils sont gorgés d'eau, ils sont plus aussi vulnérables.A ce jour et vu la période de froid annoncée, difficile de prévoir où en sera la végétation dans 2 mois. Plus le froid (modéré) durera, mieux se sera."} 
            texte2={"Avec Olivier Zebic, nous avons développé une méthodologie et un outil pour chiffrer l’impact du changement climatique sur les cultures, notamment en termes de coûts et de risques associés (rendement, « qualité » etc.). Sur vigne, nous avons pris en compte les spécificités des cépages. Nous nous sommes appuyés sur les modélisations du GIEC (DRIAS-MétéoFrance), de nombreuses références INRAE et IFV et des approches assurantielles."} 
            texte3={""} 
            image={blog1} 
            dispo={1}
            date={"17/01/2023"}/>
      </div>
      <FooterSW/>
    </div>
  );
}

export default Blog;

