import React, {useState, useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import logo from '../../assets/logo-rond2.png'

function Nav() {

    const [showLinks, setShowLinks] = useState(false)
    const [idAgent, setidAgent] = useState();
    const [role, setrole] = useState();

    const [goToHome, setGoToHome] = useState(false)
    const [goToDiag, setgoToDiag] = useState(false)
    const [goToBlog, setGoToBlog] = useState(false)
    const [goToProjets, setgoToProjets] = useState(false)
    const [goToQSN, setGoToQSN] = useState(false)
    const [goToNR, setGoToNR] = useState(false)
    const [goToContact, setGoToContact] = useState(false) 
    const [goToConnexion, setGoToConnexion] = useState(false)
    const [goToSimulation, setGoToSimulation] = useState(false)

    const handleShowLinks = () => {
        setShowLinks(!showLinks)
    }
    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('idAgent'));
        const items2 = JSON.parse(localStorage.getItem('role'));
        if (items) {
          setidAgent(items);
          setrole(items2)
        }
      }, []);


    const url = window.location.href
    const root = window.location.origin

    function handleClickHome(event) {
        event.preventDefault()
        setGoToHome(true)
    }
    function handleClickDC(event) {
        event.preventDefault()
        setgoToDiag(true)
    }
    function handleClickProjets(event) {
        event.preventDefault()
        setgoToProjets(true)
    }
    function handleClickQSN(event) {
        event.preventDefault()
        setGoToQSN(true)
    }
    function handleClickBlog(event) {
        event.preventDefault()
        setGoToBlog(true)
    }
    function handleClickNR(event) {
        event.preventDefault()
        setGoToNR(true)
    }
    function handleContacter(event){
    event.preventDefault()
    setGoToContact(true)
    }
    function handleSubmitAccesCompte(event) {
        event.preventDefault()
        if(idAgent !== undefined) {
          setGoToSimulation(true)
        }
        else {
          setGoToConnexion(true)
        }
      }

    if(goToContact) {
    return <Navigate push to={`/nouscontacter`} />
    }
    if(goToHome) {
        return <Navigate push to={`/`} />
    }
    if(goToDiag) {
        return <Navigate push to={`/diagnostics_climatiques`} />
    }
    if(goToProjets) {
        return <Navigate push to={`/projets`} />
    }
    if(goToBlog) {
        return <Navigate push to={`/blog`} />
    }
    if(goToQSN) {
        return <Navigate push to={`/quisommesnous`} />
    }
    if(goToNR) {
        return <Navigate push to={`/nousrejoindre`} />
    }
    if(goToConnexion) {
        return <Navigate push to={`/connexion`} />
    }
    if(goToSimulation) {
        return <Navigate push to={`/simulations`}/>
    }

  return (
    <nav className={showLinks? 'navSW show-nav' : 'navSW hide-nav'}>
        <button className='navSW_burger' onClick={handleShowLinks}>
            <span className='burger-bar'></span>
        </button>
        <ul className='liste listeAccueilgauche' >
            <li className='item'>
                <button className='ButtonEnteteNav' onClick={handleClickHome} disabled={url===root+'/' ? true : false} >
                <div className='textNavAccueil'>Accueil </div>
                <img src={logo} className='logoNavAccueil' alt="logo"  style={{height:"50px"}}/>
            </button>
            </li>
            <li className='item'>
                <button className='ButtonEnteteNav' style={{fontWeight: url.indexOf("diagnostics_climatiques") > -1 ? 'bold': 'normal', textDecoration:url.indexOf("diagnostics_climatiques") > -1 ? 'underline': 'none'}} onClick={handleClickDC} disabled={url.indexOf("diagnostics_climatiques") > -1 ? true : false} > Diagnostics Climatiques </button>
            </li>
            <li className='item'>
                <button className='ButtonEnteteNav' style={{fontWeight: url.indexOf("projets") > -1 ? 'bold': 'normal', textDecoration:url.indexOf("projets") > -1 ? 'underline': 'none'}} onClick={handleClickProjets} disabled={url.indexOf("projets") > -1 ? true : false} > Transitions </button>
            </li>
            <li className='item'>
                <button className='ButtonEnteteNav' style={{fontWeight: url.indexOf("quisommesnous") > -1 ? 'bold': 'normal', textDecoration:url.indexOf("quisommesnous") > -1 ? 'underline': 'none'}} onClick={handleClickQSN} disabled={url.indexOf("quisommesnous") > -1 ? true : false}  > Qui sommes-nous ? </button>
            </li>
            <li className='item'>
                <button className='ButtonEnteteNav' style={{fontWeight: url.indexOf("blog") > -1 ? 'bold': 'normal', textDecoration:url.indexOf("blog") > -1 ? 'underline': 'none'}} onClick={handleClickBlog} disabled={url.indexOf("blog") > -1 ? true : false} > Actus </button>
            </li>
            {/*  <li className='item'>
                    <button className='ButtonEnteteNav' onClick={handleClickNR} disabled={url.indexOf("/nousrejoindre") > -1 ? true : false} > Nous rejoindre </button>
                </li> */}
         </ul>
       
        <ul className='liste2' style={{width:'40%', justifyContent:'flex-end'}}>
            <li className='item'>
                <button className='ButtonEnteteNav buttonRightEnteteNav' style={{fontWeight: url.indexOf("nouscontacter") > -1 ? 'bold': 'normal', textDecoration:url.indexOf("nouscontacter") > -1 ? 'underline': 'none'}}  onClick={handleContacter} disabled={url.indexOf("nouscontacter") > -1 ? true : false}  > Contact </button>
            </li>
            <li className='item' >
                <button className='ButtonEnteteNav buttonConnexionNav buttonRightEnteteNav' onClick={handleSubmitAccesCompte}>{idAgent !== undefined ? "Mon compte" : "Connexion"} </button>
            </li>
        </ul>

    </nav>
  )
}

export default Nav