import React, {useState} from 'react';
import {Navigate} from 'react-router-dom'

import logo from '../../assets/DiagoRisk-logoRVB.png'
import mans from '../../assets/logo-universite-du-mans.png'
import perqo from '../../assets/logo-du-perqo.jpg'
import idf from '../../assets/Logo-region-ile-de-france.png'
import inrae from '../../assets/logo-inrae.png'
import TI from '../../assets/terreInnovia_logo.png'
import ARTB from '../../assets/artb.png'
import ifv from '../../assets/LOGO-IFV-blanc.png'
import sunagri from '../../assets/Logo-Sun-agri-2021-LD.png'
import ecophyto from '../../assets/Logo-ecophyto.png'
import ofb from '../../assets/logo-ofb-actualite.jpg'
import savethedate from '../../assets/save-the-date.png'
import vitiPlus2 from '../../assets/carte+2°.png'
import img_ente from '../../assets/Vigne-grandes-culture-accueil4.png'
import RA from '../../assets/RA.png'

import Nav from '../MEP/SWNav'
import FooterSW from '../MEP/footer_SW';


function Accueil() {

  const [goToLocalisation, setGoToLocalisation] = useState(false)
  const [goToProjets, setgoToProjets] = useState(false)
  const [goToContact, setGoToContact] = useState(false)
  const [goToDiag, setgoToDiag] = useState(false)


  function handleContacter(event){
    event.preventDefault()
    setGoToContact(true)
  }
  function handleSubmitLoca(event) {
    event.preventDefault()
    setGoToLocalisation(true)
  }
  function handleClickProjets(event) {
    event.preventDefault()
    setgoToProjets(true)
}
function handleClickDC(event) {
  event.preventDefault()
  setgoToDiag(true)
}

  if(goToLocalisation) {
      return <Navigate push to={`/decouvrirDiagoRisk`} />
  }
  if(goToProjets) {
    return <Navigate push to={`/projets`} />
  }
  if(goToContact) {
    return <Navigate push to={`/nouscontacter`} />
  }
  if(goToDiag) {
    return <Navigate push to={`/diagnostics_climatiques`} />
  }

  return (
    <div>
      <Nav/>
      <div className="accueil" >          
          <div className='PremierBlocAccueil'>
            <img src={logo} alt="Logo DiagoRisk"  style={{width:"20%", paddingTop:'20px'}}/>
            <h1 className="propositionValeur"> Dérisquer les transitions agricoles </h1>
          </div>
      </div>

      <div className='column'>
        <div className='peintureDR'>
          <div className='paragrapheAccueil' style={{fontWeight:'bold'}}>DiagoRisk chiffre les impacts des transitions climatiques et agroécologiques,</div>
          <div className='paragrapheAccueil' style={{fontWeight:'bold'}}>vous aide à identifier les solutions les plus efficaces  </div>
          <div className='paragrapheAccueil' style={{fontWeight:'bold'}}>aujourd’hui et 2035 – 2050 </div>  
          <div className='paragrapheAccueil' style={{fontWeight:'bold'}}>sur votre exploitation et votre territoire.</div>
        </div>

        <div className='cardAccueil' style={{marginTop:'5px'}}>
          <div className='cardAccueil2' >
            <img src={savethedate} alt="chemin agricole" className="imgcardaccueil2 imgduoaccuel"/>
            <div className='paragrapheAccueil imgcardaccueil2' style={{fontWeight:'bold', textAlign:'center', marginTop:'1%'}}> Nouvelles Pratiques, </div>
            <div className='paragrapheAccueil imgcardaccueil2' style={{fontWeight:'bold', textAlign:'center'}}> Impacts des bioagresseurs </div>
            <div className='paragrapheAccueil imgcardaccueil2' style={{fontWeight:'bold', marginBottom:'1%', textAlign:'center'}}>Dérisquons l’avenir ensemble</div>
            <div className='paragraphe paragrapheLanding imgcardaccueil2 prghduoaccueil' style={{marginBottom:'1%'}}>Colza Zéro Insecticide, Vigne à Moindres traitements, Plan Protéines, Betteraves sans néonicotinoïde, …</div>
            <div className='paragraphe paragrapheLanding imgcardaccueil2 prghduoaccueil'>Nous aidons à mieux évaluer les risques pour définir des stratégies plus performantes.</div>
            <button className='validation validationAccueil2' onClick={handleClickProjets}>En savoir plus</button>
          </div>
          <div className='cardAccueil2' >
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%'}}>
              <img src={vitiPlus2} alt="champs de cultures céréalières" className="imgcardaccueil2 imgduoaccuel"/>
              <div className='paragrapheAccueil imgcardaccueil2' style={{fontWeight:'bold', textAlign:'center', marginTop:'1%'}}>Réalisez vos diagnostics Climat facilement </div>
              <div className='paragrapheAccueil imgcardaccueil2' style={{fontWeight:'bold', textAlign:'center', marginBottom:'1%'}}>grâce à DiagoRisk</div>
              <div className='paragraphe paragrapheLanding imgcardaccueil2 prghduoaccueil'>A l’échelle de votre territoire ou de votre exploitation, nous vous accompagnons dans l’élaboration de votre stratégie collective d’adaptation au changement climatique. </div>
            </div>
            
            <button className='validation validationAccueil2' onClick={handleClickDC} >En savoir plus</button>
          </div>
        </div>

        <div style={{width:'90%', backgroundColor:'#ECFFD9', padding:'5%', textAlign:'center', marginTop:'20px'}}>
          <div className='paragrapheAccueil' style={{fontWeight:'bold', textAlign:'center'}}> DiagoRisk pour les acteurs agri-agro</div>
          <div className='paragraphe paragrapheLanding ' style={{textAlign:'center', marginTop:'15px'}}>DiagoRisk aide les acteurs de l'agriculture à définir et à sécuriser leur stratégie d'adaptation, grâce à un diagnostic à l'échelle d'une exploitation, d'une filière ou d'un territoire et en optimisant les solutions techniques, agronomiques ou financières. Le diagnostic et la stratégie collective peuvent être déclinés à l’échelle de chaque exploitation pour prendre en compte ses spécificités.</div>
          <button className='validation validationAccueil2' onClick={handleSubmitLoca}>Découvrir DiagoRisk en vidéo</button>
        </div>

        

        <div className='cardAccueil3' style={{marginTop:'2%', marginBottom:'2%'}}>
          <img src={RA} alt="Carte des région agricole" className="imageAccueil"/>
          <div className='textCardAccueil textCardAccueil2' style={{marginLeft:'5%'}}>
            <div className='paragraphe paragrapheLanding' style={{marginBottom:'2%'}}> Notre ambition est de dérisquer les nouvelles pratiques agricoles pour accélérer leur adoption à grande échelle. </div>
            <div className='paragraphe paragrapheLanding'> Cette solution s’appuie sur une modélisation puissante des risques rendement, des projections du GIEC et des bioagresseurs, développée par une équipe de spécialistes du risque agricole, du changement climatique et de l’assurance, en partenariat avec l’Institut du Risque et de l’Assurance de l’Université du Mans. </div>
            <button className='validation validationAccueil2' onClick={handleContacter}>Contactez-nous</button>
          </div>
        </div>


        <div className='partenaireDemicercle'>
          <div className='paragrapheAccueil' style={{fontWeight:'bold', marginTop:'1%',marginBottom:'2%', textAlign:'center'}}>
            Ils nous font confiance
          </div>
          <div className='row'>
            <img src={ifv} alt="Logo de l'IFV" className="imgPartenaire"/>
            <img src={inrae} alt="Logo de l'INRAE" className="imgPartenaire"/>
            <img src={mans} alt="Logo de l'université du Mans" className="imgPartenaire"/>
            <img src={sunagri} alt="Logo de SunAgri" className="imgPartenaire"/>
          </div>
          <div className='row'>
            <img src={ARTB} alt="Logo de l'ARTB" className="imgPartenaire"/>
            <img src={ecophyto} alt="Logo EcopPhyto" className="imgPartenaire"/>
            <img src={ofb} alt="Logo de l'OFB" className="imgPartenaire"/>
            <img src={TI} alt="Logo de TerreInnovia" className="imgPartenaire"/>
          </div>
          <div className='row'>
            <img src={perqo} alt="Logo du PERQO" className="imgPartenaire"/>
            <img src={idf} alt="Logo région Ile-de-France" className="imgPartenaire"/>
          </div>
        </div>

      </div>
      <FooterSW/>
    </div>
  );
}

export default Accueil;

