import React, {useEffect, useState} from 'react'
import apiLink from '../../config.js'
import TitreRapport from './titre_rapport'
import GraphOptiFinanciere from '../OAD/Graphiques/Graphe_opti_financiere.js'

function PageOpti({idExploit}) {
  const [ExploitMixte, setExploitMixte] = useState(false)
  const [ExploitViti, setExploitViti] = useState(false)
  const [ExploitGC,setExploitGC] = useState(true)

  const [dataGraphCN, setDataGraphCN] = React.useState([
    [
      [0, 35, 17, 'Sans ass', 'Sans assurance']
    ],
    [
      [1, 14, 61, 'MRC 20%','Sans option' ],
      [0.4, 19, 49,'MRC 25%' ,'Sans option' ],
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 60%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 60%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 110%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 110%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 120%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 120%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 90%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 90%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 105%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 105%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 110%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 110%']
    ],
    [
      [1.25, 11, 68, 'MRC 20%','Rachat rendement 110%, rachat prix 120%' ],
      [0.65, 14, 55, 'MRC 25%', 'Rachat rendement 110%, rachat prix 120%'],
      
    ],    
  ])
  const [dataGraphCB, setDataGraphCB] = React.useState([
    [
      [0, 35, 17, 'Sans ass', 'Sans assurance']
    ],
    [
      [1, 14, 61, 'MRC 20%','Sans option' ],
      [0.4, 19, 49,'MRC 25%' ,'Sans option' ],
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 60%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 60%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 110%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 110%']
    ],
    [
      [1.9, 27, 45, 'MRC 20%','Rachat prix 120%'],
      [0.2, 31, 41, 'MRC 25%','Rachat prix 120%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 90%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 90%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 105%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 105%']
    ],
    [
      [1.7, 17, 45, 'MRC 20%','Rachat rendement 110%'],
      [0.7, 23, 41, 'MRC 25%','Rachat rendement 110%']
    ],
    [
      [1.25, 11, 68, 'MRC 20%','Rachat rendement 110%, rachat prix 120%' ],
      [0.65, 14, 55, 'MRC 25%', 'Rachat rendement 110%, rachat prix 120%'],
      
    ],    
  ])
  const [cout, setCout] = React.useState('CN')
  const [PBTot, setPBTot] = React.useState(0)

  function shapeData(dataGraph){
    return [
      [
        [100*Math.round(dataGraph['Sans Assurance'][0]*1000)/1000, 100*Math.round(dataGraph['Sans Assurance'][1]*1000)/1000, 100*Math.round(dataGraph['Sans Assurance'][2]*1000)/1000, 'Sans ass', 'Sans assurance']
      ],
      [
        [100*Math.round(dataGraph['MRC 20%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20%'][2]*1000)/1000, 'MRC 20%','Sans option' ],
        [100*Math.round(dataGraph['MRC 25%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25%'][2]*1000)/1000,'MRC 25%' ,'Sans option' ],
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Prix 60% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 60% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 60% '][2]*1000)/1000, 'MRC 20%','Rachat prix 60% '],
        [100*Math.round(dataGraph['MRC 25% + Prix 60% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 60% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 60% '][2]*1000)/1000, 'MRC 25%','Rachat prix 60% ' ]
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Prix 110% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 110% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 110% '][2]*1000)/1000, 'MRC 20%','Rachat prix 110% '],
        [100*Math.round(dataGraph['MRC 25% + Prix 110% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 110% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 110% '][2]*1000)/1000, 'MRC 25%','Rachat prix 110% ' ]
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Prix 120% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 120% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Prix 120% '][2]*1000)/1000, 'MRC 20%','Rachat prix 120% '],
        [100*Math.round(dataGraph['MRC 25% + Prix 120% '][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 120% '][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Prix 120% '][2]*1000)/1000, 'MRC 25%','Rachat prix 120% ' ]
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 90%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 90%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 90%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 90%'],
        [100*Math.round(dataGraph['MRC 25% + Rendement 90%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 90%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 90%'][2]*1000)/1000, 'MRC 25%','Rachat rendement 90%']
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 105%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 105%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 105%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 105%'],
        [100*Math.round(dataGraph['MRC 25% + Rendement 105%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 105%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 105%'][2]*1000)/1000, 'MRC 25%','Rachat rendement 105%']
      ], 
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 110%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 110% '],
        [100*Math.round(dataGraph['MRC 25% + Rendement 110%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110%'][2]*1000)/1000, 'MRC 25% ','Rachat rendement 110% ']
      ],
      [
        [100*Math.round(dataGraph['MRC 20% + Rendement 110% + Prix 120%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110% + Prix 120%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 20% + Rendement 110% + Prix 120%'][2]*1000)/1000, 'MRC 20%','Rachat rendement 110%, rachat prix 120%' ],
        [100*Math.round(dataGraph['MRC 25% + Rendement 110% + Prix 120%'][0]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110% + Prix 120%'][1]*1000)/1000, 100*Math.round(dataGraph['MRC 25% + Rendement 110% + Prix 120%'][2]*1000)/1000, 'MRC 25%','Rachat rendement 110%, rachat prix 120%'],
        
      ],
    ]
  }

 useEffect ( () => {
  fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
    let data = response
    for (var cle in data) {
        if (cle.includes("Vignes")) {
            setExploitMixte(true)
            setExploitGC(false)
            setExploitViti(false);
            break;
        }
    }
    let allKeysContainVignes = Object.keys(data).every(cle => cle.includes("Vignes"));
    if (allKeysContainVignes) {
        setExploitViti(true);
        setExploitMixte(false)
        setExploitGC(false)
    } else {
        setExploitViti(false);
    }
    })
  fetch(apiLink + 'page/optimisation/' + idExploit)
  .then( (response) => {
    return response.json()
  })
  .then(response =>{
    setDataGraphCN(shapeData(response.dataGraphCN))
    setDataGraphCN(prev => {
    return prev;
    })
    setDataGraphCB(shapeData(response.dataGraphCB))
    setDataGraphCB(prev => {
    return prev;
    })
  }) 
  fetch(apiLink + 'page/exploitation/' + idExploit)
    .then( (response) => {
      return response.json()
    })
    .then(response =>{
      setPBTot(response.PBTotal)
      setPBTot(prev => {
        return prev;
      })
    }) 
  }, [idExploit])

  const minX= 0
  const minY = 0
  const maxX = Math.round(dataGraphCB[8][0][0])%2 === 0 ? Math.round(dataGraphCB[8][0][0]) + 2 : Math.round(dataGraphCB[8][0][0]) +1 
  const maxY = Math.round(dataGraphCB[0][0][1])%2 === 0 ? Math.round(dataGraphCB[0][0][1]) + 2 : Math.round(dataGraphCB[0][0][1]) +1 

  return (
    <div className='containerPrint'>
      <TitreRapport Titre={'Optimisation de mon assurance'} Page={ExploitGC ? '8': ExploitMixte ? "9" : "8"}/>
        <div className='rowResiScalePrincipal' style={{alignItems:'flex-start'}}>
        <div className='optiLeft'>
          <div className='paragraphe' style={{marginBottom:'10px'}}> Sur le graphique :  </div>
          <ul>
            <li> <div className='lila paragraphe'>  À l’horizontal, le<span style={{fontWeight:'bold'}}> coût net </span>(montant annuel de la prime  - les subventions) de l’assurance en % du produit brut </div> </li>
            <li> <div className='lila paragraphe'>  A la verticale, le <span style={{fontWeight:'bold'}}>reste à charge</span> après indemnisation, calculé en moyenne et en % du produit brut </div> </li>
            
          </ul>

          <div className='paragraphe' style={{marginBottom:'20px'}}> Les rond avec un <span style={{fontWeight:'bold'}}> intérieur blanc </span>correspondent à l'assurance <span style={{fontWeight:'bold'}}>MRC 20%</span>, ceux <span style={{fontWeight:'bold'}}> sans</span> correspondent à l'assurance <span style={{fontWeight:'bold'}}>MRC 25%</span>. </div>

          <div className='paragraphe' style={{marginBottom:'10px'}}> Pour lire les détails, passer sur le point avec la souris : </div>
          <div className='paragraphe' > <span style={{fontWeight:'bold'}}>RAC = </span> reste à charge moyen après indemnisation</div>
          <div className='paragraphe' > <span style={{fontWeight:'bold'}}>C = </span>coût net </div>
          

          </div>
          <div className='optiRight'>
          <div className='titreMoins row' style={{marginTop:'0px', marginBottom:'0px'}}> Ratio coût-sécurité (en % de produit brut)  </div>
          <div className='paragraphe row' style={{marginTop:'5px', marginBottom:'0px'}}> Produit brut de référence : {PBTot.toLocaleString()}€ / Risque moyen : {dataGraphCN[0][0][1]} % </div> 
              <GraphOptiFinanciere data={dataGraphCN} print={true} minX={minX} minY={minY} maxX={maxX} maxY={maxY} cout={cout}/>
          </div>
        </div>
        <div className='commentaireRapport'>
          <div style={{fontWeight:'bold', marginBottom:'5px'}}>Commentaire : </div>
          <div>Nous comparons le ratio coût sécurité des assurances avec et sans option de rachat de prix et de rendement. </div>
          <div>Les calculs prennent ici en compte toutes les années possibles, bonnes ou mauvaises en tenant compte de leur fréquence. Le risque moyen correspond à la perte comparée au produit brut de référence. Il donne un ordre de grandeur de l'effort à réaliser chaque année pour couvrir votre risque. </div>
          <div>Dans votre cas, il est de {dataGraphCN[0][0][1]} %. Il est généralement inférieur au risque courant, car plus un sinistre est important, plus il est rare. </div>
          <div style={{marginTop:'20px'}}> On compare le reste à charge après indemnisations, au coût net donc après subventions. Le reste à charge le plus important correspond à une situation sans assurance et donc à votre risque moyen. Logiquement plus la couverture est importante, plus le reste à charge est faible, mais plus le coût de l'assurance augmente. </div>
          <div style={{marginTop:'20px'}}> A reste à charge équivalent, choisissez l'assurance avec les options qui a le coût net le plus faible. </div>
          <div style={{marginTop:'20px'}}> Pour les points qui se situent sous la ligne noire, du fait des subventions, les primes d'assurance payées sont en moyenne moins chères que les indemnisations reçues. Au dessus, c'est le phénomène inverse. Enfin sur cette ligne, les deux s'équilibrent. </div>
        </div>
    </div>
  )
}

export default PageOpti