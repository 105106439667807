import React, {useState} from 'react'
import Entete from '../MEP/entete'
import ButtonQuestions from '../MEP/button question'
import ComponentManuel from './componentManuel'

import navP1 from '../../assets/ManuelDecouv/navP1.PNG'
import navP2 from '../../assets/ManuelDecouv/navP2.PNG'
import navP3 from '../../assets/ManuelDecouv/navP3.PNG'
import creP1 from '../../assets/ManuelDecouv/creP1.PNG'
import creP2 from '../../assets/ManuelDecouv/creP2.PNG'
import nsP1 from '../../assets/ManuelDecouv/nsP1.PNG'
import coeP1 from '../../assets/ManuelDecouv/coeP1.PNG'
import coeP2 from '../../assets/ManuelDecouv/coeP2.PNG'
import coeP3 from '../../assets/ManuelDecouv/coeP3.PNG'
import coetP1 from '../../assets/ManuelDecouv/coetP1.PNG'
import eP1 from '../../assets/ManuelDecouv/eP1.PNG'
import riP1 from '../../assets/ManuelDecouv/riP1.PNG'
import riP2 from '../../assets/ManuelDecouv/riP2.PNG'
import oP1 from '../../assets/ManuelDecouv/oP1.PNG'
import oP2 from '../../assets/ManuelDecouv/oP2.PNG'
import oP3 from '../../assets/ManuelDecouv/oP3.PNG'
import reP1 from '../../assets/ManuelDecouv/reP1.PNG'
import reP2 from '../../assets/ManuelDecouv/reP2.PNG'
import aP1 from '../../assets/ManuelDecouv/aP1.PNG'
import aP2 from '../../assets/ManuelDecouv/aP2.PNG'
import aP3 from '../../assets/ManuelDecouv/aP3.PNG'
import aP4 from '../../assets/ManuelDecouv/aP4.PNG'
import aP5 from '../../assets/ManuelDecouv/aP5.PNG'
import R3050P1 from '../../assets/ManuelDecouv/R3050P1.PNG'
import R3050P2 from '../../assets/ManuelDecouv/R3050P2.PNG'
import alP1 from '../../assets/ManuelDecouv/alP1.PNG'
import alP2 from '../../assets/ManuelDecouv/alP2.PNG'
import solP1 from '../../assets/ManuelDecouv/solP1.PNG'
import solP2 from '../../assets/ManuelDecouv/solP2.PNG'
import solP3 from '../../assets/ManuelDecouv/solP3.PNG'
import creP1VM from '../../assets/ManuelDecouv/creP1VM.PNG'
import creP2VM from '../../assets/ManuelDecouv/creP2VM.PNG'
import creP3VM from '../../assets/ManuelDecouv/creP3VM.PNG'
import nsP1VM from '../../assets/ManuelDecouv/nsP1VM.PNG'
import coeP1VM from '../../assets/ManuelDecouv/coeP1VM.PNG'
import coeP2VM from '../../assets/ManuelDecouv/coeP2VM.PNG'
import coeP3VM from '../../assets/ManuelDecouv/coeP3VM.PNG'
import coetP1VM from '../../assets/ManuelDecouv/coetP1.PNG'
import eP1VM from '../../assets/ManuelDecouv/eP1VM.PNG'
import riP1VM from '../../assets/ManuelDecouv/riP1VM.PNG'
import riP2VM from '../../assets/ManuelDecouv/riP2VM.PNG'
import oP1VM from '../../assets/ManuelDecouv/oP1VM.PNG'
import oP2VM from '../../assets/ManuelDecouv/oP2VM.PNG'
import oP3VM from '../../assets/ManuelDecouv/oP3VM.PNG'
import reP1VM from '../../assets/ManuelDecouv/reP1VM.PNG'
import reP2VM from '../../assets/ManuelDecouv/reP2VM.PNG'
import aP1VM from '../../assets/ManuelDecouv/aP1VM.PNG'
import aP2VM from '../../assets/ManuelDecouv/aP2VM.PNG'
import aP3VM from '../../assets/ManuelDecouv/aP3VM.PNG'
import aP4VM from '../../assets/ManuelDecouv/aP4VM.PNG'
import aP5VM from '../../assets/ManuelDecouv/aP5VM.PNG'

function ManuelDecouverte() {
  const [ClickedGC, setClickedGC]= useState(true);
  const [ClickedViti, setClickedViti]= useState(false);
  const [ClickedNav, setClickedNav]= useState(false);
  const [ClickedCreerExp, setClickedCreerExp]= useState(false);
  const [ClickedNewSimul, setClickedNewSimul]= useState(false);
  const [ClickedComp2, setClickedComp2]= useState(false);
  const [ClickedCompExpType, setClickedCompExpType]= useState(false);
  const [ClickedOADE, setClickedOADE]= useState(false);
  const [ClickedOADRI, setClickedOADRI]= useState(false);
  const [ClickedOAD3050, setClickedOAD3050]= useState(false);
  const [ClickedOADAl, setClickedOADAl]= useState(false);
  const [ClickedOADSol, setClickedOASol]= useState(false);
  const [ClickedOADRE, setClickedOADRE]= useState(false);
  const [ClickedOADO, setClickedOADO]= useState(false);
  const [ClickedOADA, setClickedOADA]= useState(false);
  const [open, setopen]= useState(false);
  const [img1, setIMG1]= useState();
  const [img2, setIMG2]= useState();
  const [img3, setIMG3]= useState();
  const [img4, setIMG4]= useState();
  const [img5, setIMG5]= useState();
  const [img6, setIMG6]= useState();

  function handleClickedGC() {
    setClickedGC(true)
    setClickedViti(false)
    
    if(ClickedCreerExp){
      setIMG1(creP1)
      setIMG2(creP2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedNewSimul){
      setIMG1(nsP1)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedComp2){
      setIMG1(coeP1)
      setIMG2(coeP2)
      setIMG3(coeP3)
      setIMG4()
      setIMG5()
    }
    else if (ClickedCompExpType){
      setIMG1(coetP1)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADE){
      setIMG1(eP1)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADRI){
      setIMG1(riP1)
      setIMG2(riP2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADRE){
      setIMG1(reP1)
      setIMG2(reP2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADO){
      setIMG1(oP1)
      setIMG2(oP2)
      setIMG3(oP3)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADA){
      setIMG1(aP1)
      setIMG2(aP2)
      setIMG3(aP3)
      setIMG4(aP4)
      setIMG5(aP5)
    }
    else if (ClickedOAD3050){
      setIMG1(R3050P1)
      setIMG2(R3050P2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADAl){
      setIMG1(alP1)
      setIMG2(alP2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADSol){
      setIMG1(solP1)
      setIMG2(solP2)
      setIMG3(solP3)
      setIMG4()
      setIMG5()
    }
  }
  function handleClickedViti() {
    setClickedGC(false)
    setClickedViti(true)

    if(ClickedCreerExp){
      setIMG1(creP1VM)
      setIMG2(creP2VM)
      setIMG3(creP3VM)
      setIMG4()
      setIMG5()
    }
    else if (ClickedNewSimul){
      setIMG1(nsP1VM)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedComp2){
      setIMG1(coeP1VM)
      setIMG2(coeP2VM)
      setIMG3(coeP3VM)
      setIMG4()
      setIMG5()
    }
    else if (ClickedCompExpType){
      setIMG1(coetP1VM)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADE){
      setIMG1(eP1VM)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADRI){
      setIMG1(riP1VM)
      setIMG2(riP2VM)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADRE){
      setIMG1(reP1VM)
      setIMG2(reP2VM)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADO){
      setIMG1(oP1VM)
      setIMG2(oP2VM)
      setIMG3(oP3VM)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADA){
      setIMG1(aP1VM)
      setIMG2(aP2VM)
      setIMG3(aP3VM)
      setIMG4(aP4VM)
      setIMG5(aP5VM)
    }
    else if (ClickedOAD3050){
      setIMG1(R3050P1)
      setIMG2(R3050P2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADAl){
      setIMG1(alP1)
      setIMG2(alP2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else if (ClickedOADSol){
      setIMG1(solP1)
      setIMG2(solP2)
      setIMG3(solP3)
      setIMG4()
      setIMG5()
    }
  }

  function handleClickNav(){
    setClickedNav(true)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
    setIMG1(navP1)
    setIMG2(navP2)
    setIMG3(navP3)
  }
  function handleClickCreerExp(){
    setClickedNav(false)
    setClickedCreerExp(true)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
     if (ClickedGC){
      setIMG1(creP1)
      setIMG2(creP2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(creP1VM)
      setIMG2(creP2VM)
      setIMG3(creP3VM)
      setIMG4()
      setIMG5()
    }
  }
  function handleClickNewSimul(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(true)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
    if (ClickedGC){
      setIMG1(nsP1)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(nsP1VM)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
  }
  function handleClickComp2(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(true)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
    if (ClickedGC){
      setIMG1(coeP1)
      setIMG2(coeP2)
      setIMG3(coeP3)
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(coeP1VM)
      setIMG2(coeP2VM)
      setIMG3(coeP3VM)
      setIMG4()
      setIMG5()
    }
  }
  function handleClickCompExpType(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(true)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
     if (ClickedGC){
      setIMG1(coetP1)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(coetP1VM)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
  }
  function handleClickOADE(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(true)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
    if (ClickedGC){
      setIMG1(eP1)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(eP1VM)
      setIMG2()
      setIMG3()
      setIMG4()
      setIMG5()
    }
  }
  function handleClickOADRI(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(true)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
    if (ClickedGC){
      setIMG1(riP1)
      setIMG2(riP2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(riP1VM)
      setIMG2(riP2VM)
      setIMG3()
      setIMG4()
      setIMG5()
    }
  }
  function handleClickOADRE(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(true)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
    if (ClickedGC){
      setIMG1(reP1)
      setIMG2(reP2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(reP1VM)
      setIMG2(reP2VM)
      setIMG3()
      setIMG4()
      setIMG5()
    }
  }
  function handleClickOADO(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(true)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
    if (ClickedGC){
      setIMG1(oP1)
      setIMG2(oP2)
      setIMG3(oP3)
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(oP1VM)
      setIMG2(oP2VM)
      setIMG3(oP3VM)
      setIMG4()
      setIMG5()
    }
  }
  function handleClickOADA(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(true)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
    if (ClickedGC){
      setIMG1(aP1)
      setIMG2(aP2)
      setIMG3(aP3)
      setIMG4(aP4)
      setIMG5(aP5)
    }
    else {
      setIMG1(aP1VM)
      setIMG2(aP2VM)
      setIMG3(aP3VM)
      setIMG4(aP4VM)
      setIMG5(aP5VM)
    }
  }
  function handleClickOAD3050(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(true)
    setClickedOADAl(false)
    setClickedOASol(false)
    setopen(true)
    if (ClickedGC){
      setIMG1(R3050P1)
      setIMG2(R3050P2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(R3050P1)
      setIMG2(R3050P2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
  }
  function handleClickOADAl(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(true)
    setClickedOASol(false)
    setopen(true)
    if (ClickedGC){
      setIMG1(alP1)
      setIMG2(alP2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(alP1)
      setIMG2(alP2)
      setIMG3()
      setIMG4()
      setIMG5()
    }
  }
  function handleClickOADSol(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setClickedOAD3050(false)
    setClickedOADAl(false)
    setClickedOASol(true)
    setopen(true)
    if (ClickedGC){
      setIMG1(solP1)
      setIMG2(solP2)
      setIMG3(solP3)
      setIMG4()
      setIMG5()
    }
    else {
      setIMG1(solP1)
      setIMG2(solP2)
      setIMG3(solP3)
      setIMG4()
      setIMG5()
    }
  }
  function handleCross(){
    setClickedNav(false)
    setClickedCreerExp(false)
    setClickedNewSimul(false)
    setClickedComp2(false)
    setClickedCompExpType(false)
    setClickedOADE(false)
    setClickedOADRI(false)
    setClickedOADRE(false)
    setClickedOADO(false)
    setClickedOADA(false)
    setopen(false)
  }

  return (
    <div>
      <Entete titre="Manuel de découverte"/>
      <div className="container" >
        <div className='titrePlusVideo'>
          <div className='paragraphe' style={{marginTop:'20px'}}>Vous pouvez accéder à tous les tutos sur</div> 
          <a className='paragraphe' href='https://www.youtube.com/channel/UCfsI6GM0kVNMIdVNNbWyIzQ'  target='_blank' rel="noopener noreferrer" style={{fontWeight:'bold', color:'#92D050', marginTop:'20px', marginLeft:'5px'}}>notre page youtube !</a>
        </div>
        <div className='paragraphe'>Pour voir les tutos en cas d’exploitation uniquement grandes cultures ou bien viticulture/mixte il vous suffit de cliquer sur le bouton associé. </div>

        <div className='dispLigne ligneGCVMD'>
          <button className= {ClickedGC ? 'validation validationCompGCVITIMI BTGCVMD valCompGCC' : 'validation validationCompGCVITIMI BTGCVMD valCompGCUnC'} onClick={handleClickedGC} >
            Grandes cultures
          </button>
          <button className= {ClickedViti ?'validation validationCompGCVITIMI BTGCVMD valCompVitiC' : 'validation validationCompGCVITIMI BTGCVMD valCompVitiUnC'} onClick={handleClickedViti}>
            Viticulture / Mixte
          </button>
        </div>

        <div className='ligneMD'> 
          <div className={ClickedNav ? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickNav}><button className='ButtonHome paragraphe buttonOngletMD'> Navigation </button></div>
          <div className={ClickedCreerExp? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickCreerExp}><button className='ButtonHome paragraphe buttonOngletMD'> Créer mon exploitation </button></div>
          <div className={ClickedNewSimul ? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickNewSimul}><button className='ButtonHome paragraphe buttonOngletMD'> Nouvelle simulation </button></div>
          <div className={ClickedComp2 ? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickComp2}><button className='ButtonHome paragraphe buttonOngletMD'> Comparer 2 exploitations </button></div>
          <div className={ClickedCompExpType ? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickCompExpType}><button className='ButtonHome paragraphe buttonOngletMD'> Comparer à l’exp. moyenne </button></div>
          <div className={ClickedOADE ? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickOADE}><button className='ButtonHome paragraphe buttonOngletMD'> OAD onglet “Exploitation” </button></div>
          <div className={ClickedOADRI ? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickOADRI}><button className='ButtonHome paragraphe buttonOngletMD'> OAD onglet “Risque” </button></div>
          <div className={ClickedOAD3050? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickOAD3050}><button className='ButtonHome paragraphe buttonOngletMD'> OAD onglet “2030/2050” </button></div>
          <div className={ClickedOADAl? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickOADAl}><button className='ButtonHome paragraphe buttonOngletMD'> OAD onglet “Aléas” </button></div>
          <div className={ClickedOADSol? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickOADSol}><button className='ButtonHome paragraphe buttonOngletMD'> OAD onglet “Solutions” </button></div>
          <div className={ClickedOADA? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickOADA}><button className='ButtonHome paragraphe buttonOngletMD'> OAD onglet “Assolement” </button></div>
          <div className={ClickedOADRE ? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickOADRE}><button className='ButtonHome paragraphe buttonOngletMD'> OAD onglet “Résistance” </button></div>
          <div className={ClickedOADO ? 'paragraphe ongletMD_INAC ongletMD_AC' :'paragraphe ongletMD_INAC'} onClick={handleClickOADO}><button className='ButtonHome paragraphe buttonOngletMD'> OAD onglet “Optimisation” </button></div>
        </div>
        {open ? <ComponentManuel handleCross={handleCross} img1={img1} img2={img2} img3={img3} img4={img4} img5={img5} img6={img6} /> : ""}
      </div>
      <ButtonQuestions/>
    </div>
  )
}

export default ManuelDecouverte