import React, {useState, useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import home from '../../assets/home2.png'
import rond from '../../assets/logo-rond2.png'
import rond2 from '../../assets/logo-rond.png'
import profil from '../../assets/icon-profil.png'
import deco from '../../assets/icon-deconnexion.png'
import NavOAD from './oadNav'


function  Entete ({titre, rapport, R2050, Home}) {
    const [idAgent, setidAgent] = useState();
    const role = JSON.parse(localStorage.getItem('role')); 
    const formule = JSON.parse(localStorage.getItem('formule')); 
    const [ShowResult, setShowResult]= React.useState(false);
    const [goToProfil, setGoToProfil] = useState(false)
    const [retour, setRetour]= useState(false)
    const [retour2050, setRetour2050]= useState(false)

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('idAgent'));
        if (items ) {
        setidAgent(items);
        }
      }, []);
    
    const [goToHome, setGoToHome] = useState(false)

    function handleProfil(event) {
        event.preventDefault()
        setGoToProfil(true)
    }

    function handleDeco() {
        localStorage.clear()
        setGoToHome(true)
      }

    function handleClick(event) {
        event.preventDefault()
        setGoToHome(true)
    }

    function handleShowHide(){
        if(ShowResult){
            setShowResult(false)
        }
        else{
            setShowResult(true)
        }
    }
    function handleClickRetour(){
        setRetour(true)
    }
    function handleClickRetour2050(){
        setRetour2050(true)
    }

    if(retour){
        return <Navigate push to={`/simulations`} />
    }
    if(retour2050){
        return <Navigate push to={`/params2050`} />
    }
    if(goToHome) {
        return <Navigate push to={`/`} />
    }
    if(goToProfil) {
        return <Navigate push to={`/monprofil`} />
    }

    return (
        <div className='enteteOAD'>
            {(idAgent !== undefined && rapport === undefined && R2050 === undefined && Home === undefined)  ? (<NavOAD/>)  : ''}
            <div className={role==="ROLE_ADMINISTRATEUR" ?'row entete backgroundAdmin': formule==="Flash"  ?'row entete backgroundFlash' : 'row entete backgroundExploitant'}>
                {rapport ? 
                    (<button className='ButtonHome' onClick={handleClickRetour} style={{marginLeft:'10px', fontWeight:'bold', color:role==="ROLE_ADMINISTRATEUR" ? '#1A213F' : 'white', fontSize:'large'}} >Retour </button>)  
                : R2050 ? 
                    (<button className='ButtonHome' onClick={handleClickRetour2050} style={{marginLeft:'10px', fontWeight:'bold', color:role==="ROLE_ADMINISTRATEUR" ? '#1A213F' : 'white', fontSize:'large'}} >Retour </button>)  
                :
                    <button className='ButtonHome' onClick={handleClick} > <img src={home} className="IconHome" alt='home'/> </button>}
                <h1 style={{color:'#1A213F'}}>{titre}</h1>
                <button className='ButtonHome' onClick={handleShowHide}><img src={role==="ROLE_ADMINISTRATEUR" ? rond2 : rond} className="LogoRond" alt='logo'/></button>
            </div>

            <div className={ShowResult && idAgent!==undefined ? 'ShowResult' : 'HideResult'}>
            <div className={role==="ROLE_ADMINISTRATEUR" ?'encartProfil backgroundAdmin': formule==="Flash" ?'encartProfil backgroundFlash' : 'encartProfil backgroundExploitant'} >
                <button className='ButtonHome' onClick={handleProfil} style={{margin:'0px'}}><div className='row' style={{justifyContent:'flex-start'}}> 
                    <img src={profil} alt="icone" style={{width:"30px", marginRight:"5px"}}/>
                    <div className='paragraphe'> Profil</div>
                </div></button>
                <button className='ButtonHome' onClick={handleDeco} style={{margin:'0px'}}> <div className='row' style={{justifyContent:'flex-start'}}> 
                    <img src={deco} alt="icone" style={{width:"25px", marginRight:"5px"}}/>
                    <div className='paragraphe'> Déconnexion</div>
                </div> </button>
            </div>
            </div>
        </div>
    )
    
  }
  
  
  export default Entete;