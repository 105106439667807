import React, {useState, useEffect} from 'react'
import logo from '../../assets/DiagoRisk-logoRVB.png'
import img from '../../assets/countryside-2326787_1920_maj_091122.jpg'
import apiLink from '../../config.js'

function PageDeGarde({idExploit}) {
  const [ExploitMixte, setExploitMixte] = useState(false)
  const [ExploitViti, setExploitViti] = useState(false)
  const [ExploitGC,setExploitGC] = useState(true)
  function fetchData() {
    fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
    let data = response
    for (var cle in data) {
        if (cle.includes("Vignes")) {
            setExploitMixte(true)
            setExploitGC(false)
            setExploitViti(false);
            break;
        }
    }
    let allKeysContainVignes = Object.keys(data).every(cle => cle.includes("Vignes"));
    if (allKeysContainVignes) {
        setExploitViti(true);
        setExploitMixte(false)
        setExploitGC(false)
    } else {
        setExploitViti(false);
    }
    })
}

  useEffect ( () => {
      fetchData()
    }, [idExploit])

  return (
    <div className='pageDeGarde'>
      <img src={logo} alt="logo_diagorisk" className="App-logo"/>

      <div className='row' style={{marginLeft:'150px'}}>
        <div>
          {/* <div className='titrePDG_rapport' style={{fontSize:'60px', marginTop:'50px'}}> Structure générale d'un rapport à paramétrer pour Cahors</div> */}
          <div className='titrePDG_rapport' style={{marginTop:'250px',marginBottom:'50px'}}>Rapport de diagnostic personnalisé</div>
        </div>
      </div>

      <div className='row' style={{justifyContent:'space-around'}}>
        <img src={img} alt="logo_diagorisk" className="img_rport_PDG" style={{marginLeft:'20px'}}/>
        <div style={{width:'40%', marginLeft:'20px'}}>
          <div className='row' style={{color:'#616161', fontWeight:'bold', fontSize:'30px', marginBottom:'20px'}}> Sommaire </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> p 1  </div>
            <div className='titre_sommaire_PDG'>Facteurs de production</div>
          </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> p 2 à 3 </div>
            <div className='titre_sommaire_PDG'>Vulnérabilités</div>
          </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> p 4 </div>
            <div className='titre_sommaire_PDG'>Comparaison à 2030/2050</div>
          </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> {ExploitViti ? 'p 5' : 'p 5 à 6'}  </div>
            <div className='titre_sommaire_PDG'> Impact des aléas </div>
          </div>
          <div className={ExploitMixte || ExploitViti ? 'row ShowResultFlex' : 'row HideResult'} style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> {ExploitViti ? 'p 6' : 'p 7'}  </div>
            <div className='titre_sommaire_PDG'> Solution : changement de cépage </div>
          </div>
          <div className='row'  style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> {ExploitGC ? 'p 7': ExploitMixte ? "p 8" : "p 7"}  </div>
            <div className='titre_sommaire_PDG'> AFOM pour chaque culture  </div>
          </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'> {ExploitGC ? 'p 8': ExploitMixte ? "p 9" : "p 8"}  </div>
            <div className='titre_sommaire_PDG'>Optimisation Sécurité - Coût</div>
          </div>
          <div className='row' style={{marginBottom:'20px', justifyContent:'flex-start'}}>
            <div className='page_sommaire_PDG'>  {ExploitGC ? 'p 9': ExploitMixte ? "p 10" : "p 9"}   </div>
            <div className='titre_sommaire_PDG'>Méthodologie</div>
          </div>
        </div>
      </div>
      {/* <div style={{marginLeft:'5%', margintop:'20px'}}>
        <div className='page_sommaire_PDG'>Les résultats calculés sur DiagoRisk peuvent être édités sous forme d'un rapport, selon l'exemple-ci. </div>
        <div className='page_sommaire_PDG' style={{marginTop:'10px'}}>Pour le Syndicat des Vins de Cahors, DiagoRisk sera configuré et enrichi avec les informations obtenues par ailleurs : parcellaires, rendements, carte de gel, ... et les différents leviers d'action envisagés.</div>
        <div className='page_sommaire_PDG' style={{marginTop:'10px'}}>Chaque viticulteur bénéficiant de l'Accompagnement Individuel Approfondi verra sur l'application et dans son rapport : son parcellaire et son encépagement / les vulnérabilités et les opportunités climatiques par aléa (et par période si pertinent) / un comparaison à un panel d'autres exploitations (fictives ou réelles anonymisées avec l'accord des autres viticulteurs) / l'évolution de ces vulnérabilités d'ici 2050 / l'accessibilité et la pertinence des leviers d'action / une matrice AFOM reprenant ces différent éléments / un ratio Efficacité / coût sur le modèle de celui réalisé pour l'assurance Récolte.  </div>
      </div> */}
    </div>
  )
}

export default PageDeGarde