import React from 'react'
import NavOAD from '../MEP/oadNav'
import Entete from '../MEP/entete'
import graph from '../../assets/graph-risques.jpg'

function Questions() {
  return (
    <div>
      <Entete titre="Questions ?"/>
      <div className="container" >
        <div className='videBlocQuestion'> 
          <div className='titreQuestion' >
            <div style={{marginLeft:'10px', marginTop:'0px'}}> D’où viennent les rendements et les prix affichés par défaut ?</div>
          </div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Ils correspondent aux statistiques officielles disponibles.</div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Les rendements et les prix affichés par défaut correspondent aux statistiques officielles (INSEE - Agreste - SNM) ou de l’organisme via lequel vous avez souscrit au service.</div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Ces valeurs peuvent être remplacées par vos données personnelles.</div>
        </div>

        <div className='videBlocQuestion'> 
          <div className='titreQuestion' >
            <div style={{marginLeft:'10px', marginTop:'0px'}}> Qu'est ce que sont les risques courant, exceptionnel et catastrophique ?</div>
          </div>
          <div className='paragraphe'> Le risque<span style={{color:'#FFC000',marginLeft:'10px'}}>"courant"</span> se produit de manière fréquente (tous les 7 ans environ) et à des effets peu importants sur la perte de rendement. </div>
          <div className='paragraphe'> Le risque<span style={{color:'#0027CC', marginLeft:'10px'}}>"exceptionnel"</span> se produit de manière un peu plus fréquente (tous les 20 ans environ) et à des effets plus importants sur la perte de rendement.  </div>
          <div className='paragraphe'> Le risque<span style={{color:'#DB04B9', marginLeft:'10px'}}>"catastrophique"</span> se produit de manière un peu fréquente (tous les 50 ans environ) et à des effets importants sur la perte de rendement.  </div>
        </div>

        <div className='videBlocQuestion'> 
          <div className='titreQuestion' >
            <div style={{marginLeft:'10px', marginTop:'0px'}}> Quel est le rendement par défaut ?</div>
          </div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>C'est le rendement moyen observé par petite région agricole ou par département.</div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Chaque utilisateur peut le personnaliser. Il faut bien distinguer si il s'agit d'un rendement potentiel ou espéré, ou une valeur correspondant à la moyenne des rendements réalisés ces dernières années.</div>
        </div>

        <div className='videBlocQuestion'> 
          <div className='titreQuestion' >
            <div style={{marginLeft:'10px', marginTop:'0px'}}> Quel est le prix par défaut ?</div>
          </div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>C'est le prix au producteur.</div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Par défaut, les prix proposés proviennent des observations réalisés sur des échantillons ou dans les mercuriales de référence. Ils sont personnalisables.</div>
        </div>

        <div className='videBlocQuestion'> 
          <div className='titreQuestion' >
            <div style={{marginLeft:'10px', marginTop:'0px'}}> Qu'est ce que le produit brut ?</div>
          </div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Le produit brut de référence est calculé pour chaque production en prenant le rendement et le prix de référence indiqués dans le formulaire. Chacun des produits bruts par hectare est multiplié par la surface.</div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Le produit brut est recalculé à chaque modification. On calcule le produit brut de référence à partir des données renseignées dans "mon exploitation" - Assolement. Puis le Produit brut moyen, qui intègre les variations de prix et de rendement.</div>
        </div>

        <div className='videBlocQuestion'> 
          <div className='titreQuestion' >
            <div style={{marginLeft:'10px', marginTop:'0px'}}> Quel est le modèle de simulation ?</div>
          </div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Il représente l'impact des aléas de rendement et de prix auxquelles sont soumis les productions de l'exploitation.</div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Nous utilisons un modèle actuariel original développé depuis 3 ans avec l'Institut du Risque et de l'Assurance de Le Mans Université.</div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Ce modèle exploite la base de données du RICA qui compile chaque année les données comptables de 7 500 à 8000 exploitations, et des données de rendements de 1989 à 2020.</div>
          <div className='paragraphe' style={{marginLeft:'10px'}}> Pour le rendement, le risque est évalué par chacune des 472 Régions Agricoles , qui correspondent à une classification des potentiels pédoclimatiques et permet de s'affranchir des limites administratives et quand on manque de données par département.</div>
        </div>

        <div className='videBlocQuestion'> 
          <div className='titreQuestion' >
            <div style={{marginLeft:'10px', marginTop:'0px'}}> A quoi correspond le risque de perte ?</div>
          </div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Le risque de perte correspond à la baisse de produit brut par rapport au produit brut de référence.</div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Notre modèle calcule de très nombreux scénarios de rendement et de prix. La perte est exprimée selon les fréquences de survenance (ou période de retour théorique)</div>
          <div className='paragraphe' style={{marginLeft:'10px'}}>Il y a logiquement la perte est importante quand elle est rare.</div>
        </div>

        <div className='videBlocQuestion'> 
          <div className='titreQuestion' >
            <div style={{marginLeft:'10px', marginTop:'0px'}}> Quelles pertes pour qu'elle gravité de risques ?</div>
          </div>
          <div style={{width:'100%',display:'flex', alignItems:'center', justifyContent:'center', marginTop:'20px'}}>
            <img src={graph} alt='graphique du lien entre gravité du risque et perte de rendement'/>
          </div>
          
        </div>

      </div>
    </div>
  )
}

export default Questions