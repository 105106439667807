import React, {useEffect} from 'react'
import TitreRapport from './titre_rapport.js'
import apiLink from '../../config.js'
import AFOM_A from '../../assets/AFOM-A.png'
import AFOM_F from '../../assets/AFOM-F.png'
import AFOM_O from '../../assets/AFOM-O.png'
import AFOM_M from '../../assets/AFOM-M.png'

function PageAFOM({idExploit}) {
  const [ExploitMixte, setExploitMixte] = React.useState(false)
  const [ExploitViti, setExploitViti] = React.useState(false)
  const [ExploitGC,setExploitGC] = React.useState(true)
  function fetchData() {
    fetch(apiLink + 'page/production-rapport/' + idExploit)
    .then( (response) => {
    return response.json()
    })
    .then(response =>{
    let data = response
    for (var cle in data) {
        if (cle.includes("Vignes")) {
            setExploitMixte(true)
            setExploitGC(false)
            setExploitViti(false);
            break;
        }
    }
    let allKeysContainVignes = Object.keys(data).every(cle => cle.includes("Vignes"));
    if (allKeysContainVignes) {
        setExploitViti(true);
        setExploitMixte(false)
        setExploitGC(false)
    } else {
        setExploitViti(false);
    }
    })
}

  useEffect ( () => {
      fetchData()
    }, [idExploit])

  return (
    <div className='containerPrint'>
      <TitreRapport Titre={"Matrice AFOM"} Page={ExploitGC ? '7': ExploitMixte ? "8" : "7"}/>
      <div className='paragraphe'>Les matrice AFOM (ou SWOT) vous permet d’évaluer les atouts et faiblesses de votre exploitation, ainsi que les opportunités et les menaces auxquelles elle fait face.</div>
      <ul>
        <li> <div className='paragraphe lila lila2'>  A = Atouts </div> </li>
        <li> <div className='paragraphe lila lila2'> F = Faiblesses </div> </li>
        <li> <div className='paragraphe lila lila2'>  O = Opportunités </div> </li>
        <li> <div className='paragraphe lila lila2'>  M = Menaces </div> </li>
      </ul>

      <div>
        <div className='row' style={{justifyContent:'space-around'}}>
          <div className='bloc-afom-rapport bloc-afom-rapport-A'>
            <div className='row' style={{marginTop:'10px'}}><img src={AFOM_A} alt="lettre A de la matrice AFOM" className="lettre-rapport-AFOM"/></div>
            <div style={{paddingLeft:'10px'}}>
              <div className='paragraphe paragrapheSmaller' style={{marginTop:'15px'}}> Texte en construction</div>
              <div className='paragraphe paragrapheSmaller' style={{fontWeight:'bold', color:'#83BE29', marginTop:'5px'}}> Culture 1 :</div>
              <ul style={{marginTop:'0px'}}>
                <li> <div className='paragraphe paragrapheSmaller lila lila2'> Texte </div> </li>
                <li> <div className='paragraphe paragrapheSmaller lila lila2'> en </div> </li>
                <li> <div className='paragraphe paragrapheSmaller lila lila2'> construction </div> </li>
              </ul>
              <div className='paragraphe paragrapheSmaller' style={{fontWeight:'bold', color:'#83BE29', marginTop:'5px'}}> Culture 2 :</div>
              <ul style={{marginTop:'0px'}}>
                <li> <div className='paragraphe paragrapheSmaller lila lila2'> Texte </div> </li>
                <li> <div className='paragraphe paragrapheSmaller lila lila2'> en </div> </li>
                <li> <div className='paragraphe paragrapheSmaller lila lila2'> construction </div> </li>
              </ul>
            </div>
          </div>
          
          <div className='bloc-afom-rapport bloc-afom-rapport-F'>
            <div className='row' style={{marginTop:'15px'}}><img src={AFOM_F} alt="lettre F de la matrice AFOM" className="lettre-rapport-AFOM"/></div>
            <div style={{paddingLeft:'10px'}}>
              <div className='paragraphe paragrapheSmaller' style={{marginTop:'15px'}}> Texte en construction</div>
              <div className='paragraphe paragrapheSmaller' style={{fontWeight:'bold', color:'#CA0047', marginTop:'5px'}}> Culture 1 :</div>
              <ul style={{marginTop:'0px'}}>
                <li style={{color:'#CA0000'}}> <div className='paragraphe paragrapheSmaller lila lila2'> Texte </div> </li>
                <li style={{color:'#CA0000'}}> <div className='paragraphe paragrapheSmaller lila lila2'> en </div> </li>
                <li style={{color:'#CA0000'}}> <div className='paragraphe paragrapheSmaller lila lila2'> construction </div> </li>
              </ul>
              <div className='paragraphe paragrapheSmaller' style={{fontWeight:'bold', color:'#CA0047', marginTop:'5px'}}> Culture 2 :</div>
              <ul style={{marginTop:'0px'}}>
                <li style={{color:'#CA0000'}}> <div className='paragraphe paragrapheSmaller lila lila2'> Texte </div> </li>
                <li style={{color:'#CA0000'}}> <div className='paragraphe paragrapheSmaller lila lila2'> en </div> </li>
                <li style={{color:'#CA0000'}}> <div className='paragraphe paragrapheSmaller lila lila2'> construction </div> </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='row' style={{justifyContent:'space-around'}}>
          <div className='bloc-afom-rapport bloc-afom-rapport-O'>
            <div className='row' style={{marginTop:'10px'}}><img src={AFOM_O} alt="lettre O de la matrice AFOM" className="lettre-rapport-AFOM"/></div>
            <div style={{paddingLeft:'10px'}}>
              <div className='paragraphe paragrapheSmaller' style={{marginTop:'15px'}}> Texte en construction</div>
              <div className='paragraphe paragrapheSmaller' style={{fontWeight:'bold', color:'#01D6A3', marginTop:'5px'}}> Culture 1 :</div>
              <ul style={{marginTop:'0px'}}>
                <li style={{color:'#01D6A3'}}> <div className='paragraphe paragrapheSmaller lila lila2'> Texte </div> </li>
                <li style={{color:'#01D6A3'}}> <div className='paragraphe paragrapheSmaller lila lila2'> en </div> </li>
                <li style={{color:'#01D6A3'}}> <div className='paragraphe paragrapheSmaller lila lila2'> construction </div> </li>
              </ul>
              <div className='paragraphe paragrapheSmaller' style={{fontWeight:'bold', color:'#01D6A3', marginTop:'5px'}}> Culture 2 :</div>
              <ul style={{marginTop:'0px'}}>
                <li style={{color:'#01D6A3'}}> <div className='paragraphe paragrapheSmaller lila lila2'> Texte </div> </li>
                <li style={{color:'#01D6A3'}}> <div className='paragraphe paragrapheSmaller lila lila2'> en </div> </li>
                <li style={{color:'#01D6A3'}}> <div className='paragraphe paragrapheSmaller lila lila2'> construction </div> </li>
              </ul>
            </div>
          </div>
          
          <div className='bloc-afom-rapport bloc-afom-rapport-M'>
            <div className='row' style={{marginTop:'15px'}}><img src={AFOM_M} alt="lettre M de la matrice AFOM" className="lettre-rapport-AFOM"/></div>
            <div style={{paddingLeft:'10px'}}>
              <div className='paragraphe paragrapheSmaller' style={{marginTop:'15px'}}> Texte en construction</div>
              <div className='paragraphe paragrapheSmaller' style={{fontWeight:'bold', color:'#E37006', marginTop:'5px'}}> Culture 1 :</div>
              <ul style={{marginTop:'0px'}}>
                <li style={{color:'#E37006'}}> <div className='paragraphe paragrapheSmaller lila lila2'> Texte </div> </li>
                <li style={{color:'#E37006'}}> <div className='paragraphe paragrapheSmaller lila lila2'> en </div> </li>
                <li style={{color:'#E37006'}}> <div className='paragraphe paragrapheSmaller lila lila2'> construction </div> </li>
              </ul>
              <div className='paragraphe paragrapheSmaller' style={{fontWeight:'bold', color:'#E37006', marginTop:'5px'}}> Culture 2 :</div>
              <ul style={{marginTop:'0px'}}>
                <li style={{color:'#E37006'}}> <div className='paragraphe paragrapheSmaller lila lila2'> Texte </div> </li>
                <li style={{color:'#E37006'}}> <div className='paragraphe paragrapheSmaller lila lila2'> en </div> </li>
                <li style={{color:'#E37006'}}> <div className='paragraphe paragrapheSmaller lila lila2'> construction </div> </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
     
    </div>
  )
}

export default PageAFOM